import { useQuery } from '@tanstack/react-query'

import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useDeferredValue,
  useEffect,
  useRef,
  useState,
} from 'react'
import { UserInfoType } from '../service/user'
import { TemplateItem } from '../service/list'
import { CircularCheckbox } from './CircularCheckbox'
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { contentVideoFormValues } from 'utils/type'
import { ImageTypeTextData } from 'dummy/data'

interface ModalProps {
  setImageTypeModal: Dispatch<SetStateAction<boolean>>
  isImageTypeModal: boolean
  userInfo: UserInfoType
  watch: UseFormWatch<contentVideoFormValues>
  control: Control<contentVideoFormValues>
  setValue: UseFormSetValue<contentVideoFormValues>
  imageList:
    | {
        list: TemplateItem[]
        cash: string
        originCash: number
      }
    | undefined
  etcList:
    | {
        list: TemplateItem[]
        cash: string
        originCash: number
      }
    | undefined
}

export const ImageTypeModal = ({
  children,
  setImageTypeModal,
  isImageTypeModal,
  userInfo,
  imageList,
  etcList,
  watch,
  setValue,
  control,
}: PropsWithChildren<ModalProps>) => {
  if (!imageList) <></>
  const [templateId, setTemplateId] = useState(watch('templateId'))

  const handleSaveBtn = () => {
    const info = imageList?.list?.filter((el) => el.id === templateId)
    const etcInfo = etcList?.list?.filter((el) => el.id === templateId)

    if (
      (templateId !== 0 && info?.length) ||
      (templateId !== 0 && etcInfo?.length)
    ) {
      setValue('backgroundId', 0)
      setValue('templateId', templateId)
      setValue('templateType', 'image')
      setImageTypeModal((prev) => !prev)
    }
  }

  return (
    <div className="h-screen w-full fixed left-0 top-0 flex flex-col justify-center items-center bg-black bg-opacity-50 text-center  z-50">
      <div className="bg-white w-[375px] md:w-[816px] h-[100vh] md:h-auto   px-[20px] border md:rounded-[40px]  overflow-y-auto pb-[150px]">
        <div className="sticky top-0 bg-white z-10   md:px-10 pt-10  ">
          <p className="flex justify-between items-center">
            <p className="font-bold text-[24px]">이미지 타입</p>
            <button
              onClick={() => {
                setImageTypeModal(false)
              }}
            >
              <img
                src="assets/img/Icon_x.png"
                alt="icon_x"
                height={40}
                width={40}
              />
            </button>
          </p>
        </div>
        <div className="md:px-10">
          <div className="mt-4">
            <p className="text-left">
              이미지 비율을 유지하면서 화면 전체에 적용합니다. 이미지에 따라
              잘려나가는 부분이 있을 수 있어요.
              <br /> 내 컨텐츠와 어울리는 제목의 위치와 스타일을 골라보세요!
            </p>
          </div>
          <p className="text-[14px] text-error text-left mt-4">할인 이벤트중</p>
          <p className="flex items-center gap-1 mt-4">
            {' '}
            <img
              src="assets/img/cash.png"
              alt="cash"
              className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
            />
            <s className="text-[#5E6068]  ml-1">{`${imageList?.cash}`}캐시</s>
            <span className="text-main">무료</span>
          </p>
          <div className="grid grid-cols-2 md:grid-cols-5 gap-x-[10px] gap-y-[16px] mt-10 px-5 md:p-0">
            {imageList?.list?.map((template, index) => {
              return (
                <>
                  <div
                    className="w-full flex justify-between md:items-center relative"
                    key={index}
                  >
                    {/* <ImageTypeText text={watch('mainTitle')} /> */}
                    <div className="flex flex-col h-full gap-2">
                      <img
                        src={template.path}
                        alt="bg"
                        loading={'lazy'}
                        className="h-[240px] w-[136px]"
                      />
                      <div className="flex">
                        <Controller
                          render={({ field }) => (
                            <CircularCheckbox
                              {...field}
                              id={index}
                              checked={templateId === template.id}
                              disabled={!userInfo}
                              onChange={() => setTemplateId(template.id)}
                              label={template.name}
                            />
                          )}
                          name="templateId"
                          control={control}
                        />
                        {Number(template.price) !== 0 &&
                          new Array(1).fill(0).map((el) => (
                            <>
                              {' '}
                              <img
                                src="assets/img/cash.png"
                                alt="cash"
                                className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
                              />
                            </>
                          ))}
                      </div>
                    </div>
                  </div>
                </>
              )
            })}
          </div>
          <div className="mt-10">
            <p className="flex justify-between">
              <p className="font-bold text-[24px]">배경블러</p>
            </p>
          </div>{' '}
          <div className="mt-4">
            <p className="text-left">
              컨텐츠 이미지에 블러 및 색상 효과를 주어 배경으로 이미지 뒤에
              깔아줍니다.{' '}
            </p>
            <p className="text-[14px] text-error text-left mt-4">
              할인 이벤트중
            </p>
            <p className="flex items-center gap-1 mt-[16px]">
              {' '}
              <img
                src="assets/img/cash.png"
                alt="cash"
                className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
              />
              <s className="text-[#5E6068]  ml-1">{`${etcList?.cash}`}캐시</s>
              <span className="text-main">{etcList?.originCash}캐시</span>
            </p>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-5 gap-x-[10px] gap-y-[16px] mt-10  px-5 md:p-0">
            {etcList?.list?.map((template, index) => {
              return (
                <>
                  <div
                    className="w-full flex justify-between md:items-center"
                    key={index}
                  >
                    <div className="flex flex-col h-full gap-2">
                      <img
                        src={template.path}
                        alt="bg"
                        loading={'lazy'}
                        className="h-[240px] w-[136px]"
                      />
                      <div className="flex">
                        <Controller
                          render={({ field }) => (
                            <CircularCheckbox
                              {...field}
                              id={index}
                              checked={templateId === template.id}
                              disabled={!userInfo}
                              onChange={() => setTemplateId(template.id)}
                              label={template.name}
                            />
                          )}
                          name="templateId"
                          control={control}
                        />
                        {new Array(template.cashRate).fill(0).map((el) => (
                          <>
                            {' '}
                            <img
                              src="assets/img/cash.png"
                              alt="cash"
                              className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
                            />
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )
            })}
          </div>
          <div className="mt-10">
            <button
              className="w-full text-white py-[19px] text-[18px] font-bold bg-main rounded-[40px]"
              onClick={handleSaveBtn}
            >
              저장
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const ImageTypeText = (props: any) => {
  const { text } = props
  const data = ImageTypeTextData['image_01']
  return (
    <>
      <div className=" absolute top-0 left-0 z-50 text-white">
        {text ?? data.initText}
      </div>
    </>
  )
}
