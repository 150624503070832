import classNames from 'classnames'
import { Input } from 'components/atom/Input'
import { TextArea, TextAreaContent } from 'components/atom/TextArea'
import { CircularCheckbox } from 'components/CircularCheckbox'
import { ShadowBox } from 'components/ShadowBox'
import { TextTypeAiScriptDisplay } from 'components/shortvideo/TextTypeAiScriptDisplay'
import useGetLanguages from 'hooks/useGetLanguages'
import useGetSourceType from 'hooks/useGetSourceType'
import useGetVoiceLanguageGroup from 'hooks/useGetVoiceLanguageGroup'
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { isMobile } from 'react-device-detect'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  ChildrenType,
  SourceType,
  TextTypeVideoInfo,
  VoiceTagType,
} from 'service/list'
import useUserStore from 'store/useUserStore'
import {
  getGridItemStyle,
  getLocalStorage,
  saveLocalStorage,
  styles,
} from 'utils/common'
import { contentVideoTextTypeFormValues } from 'utils/type'
import TextCashUseModal from '../TextCashUseModal'
import { useGetMyPoint } from 'hooks/query/point/useGetMyPoint'
import { Modal } from 'components/layout/Modal'
interface TextTypeProps {
  tab: 'link' | 'text'
  setTab: Dispatch<SetStateAction<'link' | 'text'>>
  changeTab: (string: 'link' | 'text') => void
  voiceTags: VoiceTagType | undefined
}

export const TextType = ({
  tab,
  setTab,
  changeTab,
  voiceTags,
}: PropsWithChildren<TextTypeProps>) => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<contentVideoTextTypeFormValues>({
    defaultValues: {
      idea: '',
      title: '',
      sourceType: '',
      languageId: 0,
      voiceId: 0,
      sourceTypeId: 0,
      amount: 0,
    },
  })

  const [textVideoInfo, setTextVideoInfo] = useState<TextTypeVideoInfo>({
    idea: '',
    title: '',
    voiceId: 0,
    sourceType: '',
    sourceTypeId: 0,
    languageId: 0,
    amount: 0,
    files: null,
  })

  const PRODUCT_NAME_PATTERN = /^[^<>\uD800-\uDFFF]*$/
  const userInfo = useUserStore((state: any) => state.userInfo)
  const navigate = useNavigate()
  const [textVideoPriceInfo, setTextVideoPriceInfo] = useState<{
    voicePrice: number
    sourceTypePrice?: number
  }>({
    voicePrice: 0,
  })
  const [saveSuccessModal, setSaveSuccessModal] = useState<boolean>(false)
  const [isUseModal, setUseModal] = useState<boolean>(false)
  const [isModal, setModal] = useState<boolean>(false)
  const [selectedSourceType, setSelectedSourceType] =
    useState<SourceType | null>(null)
  const [selectedSourceTypeChildrenId, setSelectedSourceTypeChildrenId] =
    useState<number>(0)
  const [images, setImages] = useState<string[]>([])
  const [uploadImages, setUploadImages] = useState<File[]>([])
  const [selectedImages, setSelectedImages] = useState(
    new Array(images.length).fill(true)
  )
  const [selectedUploadImages, setSelectedUploadImages] = useState(
    new Array(uploadImages.length).fill(true)
  )

  // 데이터
  const { data: sourceTypeList } = useGetSourceType()
  const { data: languageList } = useGetLanguages()
  const { data: myPointData, refetch: getMyPointRefetch } = useGetMyPoint()
  const { data: voiceList, refetch: voiceRefetch } = useGetVoiceLanguageGroup(
    watch('languageId')
  )

  const userCheck = (userInfo: any) => {
    if (!userInfo) {
      navigate('/sign-up')
      return
    }
  }

  const handleUploadCheckboxChange = (index: number) => {
    setSelectedUploadImages((prev) => {
      const currentSelectionCount = prev.filter((selected) => selected).length
      const newSelection = !prev[index]
      prev.map((selected, i) => (i === index ? !selected : selected))
      // 이미지 5개가 선택된 상태에서 추가 선택을 막습니다.
      if (currentSelectionCount >= 5 && newSelection) {
        return prev
      }
      return prev.map((selected, i) => (i === index ? newSelection : selected))
    })
  }
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files)
      setUploadImages((prevImages) => {
        const newImages = [...prevImages, ...selectedFiles]
        setSelectedUploadImages((prevSelected) => {
          const additionalSelectionCount = selectedFiles.length
          const currentSelectionCount = prevSelected.filter(
            (selected) => selected
          ).length

          // 추가된 파일이 최대 선택 개수를 초과하지 않도록 합니다.
          if (currentSelectionCount + additionalSelectionCount > 5) {
            const allowedSelectionCount = 5 - currentSelectionCount
            return [
              ...prevSelected,
              ...new Array(allowedSelectionCount).fill(true),
              ...new Array(selectedFiles.length - allowedSelectionCount).fill(
                false
              ),
            ]
          } else {
            return [
              ...prevSelected,
              ...new Array(selectedFiles.length).fill(true),
            ]
          }
        })
        return newImages
      })
    }
  }

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    document.getElementById('file-input')!.click()
  }

  const onSubmit = (data: any) => {
    console.log(data)
    userCheck(userInfo)
    // localstorage에 데이터 저장
    let { idea, title, sourceType, sourceTypeId, languageId, voiceId } = data

    const prevInfo = {
      idea,
      title,
      sourceType,
      sourceTypeId,
      languageId,
      voiceId,
    }
    saveLocalStorage('contentTextVideoData', prevInfo)

    // 가격 정보 및 계산(sourceType, 목소리)
    const [voice] = voiceList?.filter((el) => el.id === watch('voiceId')) ?? []

    if (sourceTypeId === 4 || sourceTypeId === 5) {
      const [sourceTypeInfo] =
        sourceTypeList
          ?.filter((el) => el.id === 2)[0]
          .children?.filter((el) => el.id === sourceTypeId) ?? []
      setTextVideoPriceInfo({
        voicePrice: voice?.originPrice ?? 0,
        sourceTypePrice: sourceTypeInfo.originPrice ?? 0,
      })
    } else {
      setTextVideoPriceInfo({ voicePrice: voice?.originPrice ?? 0 })
    }

    // 파일 확인
    const selectedUploadImageData = uploadImages.filter(
      (_, index) => selectedUploadImages[index]
    )
    const files = selectedUploadImageData || []
    setTextVideoInfo({
      idea,
      title,
      sourceType,
      languageId,
      sourceTypeId,
      voiceId,
      amount: 0,
      files,
    })
    setUseModal(true)
  }

  useEffect(() => {
    const saveData: TextTypeVideoInfo = getLocalStorage('contentTextVideoData')
    if (saveData) {
      const { sourceType, sourceTypeId, languageId } = saveData
      if (sourceType) {
        setValue('sourceType', sourceType)
      }
      if (sourceTypeId) {
        setValue('sourceTypeId', sourceTypeId)
      }
      if (languageId) {
        setValue('languageId', languageId)
        voiceRefetch()
      }
    } else {
      if (sourceTypeList) {
        setValue('sourceType', sourceTypeList[0]?.name)
        setValue('sourceTypeId', sourceTypeList[0]?.id)
      }
      if (languageList) {
        setValue('languageId', languageList[0].id)
        voiceRefetch()
      }
    }
  }, [sourceTypeList, languageList])

  return (
    <>
      <div className="flex flex-col relative mt-5 w-full ">
        <div className="w-full absolute h-[102px] z-40 flex ">
          <button
            className={classNames(
              'w-[50%] h-full md:rounded-tl-[40px] flex items-start justify-center ',
              {
                ' bg-[#2E343D]  text-white': tab === 'link',
                ' bg-[#EEF1F7]   text-[#8F929B]': tab !== 'link',
              }
            )}
            type="button"
            onClick={() => {
              changeTab('link')
            }}
          >
            <span className="  text-[18px] font-bold  pt-5">링크로 만들기</span>
          </button>
          <button
            className={classNames(
              'w-[50%] h-full md:rounded-tr-[40px] flex items-start justify-center ',
              {
                ' bg-[#2E343D]  text-white': tab === 'text',
                ' bg-[#EEF1F7]   text-[#8F929B]': tab !== 'text',
              }
            )}
            onClick={() => {
              changeTab('text')
            }}
            type="button"
          >
            <span className=" text-[18px] font-bold  pt-5">
              텍스트로 만들기
            </span>
          </button>
        </div>
        <form
          className={`flex flex-col gap-[20px] mt-[60px] z-50`}
          onSubmit={handleSubmit(onSubmit, (err) => {
            console.log(err)
          })}
        >
          <ShadowBox className="py-[40px]  md:w-[816px] bg-[#FFFFFF] flex flex-col gap-8">
            <div className="flex flex-col">
              <div className="flex flex-col gap-[16px]  w-full mb-4">
                {' '}
                <div className="flex flex-col w-[343px] md:w-full ml-4 md:ml-0">
                  <div className="relative w-[210px]">
                    <h3 className="font-bold text-[#2E343D] text-[20px] md:text-[24px] ">
                      컨텐츠 내용 입력
                    </h3>
                    {/* <ContentTooltip
                    setContentTooltipStatus={setContentTooltipStatus}
                    contentTooltipStatus={contentTooltipStatus}
                  /> */}
                  </div>
                  <p className="text-main text-[14px]">
                    영상으로 만들고 싶은 내용을 텍스트로 입력해주세요. 20~30초
                    영상에 맞게 요약하거나 늘려서 나레이션을 생성해요.
                  </p>
                </div>
              </div>
              <div className="relative w-full flex justify-center">
                <Controller
                  render={({ field: { onBlur, value, ...field } }) => (
                    <>
                      {isMobile ? (
                        <TextArea
                          value={value}
                          {...field}
                          placeholder="내용을 입력하세요"
                          maxLength={2000}
                        />
                      ) : (
                        <TextAreaContent
                          value={value}
                          {...field}
                          placeholder="내용을 입력하세요"
                          maxLength={2000}
                        />
                      )}
                      <span className=" absolute md:right-3 right-5 md:bottom-3 bottom-2 z-50 text-[#8F929B] text-[14px] ">
                        {`(${watch('idea').length}/2,000)`}
                      </span>
                    </>
                  )}
                  name={'idea'}
                  rules={{
                    required: {
                      value: true,
                      message: '컨텐츠 내용은 필수 입니다!',
                    },
                    maxLength: {
                      value: 2000,
                      message: '유튜브 영상 제목의 길이가 50자를 넘습니다.',
                    },
                  }}
                  control={control}
                />
              </div>
            </div>
            <div className="flex flex-col items-center w-full md:w-[736px]  gap-[8px]">
              <div className="flex flex-col gap-[16px]">
                <div className=" flex flex-col  md:ml-0">
                  <div className="relative flex items-center gap-2">
                    {' '}
                    <h3 className="font-bold text-[#2E343D] md:text-[24px] text-[20px] ">
                      영상 제목 입력
                    </h3>
                    {/* <ShortVideoTitleTooltip
                      setTitleTooltip={setTitleTooltip}
                      titleTooltip={titleTooltip}
                    /> */}
                  </div>

                  <p className="text-main text-[14px]">
                    매력적인 제목을 정해주세요!
                  </p>
                </div>
                <div className="flex flex-col items-center w-full md:w-[736px] gap-[8px]">
                  <div className="relative">
                    <Controller
                      render={({ field }) => (
                        <>
                          {isMobile ? (
                            <TextArea
                              userCheck={userCheck}
                              error={
                                field.value.length > 50 ||
                                !PRODUCT_NAME_PATTERN.test(field.value)
                                  ? true
                                  : false
                              }
                              {...field}
                              placeholder="영상 제목을 입력해주세요"
                              maxLength={50}
                            />
                          ) : (
                            <Input
                              userCheck={userCheck}
                              error={
                                field.value.length > 50 ||
                                !PRODUCT_NAME_PATTERN.test(field.value)
                                  ? true
                                  : false
                              }
                              {...field}
                              placeholder="영상 제목을 입력해주세요"
                              maxLength={50}
                            />
                          )}
                          <span className=" absolute right-3 md:bottom-[54%] bottom-10 z-50 text-[#8F929B] text-[14px] ">
                            {`(${watch('title').length}/50)`}
                          </span>
                          <div
                            className={`flex gap-[4px] mt-[8px] ${
                              (field.value.length > 50 ||
                                !PRODUCT_NAME_PATTERN.test(field.value)) &&
                              'text-error'
                            }`}
                          >
                            <span className="md:text-base text-[14px]">{`이모지, 한자(漢字), 꺽쇠괄호(< >) 는 허용되지 않습니다.`}</span>
                          </div>
                        </>
                      )}
                      name={'title'}
                      rules={{
                        required: {
                          value: true,
                          message: '컨텐츠 영상 제목은 필수입니다!',
                        },
                        maxLength: {
                          value: 90,
                          message: '유튜브 영상 제목의 길이가 50자를 넘습니다.',
                        },
                        validate:
                          watch(`title`) !== ''
                            ? (value) => {
                                return (
                                  PRODUCT_NAME_PATTERN.test(value) ||
                                  '이모지와 꺽쇠괄호(< >)는 허용되지 않습니다.'
                                )
                              }
                            : undefined,
                      }}
                      control={control}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* 영상 소스 */}
            <div className="w-full flex flex-col gap-4 md:px-0 px-4">
              <p className="text-[18px] font-bold">영상 소스</p>
              <div className="grid md:grid-cols-3 grid-cols-1 md:h-[60px] h-[240px] gap-x-1">
                {sourceTypeList?.map((sourceType, index) => (
                  <>
                    <button
                      className={classNames(
                        'border-[1px] border-[#D9DEE8] px-6 rounded-[6px] text-base font-bold  md:h-auto h-[60px]',
                        {
                          'text-[#B6BBC6] ':
                            watch('sourceType') !== sourceType.name,
                          'text-white bg-main ':
                            watch('sourceType') === sourceType.name,
                        }
                      )}
                      type="button"
                      onClick={() => {
                        setValue('sourceType', sourceType?.name)
                        setValue('sourceTypeId', sourceType?.id)
                        setSelectedSourceType(sourceType)
                        setSelectedSourceTypeChildrenId(sourceType?.id)
                        const children = sourceType.children ?? []
                        if (children.length > 0) {
                          setSelectedSourceTypeChildrenId(children[0]?.id)
                          setValue('sourceTypeId', children[0]?.id)
                        }
                      }}
                    >
                      <CircularCheckbox
                        id={index}
                        onChange={() => {}}
                        label={sourceType.name}
                        checked={watch('sourceType') === sourceType.name}
                      />
                      {index === 0 && (
                        <img
                          src="assets/img/stock.png"
                          alt=""
                          className="ml-7 w-[133px] h-[18px]"
                        />
                      )}
                      {index === 1 && (
                        <img
                          src={'assets/img/gpt.png'}
                          alt=""
                          className="ml-7 w-[62px] h-[18px]"
                        />
                      )}
                    </button>
                  </>
                ))}
              </div>
              {watch('sourceType') === 'AI 생성 이미지' && (
                <>
                  <div className="w-full md:h-[62px] h-[80px] bg-[#F6F8FB] rounded-[10px] grid grid-cols-2 px-5 md:py-0 py-4">
                    {sourceTypeList
                      ?.filter((el) => el.name === watch('sourceType'))[0]
                      ?.children?.map((el, index) => (
                        <>
                          <button
                            className="md:flex gap-1 md:items-center flex-col md:flex-row"
                            type="button"
                            onClick={() => {
                              setSelectedSourceTypeChildrenId(el.id)
                              setValue('sourceTypeId', el.id)
                            }}
                          >
                            {' '}
                            <CircularCheckbox
                              id={index}
                              onChange={() => {
                                setValue('sourceTypeId', el.id)
                              }}
                              label={el.name}
                              checked={watch('sourceTypeId') === el.id}
                            />
                            <s className="text-[14px] text-[#5E6068]">
                              {' '}
                              {Number(el.price).toLocaleString()}
                            </s>
                            {` `}
                            <span className="text-[14px] text-main ">
                              {el.originPrice}캐시
                            </span>
                          </button>
                        </>
                      ))}
                  </div>
                </>
              )}
              {watch('sourceType') === '이미지 업로드' && (
                <>
                  {' '}
                  <div className="flex flex-col gap-[16px]">
                    <div className="flex flex-col w-[343px] md:w-full md:ml-0">
                      {/* <div className="mb-4">
                    <label className="md:ml-0 font-bold text-[#2E343D] text-[18px] ">
                      컨텐츠 이미지
                    </label>
                    <p className="text-error text-[14px]">
                      사용할 수 있는 이미지가 없는 경우, 컨텐츠 영상 만들기가
                      진행되지 않습니다 🙏
                    </p>
                  </div> */}

                      <div
                        style={{
                          background: '#F6F8FB',
                          borderRadius: '10px',
                          padding: '20px',
                          maxHeight: 'calc(2 * 230px)', // 2줄까지만 보이도록 높이 제한 (2줄 높이 + 갭)
                          overflowY: 'auto', // 스크롤 가능하게 설정
                          scrollbarWidth: 'thin', // Firefox용 기본 스크롤바 폭 설정
                          scrollbarColor: '#888 #e0e0e0', // Firefox용 스크롤바 색상 설정
                        }}
                      >
                        <div className="text-[14px]  mb-1">
                          <span className="font-bold">
                            업로드한 이미지{' '}
                            {
                              selectedUploadImages.filter((image) => image)
                                .length
                            }
                            /5
                          </span>{' '}
                          (최대 5장 선택 가능)
                        </div>
                        <div className="flex items-center space-x-2 mb-3">
                          <button
                            onClick={handleButtonClick}
                            className="flex items-center space-x-1 px-3 py-2 text-white rounded-full bg-[#6B8ACB] text-[14px] font-bold"
                            type="button"
                          >
                            <img
                              src="assets/img/icon_image.png"
                              width="17px"
                              alt=""
                            />
                            <span>파일 업로드</span>
                          </button>
                          <span className="text-[#8F929B] text-[16px] text-sm">
                            총 20MB | jpg, jpeg, png
                          </span>

                          <input
                            id="file-input"
                            type="file"
                            accept=".jpg,.jpeg,.png"
                            multiple
                            className="hidden"
                            onChange={handleFileChange}
                          />
                        </div>
                        <div
                          style={{
                            ...styles.gridContainer,
                            ...(window.innerWidth <= 768
                              ? styles.gridContainerMobile
                              : {}),
                          }}
                        >
                          {uploadImages.map((src, index) => (
                            <div style={{ marginBottom: '20px' }} key={index}>
                              <div
                                style={{
                                  ...getGridItemStyle(
                                    selectedUploadImages[index]
                                  ),
                                  ...(window.innerWidth <= 768
                                    ? styles.gridItemMobile
                                    : {}),
                                }}
                              >
                                <img
                                  src={
                                    typeof src === 'string'
                                      ? src
                                      : URL.createObjectURL(src)
                                  }
                                  alt={`img_${index + 1}`}
                                  style={styles.image}
                                />
                              </div>
                              <div style={styles.checkboxContainer}>
                                <input
                                  type="checkbox"
                                  checked={selectedUploadImages[index]}
                                  onChange={() =>
                                    handleUploadCheckboxChange(index)
                                  }
                                  style={styles.checkbox}
                                />
                                <span style={styles.label}>
                                  {String(index + 1).padStart(2, '0')}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="flex flex-col w-[343px] md:w-full ml-4 md:ml-0">
              <div className="mb-4">
                <label className="md:ml-0 font-bold text-[#2E343D] text-[18px] ">
                  영상 타입 선택
                </label>
              </div>
              <div className="grid grid-cols-2">
                {[
                  { name: '숏폼', value: true },
                  { name: '롱폼(준비중)', value: false },
                ]?.map((el, index) => {
                  return (
                    <>
                      <CircularCheckbox
                        id={index}
                        disabled={!el.value}
                        label={el.name}
                        checked={el.value}
                        onChange={() => {}}
                      />
                    </>
                  )
                })}
              </div>
            </div>
          </ShadowBox>

          <>
            <ShadowBox
              className={classNames('py-[40px] px-[16px] md:w-[816px]', {
                'opacity-30 pointer-events-none': '',
              })}
            >
              <div className="flex flex-col gap-[40px] md:gap-0 w-[343px] md:w-[736px]">
                <div className="flex flex-col items-center  mt-[16px] md:mb-[40px] relative ">
                  <p className="font-bold text-[#2E343D] text-[24px] ">
                    효과 선택
                  </p>
                  <p className="text-main text-[14px]">
                    원하는 분위기의 영상 생성을 위해 각 효과를 선택해주세요!
                  </p>
                  <div className="md:absolute  md:right-0 md:mt-0 mt-4">
                    <p className=" bg-clip-text text-transparent bg-gradient-to-r from-[rgba(96,149,255,1)] to-[rgba(213,18,231,1)]">
                      영상 효과 할인 이벤트!
                    </p>
                    <img
                      src="assets/img/short_video_editor/sale.png"
                      alt="sale"
                      width={141}
                      height={32}
                    />
                  </div>
                </div>
                <TextTypeAiScriptDisplay
                  watch={watch}
                  setValue={setValue}
                  control={control}
                  languageList={languageList}
                  voiceList={voiceList}
                  voiceRefetch={voiceRefetch}
                  userInfo={userInfo}
                  voiceTags={voiceTags}
                  selectedVoiceId={watch('voiceId')}
                />
              </div>
            </ShadowBox>
          </>
          <div className="flex justify-center">
            <p className="text-center">
              패스트컷은 사용자가 제공하는 텍스트/이미지/영상을 토대로 영상을
              만들고 있으며,
              <br />
              AI의 특성상 내용에 실수가 있을 수 있습니다.
              <br />
              지적재산권 침해 여부는 사용자가 확인하여야 하며, 이에 관하여
              패스트컷은 책임이 없습니다.
            </p>
          </div>
          <div className="w-full">
            <button
              className="w-full bg-main text-[18px] rounded-full text-white py-5 font-bold"
              type="submit"
            >
              컨텐츠 영상 생성 요청
            </button>
          </div>
        </form>
      </div>
      {isUseModal && (
        <TextCashUseModal
          isUseModal={isUseModal}
          setUseModal={setUseModal}
          currentPoint={myPointData.totalPoint}
          textVideoInfo={textVideoInfo}
          textVideoPriceInfo={textVideoPriceInfo}
          setSaveSuccessModal={setSaveSuccessModal}
          setValue={setValue}
          getMyPointRefetch={getMyPointRefetch}
        />
      )}
      {saveSuccessModal && (
        <Modal isModal={saveSuccessModal}>
          <div className="bg-white w-[335px] h-[184px] p-[20px] border-t rounded-t-[20px]">
            <div className="flex flex-col items-center justify-center h-full gap-[24px]">
              <p className="font-bold text-[18px]">요청 완료</p>
              <div>
                <p className="text-[14px] font-bold">
                  컨텐츠 영상 생성이 요청 되었습니다!
                </p>
                <p className="text-[14px] font-bold">
                  생성 현황은 <span className="text-main">‘사용 현황‘</span>{' '}
                  페이지에서 확인하세요.
                </p>
              </div>
            </div>
          </div>
          <div className="flex  w-[335px]">
            <div className="w-[50%] bg-[#EEF1F7] h-[60px] border-b border-[#EEF1F7] rounded-bl-[20px] flex justify-center items-center">
              <button
                className="text-[#5E6068] w-full font-bold"
                onClick={() => {
                  navigate('/my-videos?type=tcv&page=1&pageSize=80')
                }}
              >
                사용현황 페이지 가기
              </button>
            </div>
            <div className="w-[50%] bg-main h-[60px] border-b border-main rounded-br-[20px] flex justify-center items-center">
              <button
                className="text-white w-full font-bold"
                onClick={(event) => {
                  setSaveSuccessModal(false)
                }}
              >
                확인
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}
