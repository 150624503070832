import classNames from 'classnames'
import { Modal } from 'components/layout/Modal'
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { isMobile } from 'react-device-detect'
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { Me } from 'service/user'
import { FormValues } from 'utils/type'
import { ReactComponent as StarIcon } from 'svg/star_white.svg'
import { ReactComponent as TrashIcon } from 'svg/trash.svg'
import useGetLinks from 'hooks/query/extension/useGetLinks'
import { Link, LinkDetailType } from 'service/list'
import useDeleteLink from 'hooks/query/extension/useDeleteLink'
import useGetLinkDetail from 'hooks/query/extension/useGetLinkDetail'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Spinner } from 'components/atom/Spinner'
import { BeatLoader } from 'react-spinners'
import useGetAiRecommendClipTitle from 'hooks/query/extension/useGetAiRecommendClipTitle'
import { getLocalStorage } from 'utils/common'

interface ExtensionListModalProps {
  watch: UseFormWatch<FormValues>
  setValue: UseFormSetValue<FormValues>
  control: Control<FormValues, any>
  translatePlanName?: (planName: string) => string
  userInfo: Me
  setOpenExtensionMethod: Dispatch<SetStateAction<boolean>>
  isOpenExtensionMethod: boolean
  selectedLinkIds: number[]
  setSelectedLinkIds: Dispatch<SetStateAction<number[]>>
  linkPageSize: number
  setLinkPageSize: Dispatch<SetStateAction<number>>
  linkCurrentPage: number
  setLinkCurrentPage: Dispatch<SetStateAction<number>>
  LINK_PAGE_CNT: number
  setSelectedLinks: Dispatch<SetStateAction<LinkDetailType[]>>
  isAiName: boolean
  setAiName: Dispatch<React.SetStateAction<boolean>>
  setAiRecommendClipTitle: Dispatch<React.SetStateAction<string[] | null>>
}

export const ExtensionListModal = ({
  watch,
  setValue,
  control,
  translatePlanName,
  userInfo,
  setOpenExtensionMethod,
  isOpenExtensionMethod,
  selectedLinkIds,
  setSelectedLinkIds,
  linkPageSize,
  setLinkPageSize,
  linkCurrentPage,
  setLinkCurrentPage,
  LINK_PAGE_CNT,
  setSelectedLinks,
  isAiName,
  setAiName,
  setAiRecommendClipTitle,
}: PropsWithChildren<ExtensionListModalProps>) => {
  const [isOpenErrorModal, setOpenErrorModal] = useState<boolean>(false)
  const [isOpenDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<number | null>(null)
  const [linkListStatus, setLinkListStatus] = useState<boolean[]>([])
  const [hasMore, setHasMore] = useState<boolean>(true)

  const isExtensionInstalled = getLocalStorage('fs_extension')
  const [isExtension, setExtension] = useState(false)

  const { mutate, mutateAsync, isPending } = useGetAiRecommendClipTitle()
  const {
    data: linkList,
    refetch: linkRefetch,
    isPending: linkListPending,
  } = useGetLinks(linkPageSize, 1)
  const { mutate: deleteLinkMutate, isPending: deletePending } = useDeleteLink()
  const queryResults = useGetLinkDetail(selectedLinkIds)
  const isLoading = queryResults.some((result) => result.isLoading)
  const isError = queryResults.some((result) => result.isError)
  const data = queryResults.map((result) => result.data ?? null) // 각 링크에 대한 데이터

  const handleModalOpen = () => {
    setOpenExtensionMethod((prev) => !prev)
  }
  const handleDeleteModal = () => {
    setOpenDeleteModal((prev) => !prev)
  }
  const handleErrorModal = () => {
    setOpenErrorModal((prev) => !prev)
  }
  const handleListStatus = (id: number) => {
    // 배열에 id가 존재하면 filter로 삭제
    const check = selectedLinkIds.includes(id)
    if (check) {
      const newArr = selectedLinkIds.filter((num) => num !== id)
      setSelectedLinkIds(newArr)
    } else {
      const currentArr = [...selectedLinkIds, id]
      if (currentArr.length <= 3) {
        setSelectedLinkIds(currentArr)
      }
    }
  }

  const handleDeleteLink = () => {
    if (deleteId) {
      deleteLinkMutate(deleteId, {
        onSuccess: () => {
          linkRefetch()
          handleDeleteModal()
        },
        onError: (e) => {
          console.log(e?.message)
        },
      })
    } else {
      handleDeleteModal()
    }
  }

  const handleListRefetch = () => {
    linkRefetch()
  }

  const handleAiRecommendClipTitle = async () => {
    const selectedIdsCnt = selectedLinkIds.length
    if (selectedIdsCnt === 0) {
      setOpenErrorModal((prev) => !prev)
    } else {
      handleModalOpen()

      const data = await mutateAsync(selectedLinkIds)

      setAiRecommendClipTitle(data)
    }
  }

  useEffect(() => {
    if (!data) return

    const newDataArr = data.filter((el): el is LinkDetailType => el !== null)

    setSelectedLinks((prev) => {
      // 기존 상태와 비교하여 변경된 경우에만 업데이트
      if (JSON.stringify(prev) !== JSON.stringify(newDataArr)) {
        return newDataArr
      }
      return prev // 값이 동일하면 상태 변경 X → 렌더링 방지
    })
  }, [data])

  useEffect(() => {
    if (linkList) {
      setLinkListStatus(new Array(linkList.data.totalCount).fill(false))
      // 기존에 담겨있던 id가 삭제되었는지도 확인해야함
      const checkList = linkList.data.links.filter((el) =>
        selectedLinkIds.slice(0, 3).includes(el.id)
      )
      setSelectedLinkIds(checkList.map((el) => el.id))

      //현재 item page * pageCnt

      const currentLinkCnt = linkList.data.links.length

      if (linkList.data.totalCount < linkCurrentPage * LINK_PAGE_CNT) {
        setHasMore(false)
      } else {
        const currentPage = linkCurrentPage
        const nextPage = currentPage + 1
        setLinkPageSize(nextPage * LINK_PAGE_CNT)
        setLinkCurrentPage(nextPage)
      }
    }
  }, [linkList])
  useEffect(() => {
    setExtension(isExtensionInstalled)
  }, [isExtensionInstalled])
  useEffect(() => {
    if (isOpenExtensionMethod) {
      linkRefetch()
    }
  }, [isOpenExtensionMethod])

  const LinkRowEl = (link: Link, index: number) => {
    return (
      <div className="flex gap-2 items-center mb-2" key={`link_${index}`}>
        <div
          className={classNames(
            'rounded-[10px] flex gap-2 items-center px-2 md:py-3 py-1 w-full cursor-pointer',
            {
              'opacity-30':
                selectedLinkIds.length === 3 &&
                !selectedLinkIds.includes(link.id),
              'bg-[#BBEBEC]': selectedLinkIds.includes(link.id),
              'bg-[#F6F8FB]': !selectedLinkIds.includes(link.id),
            }
          )}
          onClick={() => {
            handleListStatus(link?.id)
          }}
        >
          <input
            type="checkbox"
            className="flex-shrink-0 w-[18px] h-[18px] appearance-none border border-gray-400 rounded-md checked:bg-main checked:border-main cursor-pointer relative 
after:content-[''] after:absolute after:w-[5px] after:h-[10px] after:border-r-2 after:border-b-2 after:border-white after:rotate-45 after:top-[2px] after:left-[5px] after:opacity-0 checked:after:opacity-100"
            checked={selectedLinkIds.includes(link.id)}
          />

          <div className="flex flex-col font-bold text-[12px] text-[#B6BBC6] flex-shrink-0">
            {String(index + 1).padStart(3, '0')}
            <div className="flex justify-end">
              <img
                src={
                  link.shop === 'ali'
                    ? '/assets/img/ali_icon.png'
                    : '/assets/img/coupang_icon.png'
                }
                alt="youtube icon"
                className="w-[12px] h-[12px]"
              />
            </div>
          </div>
          <div className="flex-shrink-0">
            {' '}
            <img
              src={link?.thumbnailImage ?? ''}
              alt="youtube icon"
              className="w-[32px] h-[32px] "
            />
          </div>
          <div className="text-left">{link?.title}</div>
        </div>
        <div>
          <button
            onClick={() => {
              setDeleteId(link?.id)
              setOpenDeleteModal((prev) => !prev)
            }}
          >
            <TrashIcon />
          </button>
        </div>
      </div>
    )
  }

  const handleDisplayByMobile = () => {
    if (isMobile) {
      return (
        <>
          {linkListPending || isPending ? (
            <div className="md:overflow-auto md:h-[500px]  md:pb-4  flex items-center justify-center">
              {' '}
              <BeatLoader color="#00B7B9"></BeatLoader>
            </div>
          ) : userInfo &&
            linkList?.data?.totalCount &&
            linkList?.data?.totalCount > 0 ? (
            <>
              {' '}
              {
                <div
                  className="md:overflow-auto md:h-[500px]  md:pb-4 "
                  id="scroll_modal"
                >
                  <InfiniteScroll
                    dataLength={linkList.data.links.length} // 현재 로드된 데이터의 길이
                    next={handleListRefetch} // 데이터가 더 필요할 때 호출될 함수
                    hasMore={hasMore} // 더 불러올 데이터가 있는지 여부
                    loader={<BeatLoader color="#00B7B9"></BeatLoader>} // 로딩 중에 표시될 컴포넌트
                    endMessage={<div></div>} // 모든 아이템을 불러왔을 때 표시될 메시지
                    scrollableTarget="scroll_modal" // 스크롤 대상 (모달의 ID 또는 클래스를 사용)
                  >
                    {linkList?.data.links?.map((link, index) => (
                      <>{LinkRowEl(link, index)}</>
                    ))}
                  </InfiniteScroll>
                </div>
              }
            </>
          ) : (
            isExtension && (
              <>
                <div className="md:overflow-auto md:h-[500px]  md:pb-4 ">
                  <div>
                    {linkList?.data.totalCount === 0 && userInfo && (
                      <>
                        <p className="text-error">
                          아직 확장 프로그램에서 수집한 상품이 없어요!
                        </p>

                        <button className="text-main mt-6 font-bold">
                          <a
                            href="https://www.coupang.com"
                            target="_blank"
                            rel="noreferrer"
                          >
                            상품 수집하기 {`>`}
                          </a>
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </>
            )
          )}
        </>
      )
    } else {
      return (
        <>
          {!isExtension && (
            <div className="md:overflow-auto md:h-[500px]  md:pb-4 ">
              <p className="text-error">
                아직 확장 프로그램을 설치하지 않았어요!
              </p>

              <button className=" mt-6 font-bold">
                <a
                  href="https://chromewebstore.google.com/detail/meeeiblldcmbdhhaibnbpdiiknkoaakl?utm_source=item-share-cb"
                  target="_blank"
                  rel="noreferrer"
                  className="flex gap-2 items-center"
                >
                  <img
                    src="assets/img/logo/extension.png"
                    alt="extension"
                    className="w-[26px] h-[26px]"
                  />
                  <span className="font-bold">
                    패스트컷 AI 확장 프로그램 설치하기 {`>`}
                  </span>{' '}
                </a>
              </button>
            </div>
          )}

          {isExtension && (linkListPending || isPending) ? (
            <div className="md:overflow-auto md:h-[500px]  md:pb-4  flex items-center justify-center">
              {' '}
              <BeatLoader color="#00B7B9"></BeatLoader>
            </div>
          ) : userInfo &&
            linkList?.data?.totalCount &&
            linkList?.data?.totalCount > 0 &&
            isExtension ? (
            <>
              {' '}
              {
                <div
                  className="md:overflow-auto md:h-[500px]  md:pb-4 "
                  id="scroll_modal"
                >
                  <InfiniteScroll
                    dataLength={linkList.data.links.length} // 현재 로드된 데이터의 길이
                    next={handleListRefetch} // 데이터가 더 필요할 때 호출될 함수
                    hasMore={hasMore} // 더 불러올 데이터가 있는지 여부
                    loader={<BeatLoader color="#00B7B9"></BeatLoader>} // 로딩 중에 표시될 컴포넌트
                    endMessage={<div></div>} // 모든 아이템을 불러왔을 때 표시될 메시지
                    scrollableTarget="scroll_modal" // 스크롤 대상 (모달의 ID 또는 클래스를 사용)
                  >
                    {linkList?.data.links?.map((link, index) => (
                      <>{LinkRowEl(link, index)}</>
                    ))}
                  </InfiniteScroll>
                </div>
              }
            </>
          ) : (
            isExtension && (
              <>
                <div className="md:overflow-auto md:h-[500px]  md:pb-4 ">
                  <div>
                    {linkList?.data.totalCount === 0 && userInfo && (
                      <>
                        <p className="text-error">
                          아직 확장 프로그램에서 수집한 상품이 없어요!
                        </p>

                        <button className="text-main mt-6 font-bold">
                          <a
                            href="https://www.coupang.com"
                            target="_blank"
                            rel="noreferrer"
                          >
                            상품 수집하기 {`>`}
                          </a>
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </>
            )
          )}
        </>
      )
    }
  }

  // useEffect(() => {
  //   if (isOpenExtensionMethod) {
  //     document.body.classList.add('overflow-hidden') // Tailwind 유틸리티 클래스 사용
  //   } else {
  //     document.body.classList.remove('overflow-hidden')
  //   }
  // }, [isOpenExtensionMethod])

  return (
    <>
      <Modal isModal={isOpenExtensionMethod}>
        <div className="md:h-screen h-full w-full fixed left-0 top-0 flex flex-col justify-center items-center bg-black bg-opacity-50 text-center  z-50">
          <div
            className={classNames('', {
              'bg-white md:w-[816px] h-auto  shadow-main rounded-t-[20px] px-8 pt-8  flex flex-col   gap-8':
                !isMobile,
              'bg-white w-full h-full  shadow-main   flex flex-col   gap-8 pt-10 px-4  ':
                isMobile,
            })}
          >
            <div
              className={classNames('flex flex-col  gap-4 w-full ', {
                'justify-between': isMobile,
              })}
            >
              <p
                className={classNames('flex items-center', {
                  'justify-between w-full': isMobile,
                  'justify-between': !isMobile,
                })}
              >
                <span className="font-bold md:text-[24px] text-left text-[20px]">
                  확장 프로그램에서 수집한 상품{' '}
                  <span className="text-main ml-1">
                    {linkList?.data.totalCount}
                  </span>
                </span>
                <button onClick={handleModalOpen}>
                  <img
                    src="assets/img/Icon_x.png"
                    alt="icon_x"
                    height={40}
                    width={40}
                  />
                </button>
              </p>
              <p
                className={classNames(' cursor-pointer', {
                  'flex items-center gap-1 p-2 bg-black rounded-[20px] text-white w-[302px]':
                    !isAiName,
                  'flex items-center gap-1 p-2 bg-[#4360EC] rounded-[20px] text-white w-[302px]':
                    isAiName,
                })}
                onClick={() => {
                  setAiName((prev) => !prev)
                }}
              >
                <input
                  type="checkbox"
                  className={classNames('', {
                    "w-[18px] h-[18px]  appearance-none border bg-white border-gray-400 rounded-md  cursor-pointer relative before:content-[''] before:absolute before:w-[5px] before:h-[10px] before:border-r-2 before:border-b-2 before:border-[#D9DEE8] before:rotate-45 before:top-[2px] before:left-[5px] before:opacity-100 mr-1":
                      !isAiName,
                    "w-[18px] h-[18px] appearance-none border border-gray-400 rounded-md checked:bg-white checked:border-[#4360EC] cursor-pointer relative after:content-[''] after:absolute after:w-[5px] after:h-[10px] after:border-r-2 after:border-b-2 after:border-[#4360EC] after:rotate-45 after:top-[2px] after:left-[5px] after:opacity-0 checked:after:opacity-100 mr-1":
                      isAiName,
                  })}
                  checked={isAiName}
                />
                <StarIcon />
                <span>AI 추천 상품명으로 개선해서 반영하기</span>
              </p>
            </div>
            <div className="overflow-auto ">
              {handleDisplayByMobile()}

              {isMobile && (
                <div className="pt-5 pb-20 w-[335px]">
                  <button
                    className="bg-main rounded-full w-full text-white font-bold h-[60px]"
                    onClick={handleAiRecommendClipTitle}
                    type="button"
                    disabled={isPending}
                  >
                    선택 완료{' '}
                    <span className="text-[14px] font-medium">{`(${selectedLinkIds.length}개)`}</span>
                  </button>
                </div>
              )}
            </div>
          </div>
          {!isMobile && (
            <div
              className="relative p-8 shadow-main w-full md:w-[816px] bg-white rounded-b-[20px] 
         before:content-[''] before:absolute before:top-[-10px] before:left-0 
         before:w-full before:h-[10px] before:bg-gradient-to-t before:from-black/10 before:to-transparent z-50"
            >
              <button
                className="bg-main rounded-full w-full text-white font-bold h-[60px]"
                onClick={handleAiRecommendClipTitle}
                type="button"
                disabled={isPending}
              >
                선택 완료{' '}
                <span className="text-[14px] font-medium">{`(${selectedLinkIds.length}개)`}</span>
              </button>
            </div>
          )}
        </div>
      </Modal>
      <Modal isModal={isOpenErrorModal}>
        <div className="bg-white w-[335px] h-auto p-[20px] border-t rounded-t-[20px]">
          <div className="flex flex-col items-center justify-center h-full gap-[24px]">
            <p className="font-bold text-[18px]">
              🚨 선택 가능 개수를 확인해주세요!
            </p>
            <div className="flex flex-col leading-[22px]">
              <p className="text-[14px] text-[#5E6068]">
                영상을 생성하기 위해서는 <br />
                <span className="text-main font-bold">
                  최소 1개 ~ 최대 3개의 상품을
                </span>
                <br />
                선택해주세요!
              </p>
            </div>
          </div>
        </div>
        <div className="w-[335px] text-[14px]  h-[60px]  flex justify-center items-center">
          <button
            className="text-white w-[100%] h-full bg-main rounded-b-[20px] border-b border-main font-bold"
            onClick={handleErrorModal}
            type="button"
          >
            확인
          </button>
        </div>
      </Modal>
      <Modal isModal={isOpenDeleteModal}>
        {deletePending ? (
          <>
            <div className="bg-white w-[335px] h-[222px] p-[20px] border-t rounded-[20px] flex items-center justify-center flex-col gap-6">
              <p className="font-bold text-[18px]">항목 삭제중 ...</p>
              <BeatLoader color="#00B7B9"></BeatLoader>
            </div>
          </>
        ) : (
          <>
            <div className="bg-white w-[335px] h-auto p-[20px] border-t rounded-t-[20px]">
              <div className="flex flex-col items-center justify-center h-full gap-[24px]">
                <p className="font-bold text-[18px]">항목 삭제</p>
                <div className="flex flex-col leading-[22px]">
                  <p className="text-[14px] text-[#5E6068]">
                    <span className="text-main font-bold">
                      선택된 항목을 삭제하시겠어요?
                    </span>
                    <br />
                    삭제된 항목은 되돌릴 수 없습니다.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-[335px] text-[14px]  h-[60px]  flex justify-center items-center">
              <button
                className="text-[#5E6068] w-[50%] h-full bg-[#EEF1F7] rounded-bl-[20px] border-b border-[EEF1F7] font-bold"
                onClick={handleDeleteModal}
                type="button"
              >
                취소
              </button>
              <button
                className="text-white w-[50%] h-full bg-main rounded-br-[20px] border-b border-main font-bold"
                onClick={handleDeleteLink}
                type="button"
              >
                확인
              </button>
            </div>
          </>
        )}
      </Modal>
    </>
  )
}
