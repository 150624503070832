import classNames from 'classnames'
import React from 'react'
import { isMobile } from 'react-device-detect'

export const ExtensionTooltip = (props: any) => {
  const { setOpenExtensionTooltip } = props

  const handleModal = () => {
    setOpenExtensionTooltip((prev: boolean) => !prev)
  }

  const handleMoveLink = (url: string) => {
    if (url) {
      window.open(url, '_blank')
    }
  }

  return (
    <div
      className={classNames(
        'flex flex-col p-[16px] md:p-[32px] gap-[10px] bg-white    absolute border-[3px] border-main  rounded-[20px] ',
        {
          'w-[330px] bottom-8 left-0': !isMobile,
          'w-[280px] bottom-5 -left-32': isMobile,
        }
      )}
    >
      <p className=" md:leading-[19.2px] md:text-base  leading-[12px] text-[10px] flex justify-between items-center">
        <span className="font-bold text-[18px]">확장 프로그램이란?</span>
        <button className="" type="button" onClick={handleModal}>
          <img
            src="assets/img/Icon_x.png"
            alt="icon_x"
            height={30}
            width={30}
          />
        </button>
      </p>
      <p>
        패스트컷 AI 확장 프로그램은 크롬, 엣지, 웨일 브라우저에 설치하여 쿠팡 /
        알리익스프레스에서 상품 데이터를 수집하는 솔루션을 말해요.
      </p>
      <p className="">
        <span>확장 프로그램(익스텐션)이 제공하는</span>
        <ul className="text-main list-disc ml-5">
          <li>상품명 자동 입력</li>
          <li>상품 이미지 미리보기 및 선택</li>
          <li>최대 240개까지 수집한 상품 목록 관리</li>
        </ul>
        <span>등의 기능으로 압도적으로 편리한 영상 생성을 경험 해보세요!</span>
      </p>
      <p
        className="flex gap-2  text-[14px] items-center cursor-pointer"
        onClick={() => {
          handleMoveLink('https://www.youtube.com/watch?v=VMJKnYM8rH4')
        }}
      >
        <img
          src="assets/img/icon_youtube.png"
          alt="icon_youtube"
          className="w-[26px] h-[26px]"
        />
        <span className="font-bold">사용 가이드 영상 {`>`}</span>{' '}
      </p>
      <p
        className="flex gap-2  text-[14px] items-center cursor-pointer"
        onClick={() => {
          handleMoveLink('https://blog.fastseller.shop/fastcut_extention/')
        }}
      >
        <img
          src="assets/img/logo/fastcut-pay.jpg"
          alt="fastcut"
          className="w-[26px] h-[26px]"
        />
        <span className="font-bold">사용 가이드 블로그 {`>`}</span>{' '}
      </p>
      <p
        className="flex gap-2  text-[14px] items-center cursor-pointer"
        onClick={() => {
          handleMoveLink(
            'https://chromewebstore.google.com/detail/meeeiblldcmbdhhaibnbpdiiknkoaakl?utm_source=item-share-cb'
          )
        }}
      >
        <img
          src="assets/img/logo/extension.png"
          alt="extension"
          className="w-[26px] h-[26px]"
        />
        <span className="font-bold">
          패스트컷 AI 확장 프로그램 설치하기 {`>`}
        </span>{' '}
      </p>
    </div>
  )
}
