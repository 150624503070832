import { ThumbnailType } from 'service/list'
import { FormValues } from 'utils/type'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { UseFormWatch } from 'react-hook-form'
import { thumbnailOptions } from 'dummy/data'

interface MobilePrevThumbnailProps {
  userInfo: any
  thumbNail: ThumbnailType
  watch: UseFormWatch<FormValues>
}

export const MobilePrevThumbnail = ({
  userInfo,
  thumbNail,
  watch,
}: PropsWithChildren<MobilePrevThumbnailProps>) => {
  const [thumbnailTypeInfo, setThumbnailTypeInfo] = useState(
    thumbnailOptions[`thumbnail_${thumbNail.name}`]
  )
  const [imgSrc, setImgSrc] = useState<any>(
    'https://pub.angelleague.io/fast_cut/thumbnail/thumbnail00.png'
  )
  const [emptyChannelName, setEmptyChannelName] =
    useState<string>('내 채널 이름이 들어가요')

  const THUMBNAIL_PATTERN = /^[^\uD800-\uDFFF]*$/
  const CHANNEL_NAME_PATTERN = /^[^\uD800-\uDFFF&]*$/

  useEffect(() => {
    setThumbnailTypeInfo(thumbnailOptions[`thumbnail_${thumbNail.name}`])
  }, [thumbNail])

  return (
    <div className="w-full px-[18.5px]" key={`thumbnail_${thumbNail.id}`}>
      {thumbNail && (
        <div className="flex items-end justify-between  h-[190px]">
          <div className="flex mt-[16px] relative w-[338px] h-[190px]">
            <div className="flex relative w-[100%]">
              {thumbnailTypeInfo?.left_box && (
                <div
                  style={{
                    width: '193px',
                    height: '100%',
                    overflow: 'hidden',
                    position: 'absolute',
                    right: '0px',
                  }}
                >
                  <img
                    style={{
                      width: '193px',
                      height: '100%',
                      position: 'absolute',
                      objectFit: 'cover',
                      objectPosition: 'center',
                      overflow: 'hidden',
                    }}
                    src={
                      typeof imgSrc === 'string'
                        ? imgSrc
                        : URL.createObjectURL(imgSrc)
                    }
                    alt="thumbnail"
                  />
                </div>
              )}
              {!thumbnailTypeInfo?.left_box && (
                <div
                  style={{
                    width: '338px',
                    height: '100%',
                  }}
                >
                  <img
                    style={{
                      width: '338px',
                      height: '100%',
                      position: 'absolute',
                      objectFit: 'cover',
                      overflow: 'hidden',
                    }}
                    src={
                      typeof imgSrc === 'string'
                        ? imgSrc
                        : URL.createObjectURL(imgSrc)
                    }
                    alt="thumbnail"
                  />
                </div>
              )}

              {/* opacity 배경 */}
              {thumbnailTypeInfo?.opacity && (
                <div
                  className={` op absolute w-[338px] h-[190px] bg-black opacity-[${thumbnailTypeInfo?.opacity}]`}
                ></div>
              )}

              {/* 그라데이션 배경 */}
              {thumbnailTypeInfo?.bg_gradient && (
                <div
                  className={` bg_gra absolute w-[338px] h-[190px] bg-black`}
                  style={{
                    background: thumbnailTypeInfo?.bg_gradient,
                  }}
                ></div>
              )}

              {/* inner border */}
              {thumbnailTypeInfo?.border && (
                <div className="absolute w-[338px] h-[190px] flex items-center justify-center z-40">
                  <div
                    className={`absolute w-[328px] h-[180px] border`}
                    style={{
                      borderWidth: `${thumbnailTypeInfo?.border.weight}`,
                      borderColor: `${thumbnailTypeInfo?.border.color}`,
                    }}
                  ></div>
                </div>
              )}

              {/* out border */}
              {thumbnailTypeInfo?.outline_border && (
                <div
                  className="absolute w-[338px] h-[190px] flex items-center justify-center z-50"
                  style={{
                    borderWidth: `${thumbnailTypeInfo?.outline_border.weight}`,
                    borderColor: `${thumbnailTypeInfo?.outline_border.color}`,
                  }}
                ></div>
              )}

              {/* 좌상단 채널 이름 */}
              <div
                className="channel_name absolute top-0  w-[185px] h-[18px] flex items-center pl-[12.08px] z-40"
                style={{
                  background:
                    'linear-gradient(to right, rgba(35, 35, 35, 1),rgba(167, 167, 167, 0))',
                }}
              >
                <p className="font-bold text-[10px] text-[#FFFFFF] ">
                  {watch('thumbnailInfo.channelName')
                    ? watch('thumbnailInfo.channelName')
                    : emptyChannelName}
                </p>
              </div>

              {thumbnailTypeInfo?.titleList.map((el, index) => {
                return thumbnailTypeInfo.left_box ? (
                  <>
                    {index === 0 && (
                      <>
                        <div
                          className={`absolute w-[148px] top-0 h-full   bg-black z-10 flex  `}
                          style={{
                            // top: `${el.position}`,
                            // textAlign: `${el.title.align ? 'left' : 'center'}`,
                            // whiteSpace: 'nowrap',
                            whiteSpace: el.title.wrap,
                            overflow: 'hidden',
                          }}
                        >
                          <p
                            className=" absolute  text-[transparent] z-0  flex"
                            style={{
                              top: `${el.title.top}`,
                              left: `${el.title.left}`,
                              width: el.title?.width ?? '',
                              fontSize: `${el.title.size}`,
                              fontWeight: `${el.title.weight}`,
                              fontFamily: `${el.title.font}`,
                              background: `${el.title.gradient?.background}`,
                              WebkitBackgroundClip: `${el.title.gradient?.text}`,
                              letterSpacing: `${el.title.letter}`,
                              color: `${el.title.color}`,
                              height: el.title?.height ?? '',
                              lineHeight: `${el.title.lineHeight}`,
                              overflow: 'hidden',
                              wordBreak: 'break-all',
                              paddingTop: el.title?.py ?? '',
                              alignItems: el.title.itemsEnd ? 'end' : '',
                            }}
                          >
                            {watch(`thumbnailInfo.${el.title.titleName}`)
                              ? watch(`thumbnailInfo.${el.title.titleName}`)
                              : el.title.titleContent}
                          </p>
                        </div>
                      </>
                    )}
                    {index >= 1 && (
                      <>
                        <div
                          className={`absolute w-[148px] top-0  z-10`}
                          style={{
                            top: `${el.position}`,
                            textAlign: `${el.title.align ? 'left' : 'center'}`,
                            whiteSpace: el.title.wrap,
                          }}
                        >
                          {/* {thumbnailTypeInfo?.blank_box && (
                            <p
                              className=" absolute h-[20px] bg-[#414141] text-[transparent] z-0"
                              style={{
                                top: `40%`,
                                left: '1%',
                                fontSize: `${el.title.size}`,
                                fontWeight: `${el.title.weight}`,
                                fontFamily: `${el.title.font}`,
                                letterSpacing: `-1px`,
                                color: el.title.bg ?/ ""
                              }}
                            >
                              {watch(`thumbnailInfo.${el.title.titleName}`)
                                ? watch(`thumbnailInfo.${el.title.titleName}`)
                                : el.title.titleContent}
                            </p>
                          )} */}
                          <p
                            className={` ${el.title.border ?? ''}  ${
                              el.title.bg ? ` bg-[${el.title?.bg}]` : ''
                            }   z-10 flex absolute align-middle `}
                            style={{
                              color: `${el.title.color}`,
                              fontSize: `${el.title.size}`,
                              fontWeight: `${el.title.weight}`,
                              fontFamily: `${el.title.font}`,
                              textShadow: `${el.title.shadow}`,
                              letterSpacing: `${el.title.letter}`,
                              width: el.title?.width ?? '',
                              padding: `${el.title.padding}`,
                              background: el.title.gradient?.background ?? '',
                              WebkitBackgroundClip:
                                el.title.gradient?.text ?? '',
                              WebkitTextStroke: `${el.title.stroke}`,
                              height: `${el.title.height}`,
                              whiteSpace: '',
                              top: `${el.title.top}`,
                              left: `${el.title.left}`,
                              bottom: `${el.title.bottom}`,
                              lineHeight: `${el.title.lineHeight}`,
                              justifyContent: el.title?.justifyCenter
                                ? 'center'
                                : '',
                              alignItems: el.title?.itemsCenter ? 'center' : '',
                              paddingLeft: el.title?.px ?? '',
                              paddingRight: el.title?.px ?? '',
                              paddingTop: el.title?.py ?? '',
                              overflow: 'hidden',
                              wordBreak: 'break-all',
                            }}
                          >
                            {watch(`thumbnailInfo.${el.title.titleName}`)
                              ? watch(`thumbnailInfo.${el.title.titleName}`)
                              : el.title.titleContent}
                          </p>{' '}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div
                      className={`absolute w-full h-[37px] flex items-center px-[10px] align-middle `}
                      style={{
                        top: `${el.position}`,
                        textAlign: `${el.title.align ? 'left' : 'center'}`,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {thumbnailTypeInfo?.blank_box && (
                        <p
                          className=" absolute h-[20px] bg-[#414141] text-[transparent] z-0"
                          style={{
                            top: `40%`,
                            left: '1%',
                            fontSize: `${el.title.size}`,
                            fontWeight: `${el.title.weight}`,
                            fontFamily: `${el.title.font}`,
                            letterSpacing: `-1px`,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            wordBreak: 'break-all',
                            width: el.title.width ?? '',
                            maxWidth: '338px',
                            height: el.title.height ?? '',
                          }}
                        >
                          {watch(`thumbnailInfo.${el.title.titleName}`)
                            ? watch(`thumbnailInfo.${el.title.titleName}`)
                            : el.title.titleContent}
                        </p>
                      )}
                      <p
                        className={`${el.title.border ?? ''} w-full z-10`}
                        style={{
                          color: `${el.title.color}`,
                          fontSize: `${el.title.size}`,
                          fontWeight: `${el.title.weight}`,
                          fontFamily: `${el.title.font}`,
                          textShadow: `${el.title.shadow}`,
                          letterSpacing: `${el.title.letter}`,
                          backgroundColor: `${el.title.bg}`,
                          padding: `${el.title.padding}`,
                          background: `${el.title.gradient?.background}`,
                          WebkitBackgroundClip: `${el.title.gradient?.text}`,
                          WebkitTextStroke: `${el.title.stroke}`,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          wordBreak: 'break-all',
                          width: el.title.width ?? '',
                          height: el.title.height ?? '',
                        }}
                      >
                        {watch(`thumbnailInfo.${el.title.titleName}`)
                          ? watch(`thumbnailInfo.${el.title.titleName}`)
                          : el.title.titleContent}
                      </p>{' '}
                    </div>
                  </>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
