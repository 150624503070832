import React, { PropsWithChildren, useEffect, useState } from 'react'
import { UseFormSetValue } from 'react-hook-form'
import { LinkDetailType } from 'service/list'
import { ReactComponent as YoutubeIcon } from 'svg/fi_youtube.svg'
import { FormValues } from 'utils/type'
import { ReactComponent as ImageIcon } from 'svg/fi_image.svg'
import { ReactComponent as CheckIcon } from 'svg/fi_check.svg'
import classNames from 'classnames'
import YouTube from 'react-youtube'

interface ProductVideoSelectorProps {
  selectedLink: LinkDetailType | null
  index: number
  setValue: UseFormSetValue<FormValues>
}
export const ProductVideoSelector = ({
  selectedLink,
  index,
  setValue,
}: PropsWithChildren<ProductVideoSelectorProps>) => {
  const [videoArr, setVideoArr] = useState<boolean[]>([])
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null)
  const [durationArr, setDurationArr] = useState<number[]>([])
  const [totalDuration, setTotalDuration] = useState<number>(0)

  const handleLoadedMetadata = (
    index: number,
    e: React.SyntheticEvent<HTMLVideoElement>
  ) => {
    const duration = e.currentTarget.duration

    setDurationArr((prev) => {
      const newArr = [...prev]
      newArr[index] = duration
      return newArr
    })
  }

  const updateVideoArr = (i: number, videoUrl: string) => {
    const currentArr = videoArr
    // 현재 url이 담겨져있는지 확인
    if (selectedVideo?.includes(videoUrl)) {
      const updateArr = currentArr.map((el, index) =>
        index === i ? false : el
      )
      const updateDuration = 0

      setSelectedVideo(null)
      setTotalDuration(updateDuration)
      setValue(`products.${index}.selectedVideos`, [])
      setValue(`products.${index}.selectedVideoTotalDuration`, 0)
      setVideoArr(updateArr)
    } else {
      const updateArr = currentArr.map((el, index) =>
        index === i ? true : false
      )
      const updateDuration = durationArr[i]

      setSelectedVideo(videoUrl)
      setTotalDuration(updateDuration)
      setValue(`products.${index}.selectedVideos`, [videoUrl])
      setValue(
        `products.${index}.selectedVideoTotalDuration`,
        updateDuration ?? 0
      )
      setVideoArr(updateArr)
    }
  }

  /**
   * YouTube URL에서 동영상 ID를 추출합니다.
   * 지원되는 URL 예시:
   *  - https://www.youtube.com/watch?v=VIDEO_ID
   *  - https://youtu.be/VIDEO_ID
   */
  function extractYouTubeId(url: string): string | null {
    /**
     * YouTube URL에서 동영상 ID를 추출합니다.
     * 지원되는 URL 예시:
     *   - https://www.youtube.com/watch?v=VIDEO_ID (ID 길이가 1~11자)
     *   - https://youtu.be/VIDEO_ID
     *   - https://www.youtube.com/embed/VIDEO_ID?params...
     *   - https://www.youtube.com/embed/?params&playlist=VIDEO_ID
     */
    const regex =
      /(?<=v=)([0-9A-Za-z_-]{1,11})|(?<=youtu\.be\/)([0-9A-Za-z_-]{1,11})|(?<=embed\/)([0-9A-Za-z_-]{1,11})|(?<=playlist=)([0-9A-Za-z_-]{1,11})/
    const match = url.match(regex)

    return match ? match[1] || match[2] || match[3] || match[4] || null : null
  }

  useEffect(() => {
    const videos = selectedLink?.video?.videos
    if (videos) {
      setVideoArr(
        new Array(videos.length)
          .fill(true)
          .map((el, index) => (index === 0 ? true : false))
      )
      const initSet = new Set([videos[0]])
      setSelectedVideo(videos[0])
      setValue(`products.${index}.selectedVideos`, Array.from(initSet))
    }
  }, [selectedLink])

  return (
    <div className="flex flex-col w-full mt-6">
      <p className="w-full font-bold flex gap-2 items-center">
        <YoutubeIcon />
        상품 영상 선택 {selectedVideo ? 1 : 0}/ {videoArr.length ? 1 : 0}
      </p>

      {videoArr.length > 0 && (
        <>
          {' '}
          <p className="w-full text-[14px] text-[#8F929B]">
            사용하고 싶은 영상을 선택해주세요. (최우선으로 사용되며, 영상 길이가
            충분할 경우 이미지는 사용되지 않습니다.)
          </p>
          <div className="bg-[#F6F8FB] w-full rounded-[10px] grid md:grid-cols-5 grid-cols-3 p-4 gap-x-[7px] gap-y-1">
            {selectedLink?.video?.videos?.map((el, el_index) => (
              <>
                <div className="w-[113px] h-[64px] relative ">
                  {' '}
                  {el.includes('www.youtube.com') ? (
                    <>
                      {' '}
                      <a href={el} target="_blank" rel="noreferrer">
                        <YouTube
                          videoId={extractYouTubeId(el) ?? ''}
                          opts={{ width: '113', height: '64' }}
                        />
                      </a>
                    </>
                  ) : (
                    <>
                      {' '}
                      <a href={el} target="_blank" rel="noreferrer">
                        <video
                          src={el}
                          className="border-[1px] border-[#D9DEE8] rounded-[4px] w-[113px] h-[64px]"
                          onLoadedMetadata={(e) =>
                            handleLoadedMetadata(el_index, e)
                          }
                          playsInline
                          loop
                          muted
                        />
                      </a>
                    </>
                  )}
                  <div
                    className={classNames(
                      'w-[28px] h-[28px] rounded-full border-[1px] flex items-center justify-center absolute  right-1 bottom-1 z-10',
                      {
                        'bg-main': videoArr[el_index],
                        'bg-black opacity-70': !videoArr[el_index],
                      }
                    )}
                    onClick={() => {
                      updateVideoArr(el_index, el)
                    }}
                  >
                    {videoArr[el_index] && <CheckIcon />}
                  </div>
                </div>
              </>
            ))}
          </div>
        </>
      )}
    </div>
  )
}
