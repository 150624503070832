import '../App.css'
import { GuideToUse } from '../components/GuideToUse'
import { CashPromotionBanner } from '../components/affiliate/CashPromotionBanner'
import { useNavigate } from 'react-router-dom'
import { useShortVideoData } from 'hooks/useShortVideoData'
import useUserStore from 'store/useUserStore'
import { LinkType } from 'components/shortvideo/display/LinkType'
import { useEffect, useState } from 'react'
import { TextType } from 'components/shortvideo/display/TextType'

export const ShortVideo = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const createType = urlParams.get('createType')
  const [tab, setTab] = useState<'link' | 'text'>('link')
  const { myPointData, getMyPointRefetch, voiceTags } = useShortVideoData()
  const navigate = useNavigate()
  const userInfo = useUserStore((state: any) => state.userInfo)
  const userCheck = (userInfo: any) => {
    if (!userInfo) {
      navigate('/sign-up')
      return
    }
  }
  const updateUrlParams = (createType: string) => {
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.set('createType', createType === 'link' ? 'link' : 'text') // 값 설정

    navigate(`?${urlParams.toString()}`, { replace: true })
  }

  const changeTab = (selectedTab: 'link' | 'text') => {
    updateUrlParams(selectedTab)
    setTab(selectedTab)
  }
  useEffect(() => {
    if (createType) {
      setTab(createType === 'link' ? 'link' : 'text')
    }
  }, [createType])
  return (
    <>
      <div className="md:max-w-[816px] w-full my-0 mx-auto flex flex-col items-center gap-[16px]">
        <div className=" relative flex flex-col items-center w-[343px] md:w-full gap-2">
          <h1 className="font-bold text-[28px] md:text-[40px]">
            컨텐츠 영상 만들기
          </h1>
          <button
            className=" w-[224px] h-[59px]  bg-[#F6F8FB] rounded-full md:absolute right-0 top-1 flex justify-between items-center px-6 font-bold"
            onClick={() => {
              navigate('/my-cash-dashboard')
              userCheck(userInfo)
            }}
          >
            <p> 보유캐시</p>
            <p className="text-main">
              {myPointData.totalPoint.toLocaleString()}
              {' >'}
            </p>
          </button>
        </div>
        {userInfo?.affiliate?.point && (
          <CashPromotionBanner
            daysLeft={userInfo?.affiliate.point.daysLeft}
            hoursLeft={userInfo?.affiliate.point.hoursLeft}
            affiliateImage={userInfo?.affiliate.point.affiliateImage}
            affiliateDescription={
              userInfo?.affiliate.point.affiliateDescription
            }
            description={userInfo?.affiliate.point.description}
          />
        )}
        {tab === 'link' && (
          <LinkType tab={tab} setTab={setTab} changeTab={changeTab} />
        )}
        {tab === 'text' && (
          <TextType
            tab={tab}
            setTab={setTab}
            changeTab={changeTab}
            voiceTags={voiceTags}
          />
        )}

        <GuideToUse />
      </div>
    </>
  )
}
