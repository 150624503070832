// hooks/useShortVideoData.ts
import useGetBgm from '../hooks/query/useGetBgm'
import useGetVoice from '../hooks/query/useGetVoice'

import useGetPromptDuration from '../hooks/query/useGetPromptDuration'
import useGetBg from '../hooks/query/useGetBg'
import useGetTemplate from '../hooks/query/useGetTemplate'
import useGetVoiceTag from '../hooks/query/useGetVoiceTag'

import useGetAnimation from '../hooks/query/useGetAnimation'
import useGetSubtitleColor from '../hooks/query/useGetSubtitleColor'
import useGetTempo from '../hooks/query/useGetTempo'
import { useGetMyPoint } from '../hooks/query/point/useGetMyPoint'
import { useGetSubtitleShort } from 'hooks/query/useGetSubtitle'
import { useGetShortTypeTone } from 'hooks/query/useGetTone'

export const useShortVideoData = () => {
  const { data: myPointData, refetch: getMyPointRefetch } = useGetMyPoint()
  const { data: bgmList } = useGetBgm()
  const { data: subTitleList } = useGetSubtitleShort()
  const { data: voiceList } = useGetVoice()
  const { data: toneList } = useGetShortTypeTone('short')
  const { data: animationList } = useGetAnimation()
  const { data: aiPromptDurationList } = useGetPromptDuration()
  const { data: bgList } = useGetBg()
  const { data: templateList } = useGetTemplate()
  const { data: voiceTags } = useGetVoiceTag()
  const { data: subtitleColorList } = useGetSubtitleColor()
  const { data: tempoList } = useGetTempo()

  return {
    myPointData,
    getMyPointRefetch,
    bgmList,
    subTitleList,
    voiceList,
    toneList,
    animationList,
    aiPromptDurationList,
    bgList,
    templateList,
    voiceTags,
    subtitleColorList,
    tempoList,
  }
}
