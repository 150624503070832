import { Dispatch, PropsWithChildren, useEffect } from 'react'
import ProductNameInput from './ProductNameInput'
import {
  Control,
  FieldArrayWithId,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { FormValues } from 'utils/type'
import { Me, Usage } from 'service/user'
import { ProductVideoSelector } from './ProductVideoSelector'
import { ProductImageSelector } from './ProductImageSelector'
import { ProductDetailImageSelector } from './ProdcutDetailImageSelector'
import { FileUploader } from './FileUploader'
import { LinkDetailType } from 'service/list'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { InputEmpty } from 'components/atom/Input'
import { ReactComponent as ImageIcon } from 'svg/fi_image.svg'
import { ReactComponent as CheckIcon } from 'svg/fi_check.svg'
import { ReactComponent as YoutubeIcon } from 'svg/fi_youtube.svg'
import { ReactComponent as EditIcon } from 'svg/fi_edit.svg'
import { ReactComponent as StarIcon } from 'svg/star_white.svg'
interface ProductItemEmptyProps {
  watch: UseFormWatch<FormValues>
  setValue: UseFormSetValue<FormValues>
  control: Control<FormValues, any>
  fields: FieldArrayWithId<FormValues, 'products', 'id'>[]
  errors: FieldErrors<FormValues>
  setErrorText: Dispatch<React.SetStateAction<string>>
  userInfo: Me
  usage: Usage | undefined
  error: {
    products: {
      link: boolean
      product: boolean
    }[]
    mainTitle: boolean
    thumbnail: boolean
  }
  selectedLinks: LinkDetailType[]
  setError: any
  isAiName: boolean
}

const ProductItemEmpty = ({
  watch,
  setValue,
  control,
  fields,
  errors,
  error,
  setErrorText,
  userInfo,
  usage,
  selectedLinks,
  setError,
  isAiName,
}: PropsWithChildren<ProductItemEmptyProps>) => {
  const index = 1
  const selectedLink: LinkDetailType | null = null
  return (
    <div
      className={classNames(
        'flex flex-col items-center w-[375px] md:w-full gap-1',
        { 'px-4': isMobile }
      )}
    >
      <p className="text-[18px] font-bold bg-[#2E343D] text-white w-full rounded-[6px] px-2">
        상품 1
      </p>
      {/* 상품명 입력 */}
      <div className="flex flex-col w-full mt-6 gap-2">
        <p className="w-full font-bold flex gap-2 items-center">
          <EditIcon />
          상품명 편집
        </p>
        <div className="product_name cursor-pointer">
          <p
            className={classNames(
              'px-2 py-1 rounded-[4px] inline-flex gap-2 items-center',
              {
                'bg-[#BBEBEC]': true,
              }
            )}
          >
            {' '}
            <span className="font-bold text-white bg-[#2E343D] flex  items-center w-[65px] h-[22px] rounded-[4px] text-[12px] justify-center ">
              <StarIcon className="text-white" /> AI 추천
            </span>
            {'--'}
          </p>
        </div>
        <div className=" cursor-pointer">
          <p
            className={classNames(
              'px-2 py-1 rounded-[4px] inline-flex gap-2 items-center',
              {
                'bg-[#EEF1F7] ': true,
              }
            )}
          >
            <span className="text-[12px] bg-[#8F929B] px-1 py-[2px] text-white shrink-0 rounded-[4px] font-bold">
              원본
            </span>
            {'--'}
          </p>
        </div>
      </div>
      <textarea
        value={`패스트컷 확장프로그램에서 수집한 쿠팡이나 알리익스프레스 상품을 먼저 선택해${
          isMobile ? '' : '\n'
        }주셔야 영상으로 만들 상품명을 불러올 수 있어요!`}
        className="text-error w-full border-[1px] border-[#D9DEE8] rounded-[10px] md:h-[80px] h-[100px] px-4 py-4  mt-2"
        disabled
      />
      {/* 상품 영상 선택 */}
      <div className="flex flex-col w-full mt-6">
        <p className="w-full font-bold flex gap-2 items-center">
          <YoutubeIcon />
          상품 영상 선택 0/0
        </p>
      </div>
      <div className={classNames('flex flex-col w-full mt-6', {})}>
        <p className="w-full font-bold flex gap-2 items-center">
          <ImageIcon />
          상품 대표 이미지 선택 0/0
        </p>
        <p className="w-full text-[14px] text-[#8F929B]">
          사용하고 싶은 이미지를 선택해주세요. (영상 길이에 따라 선택한 이미지가
          모두 사용되지 않을 수 있습니다)
        </p>
      </div>
      <div className={classNames('flex flex-col w-full mt-6 relative', {})}>
        <p className="w-full font-bold flex gap-2 items-center">
          <ImageIcon />
          상품 상세페이지 이미지 선택 0 /0
        </p>
        <p className="w-full text-[14px] text-[#8F929B]">
          사용하고 싶은 이미지를 선택해주세요. (영상 길이에 따라 선택한 이미지가
          모두 사용되지 않을 수 있습니다)
        </p>
      </div>

      {/* 상품 이미지 선택 */}
      {/* <ProductImageSelector
        selectedLink={selectedLink}
        index={index}
        setValue={setValue}
        watch={watch}
      /> */}

      {/* 상품 상세 이미지 */}
      {/* <ProductDetailImageSelector
        selectedLink={selectedLink}
        index={index}
        setValue={setValue}
        watch={watch}
      /> */}

      {/* 이미지 업로드 */}
      {/* <FileUploader watch={watch} i={index} setValue={setValue} /> */}
    </div>
  )
}

export default ProductItemEmpty
