import classNames from 'classnames'
import React, { Dispatch, PropsWithChildren, SetStateAction } from 'react'
import { isMobile } from 'react-device-detect'
import { TransitionFactor } from '../../../service/list'
import { Control, Controller } from 'react-hook-form'
import { FormValues } from '../../../utils/type'
import { PlanTag } from '../../planTag'
import { Me } from '../../../service/user'
import { VideoComponent } from '../VideoComponent'

interface TransitionProps {
  watch: (name: 'transitionId', defaultValue?: number | undefined) => number
  setValue: (
    name: 'transitionId',
    value: number,
    options?:
      | Partial<{
          shouldValidate: boolean
          shouldDirty: boolean
          shouldTouch: boolean
        }>
      | undefined
  ) => void
  control: Control<FormValues, any>
  copyTransitionList: TransitionFactor[] | []
  setSelectedTransitionIndex: Dispatch<SetStateAction<number>>
  selectedTransitionIndex: number
  setTransitionModal: Dispatch<SetStateAction<boolean>>
  translatePlanName: (planName: string) => string
  userInfo: Me
  handlePlayButtonClick: (index: number) => void
  handleVideoEnded: (index: number) => void
  handleMouseLeave: (index: number) => void
  handleSelectedTransition: (behavior: boolean) => void
  videoRefs: React.MutableRefObject<(HTMLVideoElement | null)[]>
  videoRef: React.MutableRefObject<HTMLVideoElement | null>
  transitionDivRef: React.MutableRefObject<HTMLDivElement | null>
  videoPlaying: boolean[]
}

export const Transition = ({
  copyTransitionList,
  watch,
  setValue,
  control,
  setSelectedTransitionIndex,
  selectedTransitionIndex,
  translatePlanName,
  userInfo,
  handlePlayButtonClick,
  handleVideoEnded,
  handleMouseLeave,
  handleSelectedTransition,
  setTransitionModal,
  videoRefs,
  videoRef,
  videoPlaying,
  transitionDivRef,
}: PropsWithChildren<TransitionProps>) => {
  return (
    <div className="transition  my-[40px]" ref={transitionDivRef}>
      <div className="flex items-center md:mb-[16px] gap-4 ">
        <p className="font-bold text-[18px]">화면 전환 효과</p>
        <p className="flex  items-center gap-1">
          {/* <span
            className="font-bold text-white text-[11px] px-1 py-[2px] rounded-[4px]"
            style={{
              background: `linear-gradient(to right,#6095FF,#00CBB3)`,
            }}
          >
            UPDATE
          </span> */}
          {!isMobile && (
            <span className="text-[14px] text-[#5E6068]">
              100여종의 화면 전환 효과 중 나에게 맞는 것을 선택해보세요!{' '}
            </span>
          )}
        </p>
      </div>{' '}
      {isMobile && (
        <div className="mb-[16px] md:mb-0">
          <p className="text-[14px] text-[#5E6068]">
            100여종의 화면 전환 효과 중 나에게 맞는 것을 선택해보세요!{' '}
          </p>
        </div>
      )}
      <div
        className={classNames('flex gap-4', {
          'flex-col': isMobile,
          'flex-row items-center ': !isMobile,
        })}
      >
        <div className="selected_video ">
          {copyTransitionList.map((el) => {
            if (el.name === '없음' && el.id === watch('transitionId')) {
              return (
                <>
                  {' '}
                  <div
                    className="flex  md:flex-col  relative w-[138px] h-[78px] justify-center outline outline-[4px] outline-main"
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    }}
                  >
                    <button
                      type="button"
                      className=" text-white font-bold z-40"
                    >
                      효과 없음
                    </button>
                    <div
                      className={classNames(
                        'absolute w-[138px] h-[78px] flex  justify-center'
                      )}
                    ></div>
                  </div>
                </>
              )
            } else if (el.id === watch('transitionId')) {
              return (
                <>
                  <div
                    className={`${selectedTransitionIndex} flex w-[138px] relative outline outline-4 outline-main`}
                    key={`selected_video_${selectedTransitionIndex}`}
                    onMouseEnter={
                      !isMobile
                        ? () => handleSelectedTransition(true)
                        : undefined
                    }
                    onMouseLeave={
                      !isMobile
                        ? () => handleSelectedTransition(false)
                        : undefined
                    }
                    onClick={
                      isMobile
                        ? () => handleSelectedTransition(true)
                        : undefined
                    }
                  >
                    {videoRef ? (
                      <>
                        {' '}
                        <video
                          ref={videoRef}
                          src={el.path}
                          playsInline
                          width={138}
                          loop
                          autoPlay
                          muted
                          height={78}
                          preload="auto"
                        />
                      </>
                    ) : (
                      <>
                        {' '}
                        <img
                          src={
                            copyTransitionList?.filter(
                              (el) => el.id === watch('transitionId')
                            )[0].imagePath
                          }
                          className="w-[138px] h-[78px]" // 이미지는 렌더링되지만 보이지 않음
                          loading="lazy"
                          alt=""
                        />
                      </>
                    )}

                    <div className={`absolute w-[138px] h-[78px] flex `}>
                      <span className="font-bold text-white text-[14px] ml-1 mt-1">
                        선택됨
                      </span>
                    </div>
                  </div>
                </>
              )
            }
          })}
        </div>
        <div
          className={classNames('', {
            'flex md:flex-row flex-col gap-[24px] md:gap-1 justify-between bg-[#EEF1F7] md:h-[118px]  px-[10px] py-5 rounded-[10px]':
              !isMobile,
            'grid grid-cols-2 gap-x-[27px] gap-y-5 bg-[#EEF1F7]  px-[10px]  py-5 rounded-[10px] ':
              isMobile,
          })}
        >
          {copyTransitionList?.map((transition, index) => {
            if (index > (isMobile ? 3 : 3)) return <></>
            if (transition.name === '없음') {
              return (
                <>
                  {' '}
                  <div
                    className={classNames(
                      'flex  md:flex-col  relative w-[138px] h-[78px] justify-center',
                      {
                        ' outline outline-[4px] outline-main':
                          transition.name === '없음' &&
                          transition.id === watch('transitionId'),
                      }
                    )}
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    }}
                    onClick={() => {
                      if (transition?.usable) {
                        setSelectedTransitionIndex(index)
                        setValue('transitionId', transition.id)
                      }
                    }}
                  >
                    <button
                      type="button"
                      className=" text-white font-bold z-40"
                    >
                      효과 없음
                    </button>
                    <div
                      className={classNames(
                        'absolute w-[138px] h-[78px] flex  justify-center'
                      )}
                    >
                      <div className="flex items-start h-full  pt-1   mt-[6px] ml-[6px] ">
                        <PlanTag
                          planName={translatePlanName(transition?.planName)}
                          className=""
                        />
                      </div>
                      <Controller
                        render={({ field }: any) => (
                          <label className="relative flex w-full  cursor-pointer  items-end  justify-end mr-1 mb-1 ">
                            <input
                              type="checkbox"
                              className="sr-only"
                              checked={watch('transitionId') === transition.id}
                              disabled={!transition.usable}
                              onChange={() => {
                                if (transition?.usable) {
                                  setSelectedTransitionIndex(index)
                                  setValue('transitionId', transition.id)
                                }
                              }}
                            />
                            <div
                              className={`w-[20px] h-[20px] rounded-full border-2 bg-main flex items-center justify-center ${
                                watch('transitionId') === transition.id
                                  ? 'bg-white border-main'
                                  : 'bg-white'
                              }`}
                            >
                              {watch('transitionId') === transition.id && (
                                <div className="w-3 h-3 bg-main rounded-full"></div>
                              )}
                            </div>
                          </label>
                        )}
                        name="transitionId"
                        control={control}
                      />
                    </div>
                  </div>
                </>
              )
            }
            return (
              <>
                <div
                  className="flex md:flex-col  relative "
                  key={index}
                  onMouseEnter={
                    !isMobile ? () => handlePlayButtonClick(index) : undefined
                  }
                  onMouseLeave={
                    !isMobile ? () => handleVideoEnded(index) : undefined
                  }
                  onClick={
                    isMobile ? () => handlePlayButtonClick(index) : undefined
                  }
                >
                  <VideoComponent
                    videoRefs={videoRefs}
                    src={transition.path}
                    className={classNames({
                      ' outline outline-[4px] outline-main':
                        transition.id === watch('transitionId'),
                    })}
                    imagePath={transition.imagePath}
                    index={index}
                    selected={true}
                    videoPlaying={videoPlaying}
                  />

                  {index === (isMobile ? 3 : 3) ? (
                    <div
                      className={`absolute w-[138px] h-[78px] flex items-center justify-center $ `}
                      style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      <button
                        type="button"
                        onClick={() => {
                          setTransitionModal((prev) => !prev)

                          handleMouseLeave(index)
                        }}
                        className=" text-white font-bold z-40"
                      >
                        {copyTransitionList?.length - (isMobile ? 4 : 5)}개
                        더보기
                      </button>
                    </div>
                  ) : (
                    <>
                      <div
                        style={{
                          backgroundColor:
                            watch('transitionId') === transition.id
                              ? 'rgba(0, 0, 0, 0.5)'
                              : '',
                        }}
                        className={classNames(
                          'absolute w-[138px] h-[78px] flex'
                        )}
                        onClick={() => {
                          if (transition?.usable) {
                            setSelectedTransitionIndex(index)
                            setValue('transitionId', transition.id)
                          }
                        }}
                        // style={{
                        //   backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        // }}
                      >
                        <div className="flex items-start h-full  pt-1  mt-[6px] ml-[6px] ">
                          <PlanTag
                            planName={translatePlanName(transition?.planName)}
                            className=""
                          />
                        </div>
                        <Controller
                          render={({ field }: any) => (
                            <label className="relative flex w-full  cursor-pointer  items-end  justify-end mr-1 mb-1 ">
                              <input
                                type="checkbox"
                                className="sr-only"
                                checked={
                                  watch('transitionId') === transition.id
                                }
                                disabled={!transition.usable}
                                onChange={() => {
                                  if (transition?.usable) {
                                    setSelectedTransitionIndex(index)
                                    setValue('transitionId', transition.id)
                                  }
                                }}
                              />
                              <div
                                className={`w-[20px] h-[20px] rounded-full border-2 bg-main flex items-center justify-center ${
                                  watch('transitionId') === transition.id
                                    ? 'bg-white border-main'
                                    : 'bg-white'
                                }`}
                              >
                                {watch('transitionId') === transition.id && (
                                  <div className="w-3 h-3 bg-main rounded-full"></div>
                                )}
                              </div>
                            </label>
                          )}
                          name="transitionId"
                          control={control}
                        />
                      </div>
                    </>
                  )}
                </div>
              </>
            )
          })}
        </div>
      </div>
    </div>
  )
}
