import { useQuery } from '@tanstack/react-query'

import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useDeferredValue,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  loadPaymentWidget,
  PaymentWidgetInstance,
  ANONYMOUS,
} from '@tosspayments/payment-widget-sdk'

import { Controller, useForm } from 'react-hook-form'

import { useLocation } from 'react-router-dom'

import classNames from 'classnames'
import { UserInfoType } from '../../service/user'
import { AffiliatePoint } from '../../service/payment'
import { RequestPurchasePoint } from '../../service/point'
import useGetAutoChargeList from '../../hooks/query/point/useGetAutoChargeList'
import { useUpdateRequestPurchasePoint } from '../../hooks/query/point/useUpdateRequestPurchasePoint'
import { useSaveDiscount } from '../../hooks/query/useSaveDiscount'
import { CircularCheckbox } from '../CircularCheckbox'
import {
  CASH_INFO_FIRST,
  CASH_INFO_SECOND,
  CASH_REFUND_INFO,
} from '../../utils/common'
import { Modal } from '../layout/Modal'
import useGetPromotion from '../../hooks/query/point/useGetPromotion'

interface ModalProps {
  setCashPaymentModal: Dispatch<SetStateAction<boolean>>
  userInfo: UserInfoType
  affiliatePoint?: AffiliatePoint
  userCurrentPoint: number
  requestPurchasePoint: RequestPurchasePoint
}

export const CashPaymentModal = ({
  children,
  setCashPaymentModal,
  affiliatePoint,
  userInfo,
  userCurrentPoint,
  requestPurchasePoint,
}: PropsWithChildren<ModalProps>) => {
  const { pathname } = useLocation()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const close = params.get('close')

  const {
    register,
    control,
    handleSubmit,
    reset,
    trigger,
    setError,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      pointAmount: 0,
      pointId: 0,
      pointGroup: 'popular',
      bonus: 0,
    },
  })

  const { data: autoChargeList } = useGetAutoChargeList()
  //const { data: pointListData, refetch: pointListRefetch } = useGetPointList()
  const { mutate: updateMutate } = useUpdateRequestPurchasePoint()

  const { mutate: updateDiscountMutate } = useSaveDiscount()

  const [currentPoint, setCurrentPoint] = useState(userCurrentPoint)

  const [promotionInfo, setPromotionInfo] = useState({
    point: 0,
    pointRate: 0,
    expired: '',
    expiredDay: 0,
  })
  const [totalPrice, setTotalPrice] = useState(33000)
  const [selectPoint, setSelectPoint] = useState(30000)
  const [bonusValue, setBonusValue] = useState(0)
  const [selectPointVat, setSelectPointVat] = useState(0)
  const [isSpendingPoint, setSependingPoint] = useState<boolean>(false)
  const [isOpen, setOpen] = useState<boolean>(false)
  const [promotionCode, setPromotionCode] = useState<string>('')
  const [isValidCode, setValidCode] = useState<boolean>(false)
  const [isAffiliateBenefit, setAffiliateBenefit] = useState<boolean>(false)
  const [isUseInviteCode, setUseInviteCode] = useState<boolean>(false)
  const clientKey = process.env.REACT_APP_KEY ?? ''
  const [isModal, setModal] = useState<boolean>(false)
  const [errorText, setErrorText] = useState({
    title: '',
    subtitle: <></>,
  })
  const [isChecked, setIsChecked] = useState([false, false])

  const handleChange = (event: any, index: number) => {
    const updateChecked = isChecked.map((el, i) => (i === index ? !el : el))
    setIsChecked(updateChecked)
  }
  const handlePoint = (e: any) => {
    setSependingPoint(true)
    const value = e.target.value.replace(/[^0-9]/g, '')
    setCurrentPoint(value)
  }

  function handlePathName(pathname: string) {
    let newPath = pathname
    if (pathname === '/shortform-price-guide') {
      newPath = '/my-cash-dashboard'
    }
    return newPath
  }
  function usePaymentWidget(clientKey: string, customerKey: string) {
    return useQuery({
      queryKey: ['payment-widget', clientKey, customerKey],
      queryFn: () => {
        return loadPaymentWidget(clientKey, customerKey)
      },
    })
  }
  const { mutate, isPending } = useGetPromotion()
  const { data: paymentWidget } = usePaymentWidget(
    clientKey,
    requestPurchasePoint?.orderId
  )
  const paymentWidgetRef = useRef<PaymentWidgetInstance | null>(null)
  const paymentMethodsWidgetRef = useRef<ReturnType<
    PaymentWidgetInstance['renderPaymentMethods']
  > | null>(null)

  const handelPayment = () => {
    if (isValidCode || isAffiliateBenefit) {
      requestPurchasePoint.promotionCode = promotionCode
    }
    const newPath = handlePathName(pathname)
    updateMutate(requestPurchasePoint, {
      onSuccess: () => {
        const paymentWidget = paymentWidgetRef.current
        console.log(
          `${window.location.origin}${newPath}?promotionCode=${promotionInfo.pointRate}&valid=${isValidCode}&bonus=${bonusValue}`
        )
        try {
          if (requestPurchasePoint?.orderId) {
            paymentWidget?.requestPayment({
              orderId: requestPurchasePoint?.orderId,
              orderName: `패스트컷 포인트 (${selectPoint.toLocaleString()}원)`,
              customerName: userInfo.name,
              customerEmail: userInfo.email,
              successUrl: `${window.location.origin}/payment-success?promotionCode=${promotionInfo.pointRate}&valid=${isValidCode}&bonus=${bonusValue}&type=point&close=${close}`,
              failUrl: `${window.location.origin}/payment-fail?path=${newPath}&type=point`,
            })
          }
        } catch (err) {
          console.log(err)
        }
      },
      onError: () => {},
    })
  }

  const handleInvalidCode = () => {
    if (!isAffiliateBenefit) {
      setUseInviteCode(false)
      setPromotionCode('')
      setValidCode(false)
    }

    //가격도 원래 가격으로 다시 돌려야함
  }
  const handlePromotion = () => {
    const data = promotionCode
    mutate(data, {
      onSuccess: (data) => {
        setPromotionInfo(data)
        setUseInviteCode(true)
        setValidCode(true)
      },
      onError: (e) => {
        console.log(e)
        const error: any = e
        const errorCode = error?.response?.data?.message
        setValidCode(false)
        if (errorCode === 450) {
          setErrorText({
            title: '💁🏻 할인 코드 오류',
            subtitle: (
              <>
                입력하신 할인 코드는 존재하지 않는 코드입니다. <br />
                코드를 다시 잘 확인해서 입력해주세요!
              </>
            ),
          })
        } else if (errorCode === 460) {
          setErrorText({
            title: '🗓 할인 코드 기한 만료',
            subtitle: (
              <>
                입력하신 할인 코드는 사용 기한이 만료되었습니다.
                <br />
                다른 할인 코드를 입력해주세요!
              </>
            ),
          })
        }
        setModal((prev) => !prev)
        console.log(e.message)
      },
    })
  }

  const calculatePromotion = (amount: number) => {
    const { point, pointRate, expired, expiredDay } = promotionInfo
    //선택된 amount에 pointRate만큼 주기

    let calculateCash = amount * (pointRate / 100)
    //return `+${calculateCash.toLocaleString()} 캐시 (할인코드 ${pointRate}%)`
    return `할인 코드 ${pointRate}%`
  }

  const calculateFinalPoint = (amount: number) => {
    const { point, pointRate, expired, expiredDay } = promotionInfo
    //선택된 amount에 pointRate만큼 주기

    let calculateCash = amount * (pointRate / 100)
    return ` + ${pointRate}%(${calculateCash.toLocaleString()} 캐시)`
  }

  useEffect(() => {
    ;(async () => {
      if (requestPurchasePoint?.orderId) {
        const paymentWidget = await loadPaymentWidget(
          clientKey,
          requestPurchasePoint?.orderId
        )
        const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
          '#payment-widget',
          totalPrice,
          { variantKey: 'cashOnly' }
        )

        paymentWidgetRef.current = paymentWidget
        paymentMethodsWidgetRef.current = paymentMethodsWidget
      }
    })()
  }, [])

  useEffect(() => {
    const pointAmount = watch('pointAmount')
    const bonus = watch('bonus')
    setBonusValue(bonus)
    setSelectPoint(pointAmount)
    setSelectPointVat(pointAmount * 0.1)
    setTotalPrice(pointAmount + pointAmount * 0.1)
    requestPurchasePoint.amount = pointAmount
    requestPurchasePoint.price = pointAmount
    requestPurchasePoint.vat = pointAmount * 0.1
    requestPurchasePoint.productName = `패스트컷 포인트 (${pointAmount.toLocaleString()}원)`
    requestPurchasePoint.chargeOptionId = watch('pointId')
  }, [watch('pointAmount')])

  useEffect(() => {
    const paymentMethodsWidget = paymentMethodsWidgetRef.current

    if (paymentMethodsWidget == null) {
      return
    }

    paymentMethodsWidget.updateAmount(
      totalPrice,
      paymentMethodsWidget.UPDATE_REASON.POINT
    )
  }, [totalPrice])

  useEffect(() => {
    if (autoChargeList?.benefitInfo?.affiliateDescription) {
      setAffiliateBenefit(true)
    }
    if (autoChargeList?.chargeMap) {
      setValue('bonus', autoChargeList.chargeMap.popular[0].bonus)
      setValue('pointGroup', 'popular')
      setValue('pointId', autoChargeList.chargeMap.popular[0].id)
      setValue(
        'pointAmount',
        Number(autoChargeList.chargeMap.popular[0].chargeAmount)
      )
      if (affiliatePoint?.affiliateCode)
        setPromotionCode(affiliatePoint?.affiliateCode)
    }
  }, [autoChargeList])

  return (
    <div className="h-screen w-full fixed left-0 top-0 flex flex-col justify-center items-center bg-black bg-opacity-50 text-center md:p-[40px] z-50">
      <div className="bg-white w-full md:w-[600px] px-[20px] md:px-[40px] border md:rounded-[20px] overflow-y-auto pb-[100px]">
        <div className="sticky top-0 bg-white z-10  pb-5 pt-10  ">
          <p className="flex justify-center items-center relative ">
            <p className="md:text-[48px] text-[32px] font-bold   ">
              캐시 충전!
            </p>
            <button
              onClick={() => {
                if (close) {
                  window.close()
                }
                setCashPaymentModal(false)
              }}
              className=" absolute right-0"
            >
              <img
                src="assets/img/Icon_x.png"
                alt="icon_x"
                height={40}
                width={40}
              />
            </button>
          </p>
        </div>
        <div className="md:mt-[32px] md:px-[32px] md:py-[24px] p-[20px] bg-[#F6F8FB] rounded-[20px] h-auto mb-[8px]">
          <div className="flex justify-between font-bold text-[20px]">
            <p>보유 캐시</p>
            <p>{currentPoint.toLocaleString()}캐시</p>
          </div>
        </div>
        <div className=" md:px-[32px] md:py-[24px] p-[20px] bg-[#F6F8FB] rounded-[20px] h-auto mb-[8px]">
          <div className="flex flex-col">
            <div className="flex justify-between font-bold text-[20px]">
              <p>할인 코드</p>
            </div>
            <div className="relative mt-[16px]">
              <input
                className=" w-full  h-[75px] md:h-[83px] border-[1px] md:p-[16px] p-5  rounded-[20px] md:px-[32px] px-5"
                placeholder="할인 코드를 입력"
                onChange={(e) => {
                  setPromotionCode(e.target.value)
                }}
                value={isAffiliateBenefit ? '' : promotionCode}
                disabled={isValidCode || isAffiliateBenefit}
              />
              <div className="flex gap-[16px] absolute right-3 bottom-4 md:top-3 items-center ">
                <button onClick={handleInvalidCode}>
                  <img
                    className="w-[24px] h-[24px]  md:w-[32px] md:h-[32px]"
                    src="assets/img/icon_circle_x.png"
                    alt="x"
                  />
                </button>
                <button
                  className="bg-[#6B8ACB] hover:bg-[#8FABE5] active:scale-95 text-white px-[20px] py-[10px]  md:px-[24px] md:py-[16px] md:w-[76px] md:h-[51px]   flex items-center justify-center rounded-[10px] font-bold disabled:opacity-[0.3]"
                  onClick={handlePromotion}
                  disabled={!promotionCode.length || isValidCode}
                >
                  적용
                </button>
              </div>
            </div>
          </div>
        </div>
        <form>
          <div className=" md:p-[32px] p-[20px] bg-[#F6F8FB] rounded-[20px] h-auto flex flex-col gap-[16px] ">
            <p className="font-bold text-[20px] text-left">충전 단위 선택</p>
            <div className="popular-list bg-white  flex flex-col md:w-[456px] p-[20px] md:text-[18px] text-[14px] rounded-[10px]">
              <div className="flex mb-5">
                <span className="bg-main text-white rounded-[4px] px-[12px] py-[4px] md:text-[18px] text-[14px] font-bold">
                  인기
                </span>
              </div>
              {autoChargeList?.chargeMap?.popular?.map((el, index) => (
                <>
                  <div className="flex gap-[8px] items-center justify-between">
                    <Controller
                      render={({ field }) => (
                        <CircularCheckbox
                          {...field}
                          id={index}
                          checked={
                            watch('pointAmount') === Number(el.chargeAmount) &&
                            watch('pointId') === el.id &&
                            watch('pointGroup') === 'popular'
                          }
                          onChange={() => {
                            setValue('pointAmount', Number(el.chargeAmount))
                            setValue('pointId', el.id)
                            setValue('pointGroup', 'popular')
                            setValue('bonus', el.bonus)
                          }}
                          className="font-bold"
                          //disabled={!userInfo || !thumbnail.usable}

                          label={`${Number(
                            el.chargeAmount
                          ).toLocaleString()}캐시`}
                        />
                      )}
                      name="pointAmount"
                      control={control}
                    />

                    <div className="flex flex-col items-end md:text-[18px] text-[12px]">
                      {Number(el.bonusRate) > 0 && (
                        <>
                          <p className="font-bold">
                            {' '}
                            + {`${el.bonus.toLocaleString()}`} 캐시 보너스
                          </p>
                          <p
                            className={classNames(
                              'md:text-[14px] text-[12px]',
                              {
                                'mb-4': isValidCode,
                              }
                            )}
                          >
                            {autoChargeList.benefitInfo?.affiliateDescription &&
                              '제휴 혜택'}
                            {autoChargeList.benefitInfo?.inviteCode &&
                              '초대 코드'}
                            {autoChargeList.benefitType === 'purchase' &&
                              '충전 혜택'}
                            {` `}
                            <span
                              className={classNames(
                                'font-bold px-1 rounded-[4px] ',
                                {
                                  'text-error bg-[#EEF1F7]':
                                    Number(el.bonusRate) <= 9,
                                  'bg-error text-white':
                                    Number(el.bonusRate) > 9,
                                }
                              )}
                            >
                              {`${Number(el.bonusRate).toFixed(0)}%`}
                            </span>
                          </p>
                        </>
                      )}
                      {isValidCode && (
                        <div className="bg-[#FFF4F4] text-error flex justify-end md:text-[14px] text-[12px] p-1 rounded-[2px]">
                          {calculatePromotion(Number(el.chargeAmount))}
                        </div>
                      )}
                    </div>
                  </div>
                  {index !== autoChargeList.chargeMap?.popular.length - 1 && (
                    <hr className="h-[1px] bg-[#EEF1F7] my-[20px]" />
                  )}
                </>
              ))}
            </div>
            <div className="list bg-white flex flex-col md:w-[456px]  p-[20px]  md:text-[18px] text-[14px] rounded-[10px]">
              <div className="flex mb-5">
                <span className="bg-[#8F929B] text-white rounded-[4px] px-[12px] py-[4px] md:text-[18px] text-[14px] font-bold">
                  전체
                </span>
              </div>
              {autoChargeList?.chargeMap?.total?.map((el, index) => (
                <>
                  <div className="flex gap-[8px] items-center justify-between">
                    <Controller
                      render={({ field }) => (
                        <>
                          {' '}
                          <CircularCheckbox
                            {...field}
                            id={index}
                            checked={
                              watch('pointAmount') ===
                                Number(el.chargeAmount) &&
                              watch('pointId') === el.id &&
                              watch('pointGroup') === 'total'
                            }
                            onChange={() => {
                              setValue('pointAmount', Number(el.chargeAmount))
                              setValue('pointId', el.id)
                              setValue('pointGroup', 'total')
                              setValue('bonus', el.bonus)
                            }}
                            label={`${Number(
                              el.chargeAmount
                            ).toLocaleString()}캐시`}
                            className="font-bold"
                          />
                        </>
                      )}
                      name="pointAmount"
                      control={control}
                    />
                    <div className="flex flex-col items-end md:text-[18px] text-[12px]  ">
                      {Number(el.bonusRate) > 0 && (
                        <>
                          <p className="font-bold">
                            {' '}
                            + {`${el.bonus.toLocaleString()}`} 캐시 보너스
                          </p>
                          <p
                            className={classNames(
                              'md:text-[14px] text-[12px]',
                              {
                                'mb-4': isValidCode,
                              }
                            )}
                          >
                            {autoChargeList.benefitInfo?.affiliateDescription
                              ? '제휴 혜택'
                              : '충전 혜택'}{' '}
                            <span
                              className={classNames(
                                'font-bold px-1 rounded-[4px]',
                                {
                                  'text-error bg-[#EEF1F7]':
                                    Number(el.bonusRate) <= 9,
                                  'bg-error text-white':
                                    Number(el.bonusRate) > 9,
                                }
                              )}
                            >
                              {`${Number(el.bonusRate).toFixed(0)}%`}
                            </span>
                          </p>
                        </>
                      )}
                      {isValidCode && (
                        <div className="bg-[#FFF4F4] text-error flex justify-end text-[14px] p-1">
                          {calculatePromotion(Number(el.chargeAmount))}
                        </div>
                      )}
                    </div>
                  </div>
                  {index !== autoChargeList.chargeMap?.total.length - 1 && (
                    <hr className="h-[1px] bg-[#EEF1F7] my-[20px]" />
                  )}
                </>
              ))}
            </div>
            <div className="after-point bg-white  flex  md:w-[456px] p-[20px] font-bold md:text-[24px] text-base rounded-[10px] justify-between">
              <p>충전 예정 캐시</p>
              <div className="flex flex-col items-end">
                <p className="md:text-[24px] text-[20px]">
                  {(selectPoint + bonusValue).toLocaleString()}캐시
                </p>
                {isValidCode && (
                  <p className="text-error md:text-[18px] text-base mt-2">
                    {calculateFinalPoint(selectPoint)}
                  </p>
                )}
              </div>
            </div>
          </div>
        </form>

        <div className="flex flex-col mt-[24px]">
          <p className="w-full text-left text-[24px] font-bold">금액 안내</p>
          <div className="point bg-[#F6F8FB] rounded-[20px] mt-[16px]  flex flex-col p-[20px]  md:px-[32px] md:py-[24px]  ">
            <div className="flex items-center justify-between">
              <p className="text-[18px] md:text-[20px] font-bold">캐시</p>
              <p className="text-[18px] md:text-[20px] font-bold">
                {' '}
                {selectPoint.toLocaleString()}원
              </p>
            </div>
            <div className="flex items-center justify-between">
              <p className="text-[18px] md:text-[20px] font-bold">
                VAT(부가세10%)
              </p>
              <p className="text-[18px] md:text-[20px] font-bold">
                {' '}
                {(selectPoint * 0.1).toLocaleString()}원
              </p>
            </div>
          </div>
        </div>

        <div className="bg-[#AFECEC] rounded-[20px] mt-[8px] flex p-[20px] md:flex-row justify-between items-center md:px-[32px] md:py-[24px] h-[78px] ">
          <p className="text-[18px] md:text-[24px] font-bold">총 결제금액</p>
          <p className="text-[24px] font-bold">
            {' '}
            {(selectPoint + selectPoint * 0.1).toLocaleString()}원
          </p>
        </div>

        <div className="box_section w-full">
          <div id="payment-widget" />
        </div>

        <div className="w-full bg-[#F6F8FB]  p-[20px] rounded-[20px] flex justify-start flex-col items-start border-[2px] border-[#D9DEE8] gap-[12px]">
          <div className="flex flex-col justify-start items-start">
            <p className="font-bold text-[18px] p-[10px]">패스트컷 캐시 안내</p>
            <ul className="list-disc pl-8 text-left">
              {CASH_INFO_FIRST.map((el) => {
                return (
                  <>
                    <li>{el}</li>
                  </>
                )
              })}
            </ul>
          </div>
          <div className="flex flex-col justify-start items-start">
            <p className="font-bold text-[18px] p-[10px]">
              패스트컷 캐시 환불규정
            </p>
            <ul className="list-disc pl-8 text-left">
              {Object.entries(CASH_REFUND_INFO).map((el) => {
                const [number, info] = el
                return (
                  <>
                    <li>{info.text}</li>
                    {info?.firstSubText && (
                      <ul className=" list-circle  pl-4 text-left">
                        <li>{info.firstSubText}</li>
                      </ul>
                    )}
                  </>
                )
              })}

              <li>
                그 밖의 상세 규정은{' '}
                <span>
                  <a
                    className="text-href underline"
                    href="https://angelleague.notion.site/f073ce324b194809b89b07fb4baf4811"
                    target="_blank"
                    rel="noreferrer"
                  >
                    ‘패스트컷 캐시정책’
                  </a>
                </span>
                을 따릅니다.
              </li>
            </ul>
          </div>
        </div>
        <div className=" flex flex-col items-center justify-center">
          <div className="flex items-center gap-3  mt-[24px] w-full md:w-[430px]">
            <input
              type="checkbox"
              checked={isChecked[0]}
              onChange={(e) => {
                handleChange(e, 0)
              }}
              className="w-[20px] h-[20px] shrink-0"
            />
            <p className="text-[#8F929B] font-[500] text-[18px] text-left">
              [필수]{' '}
              <span>
                <a
                  className="text-href underline"
                  href="https://angelleague.notion.site/f073ce324b194809b89b07fb4baf4811"
                  target="_blank"
                  rel="noreferrer"
                >
                  패스트컷 캐시정책
                </a>
              </span>{' '}
              및 위 환불규정에 따릅니다
            </p>
          </div>
          <div className="flex items-center gap-3 mt-[12px] w-full md:w-[430px] ">
            <input
              type="checkbox"
              checked={isChecked[1]}
              onChange={(e) => {
                handleChange(e, 1)
              }}
              className="w-[20px] h-[20px]"
            />
            <p className="text-[#8F929B]   font-[500] text-[18px]  text-left">
              [필수] 만 14세 이상입니다
            </p>
          </div>
        </div>
        <div className="w-full bg-main my-[24px] h-[60px] flex items-center justify-center rounded-[40px] text-[white] font-bold text-[18px]">
          <button
            className="w-full h-full"
            disabled={!isChecked.every((element) => element)}
            onClick={() => {
              handelPayment()
            }}
          >
            {totalPrice.toLocaleString()}원 결제하기
          </button>
        </div>
      </div>
      {isModal && (
        <Modal isModal={isModal}>
          <div className="bg-white w-[400px] h-[184px] p-[20px] border-t rounded-t-[20px]">
            <div className="flex flex-col items-center justify-center h-full gap-[24px]">
              <p className="font-bold text-[18px]">{errorText.title}</p>
              <div className="flex flex-col leading-[22px]">
                <p className=" text-[14px] ">{errorText.subtitle}</p>
              </div>
            </div>
          </div>
          <div className="w-[400px] text-[14px]  h-[60px]  flex justify-center items-center">
            <button
              className="text-white w-[100%] h-full bg-main rounded-b-[20px] border-b border-main font-bold"
              onClick={() => {
                setModal((prev) => !prev)
              }}
            >
              확인
            </button>
          </div>
        </Modal>
      )}
    </div>
  )
}
