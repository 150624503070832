import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import { getLocalStorage } from 'utils/common'

export const InstallExtensionFloating = (props: any) => {
  const { userInfo } = props
  const isExtensionInstalled = getLocalStorage('fs_extension')
  const [isExtension, setExtension] = useState(false)

  const navigate = useNavigate()
  useEffect(() => {
    setExtension(isExtensionInstalled)
  }, [isExtensionInstalled])

  return (
    <>
      {' '}
      {!isMobile && isExtensionInstalled ? (
        <>
          <CoupangFloating userInfo={userInfo} navigate={navigate} />
          <AliFloating userInfo={userInfo} navigate={navigate} />
        </>
      ) : (
        !isMobile &&
        !isExtensionInstalled && (
          <>
            {' '}
            <EmptyFloating userInfo={userInfo} navigate={navigate} />
          </>
        )
      )}
    </>
  )
}

const CoupangFloating = (props: any) => {
  const { isExtensionInstalled, userInfo, navigate } = props
  return (
    <>
      {' '}
      <div
        className={classNames(
          'bg-white fixed right-5 p-4 top-32 rounded-[10px] cursor-pointer active:scale-95 border-[2px] border-main z-30'
        )}
      >
        {' '}
        <button
          onClick={() => {
            if (userInfo) {
              window.open('https://coupang.com', '_blank')
            } else {
              navigate('/sign-up')
            }
          }}
          type="button"
        >
          <img
            src="assets/img/coupang_icon.png"
            alt=""
            className="w-[26px] h-[26px]"
          />
          <p className="text-[14px] font-bold  mt-1 text-left">
            패스트컷 AI <br />
            확장 프로그램으로
            <br />
            <span className="text-[#C82009]">쿠팡</span> {`상품수집하기 >`}
          </p>{' '}
        </button>
      </div>
    </>
  )
}

const AliFloating = (props: any) => {
  const { isExtensionInstalled, userInfo, navigate } = props
  return (
    <>
      {' '}
      <div
        className={classNames(
          'bg-white fixed right-5 p-4 top-72 rounded-[10px] cursor-pointer active:scale-95 border-[2px] border-main z-30'
        )}
      >
        {' '}
        <button
          onClick={() => {
            if (userInfo) {
              window.open('https://aliexpress.com', '_blank')
            } else {
              navigate('/sign-up')
            }
          }}
          type="button"
        >
          <img
            src="assets/img/ali_icon.png"
            alt=""
            className="w-[26px] h-[26px]"
          />
          <p className="text-[14px] font-bold  mt-1 text-left">
            패스트컷 AI <br />
            확장 프로그램으로
            <br />
            <span className="text-[#FD8608]">알리</span> {`상품수집하기 >`}
          </p>{' '}
        </button>
      </div>
    </>
  )
}

const EmptyFloating = (props: any) => {
  const { isExtensionInstalled, userInfo, navigate } = props
  return (
    <>
      <div
        className={classNames(
          'bg-main fixed  right-5 top-32  p-4 rounded-[10px] w-[133px] h-[121px] cursor-pointer active:scale-95 z-30'
        )}
      >
        <button
          onClick={() => {
            if (userInfo) {
              window.open(
                'https://chromewebstore.google.com/detail/%ED%8C%A8%EC%8A%A4%ED%8A%B8%EC%BB%B7ai/meeeiblldcmbdhhaibnbpdiiknkoaakl?hl=ko',
                '_blank'
              )
            } else {
              navigate('/sign-up')
            }
          }}
          type="button"
        >
          <img src="assets/img/logo/fastcut-empty.png" alt="" />
          <p className="text-[14px] font-bold text-white mt-1 text-left">
            패스트컷 AI <br />
            확장 프로그램
            <br />
            {`설치하기 >`}
          </p>
        </button>
      </div>
    </>
  )
}
