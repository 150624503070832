import classNames from 'classnames'
import { CircularCheckbox } from 'components/CircularCheckbox'
import { CircularCheckboxWithPlayBtn } from 'components/CircularCheckboxWithPlayBtn'
import { Modal } from 'components/layout/Modal'
import { PlanTag } from 'components/planTag'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { SubtitleColorType, SubtitleType } from 'service/list'
import { Me } from 'service/user'
import { contentVideoFormValues, FormValues } from 'utils/type'
interface SubtitleDisplayProps {
  watch: UseFormWatch<FormValues>
  setValue: UseFormSetValue<FormValues>
  control: Control<FormValues, any>
  translatePlanName?: (planName: string) => string
  userInfo: Me
  subTitleList: SubtitleType[] | []
  subtitleColorList: SubtitleColorType[] | []
  subtitleDivRef?: React.MutableRefObject<HTMLDivElement | null>
}

export const SubtitleDisplay = ({
  watch,
  setValue,
  control,
  translatePlanName,
  userInfo,
  subTitleList,
  subtitleColorList,
  subtitleDivRef,
}: PropsWithChildren<SubtitleDisplayProps>) => {
  const [isOpen, setModalOpen] = useState<boolean>(false)
  const findBgmNameById = () => {
    const [subtitle] = subTitleList.filter(
      (el) => el.id === watch('subtitleId')
    )
    const [subtitleColor] = subtitleColorList.filter(
      (el) => el?.id === watch('subtitleColorId')
    )

    return (
      <>
        <p className="flex items-center gap-1">
          <span className="bg-[#D9DEE8] px-1 py-[2px] text-[#8F929B] rounded-[4px] text-[14px] ">
            스타일
          </span>
          <span>{subtitle?.name}</span>
        </p>
        <p className="flex items-center gap-1">
          <span className="bg-[#D9DEE8] px-1 py-[2px] text-[#8F929B] rounded-[4px] text-[14px] ">
            스타일
          </span>
          <img src={subtitleColor?.imagePath} alt="" />
        </p>
      </>
    )
  }
  const handleModalOpen = () => {
    setModalOpen((prev) => !prev)
  }

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden') // Tailwind 유틸리티 클래스 사용
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [isOpen])

  return (
    <>
      <div className="subTitle  mb-10" ref={subtitleDivRef}>
        <div className="flex md:flex-row flex-col md:items-center justify-between">
          <p className="font-bold text-[18px] mb-4 md:mb-0 ">나레이션 자막</p>
          <p className="md:w-[630px] w-full md:h-[61px] rounded-[10px] bg-[#F6F8FB] px-4 py-5 flex items-center justify-between font-bold">
            <div className="flex gap-4 md:flex-row flex-col">
              {findBgmNameById()}
            </div>
            <button
              className="px-2 py-[6px] bg-[#2E343D] text-[white] rounded-[4px] text-[14px]"
              onClick={() => {
                setModalOpen((prev) => !prev)
              }}
              type="button"
            >
              선택 {'>'}
            </button>
          </p>
        </div>
      </div>
      <Modal isModal={isOpen}>
        <div className="md:h-screen h-full w-full fixed left-0 top-0 flex flex-col justify-center items-center bg-black bg-opacity-50 text-center  z-50">
          <div
            className={classNames('', {
              'bg-white md:w-[816px] h-auto  shadow-main rounded-t-[20px] px-8 pt-8  flex flex-col   gap-8':
                !isMobile,
              'bg-white w-full h-full  shadow-main   flex flex-col   gap-8 pt-10 px-4  ':
                isMobile,
            })}
          >
            <div className="flex flex-col  gap-4  ">
              <p
                className={classNames('flex items-center', {
                  'justify-center': isMobile,
                  'justify-between': !isMobile,
                })}
              >
                <span className="font-bold text-[24px] text-left">
                  자막 선택
                </span>
              </p>
              <p className="md:text-left">
                내 영상 컨셉에 맞춰{isMobile && <br />} 어울리는 자막 스타일과
                색상을 선택해보세요!
              </p>
            </div>
            <div className="overflow-auto ">
              <div className="md:overflow-auto md:h-[500px]  md:pb-4">
                <div className="flex flex-col gap-4 mb-8">
                  <p className="font-bold text-[18px] text-left">스타일</p>
                  <div className="grid grid-cols-2  md:gap-x-[22px] gap-y-[16px] ">
                    {subTitleList?.map((subtitle, index) => {
                      return (
                        <>
                          <div className="" key={index}>
                            <div className="flex">
                              <Controller
                                render={({ field }) => (
                                  <CircularCheckbox
                                    {...field}
                                    id={index}
                                    checked={
                                      watch('subtitleId') === subtitle.id
                                    }
                                    disabled={!subtitle?.usable}
                                    onChange={() =>
                                      setValue('subtitleId', subtitle.id)
                                    }
                                    label={subtitle.name}
                                  />
                                )}
                                rules={{
                                  validate: (value) => {
                                    return Number(watch('subtitleId')) === 0
                                      ? 'Subtitle ID must not be 0'
                                      : true
                                  },
                                }}
                                name="subtitleId"
                                control={control}
                              />
                              {translatePlanName && (
                                <div className="flex items-start h-full  pt-1 ml-[2px] mr-[8px]">
                                  <PlanTag
                                    planName={translatePlanName(
                                      subtitle.planName
                                    )}
                                    className=""
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )
                    })}
                  </div>
                </div>
                <div className="flex flex-col gap-4">
                  <p className="font-bold text-[18px] text-left">색상</p>
                  <div className="grid grid-cols-2 md:grid-cols-4  gap-x-[22px] gap-y-[16px] ">
                    {subtitleColorList?.map((subtitleColor, index) => {
                      return (
                        <>
                          <div className="" key={index}>
                            <div className="flex">
                              <Controller
                                render={({ field }) => (
                                  <CircularCheckbox
                                    {...field}
                                    id={index}
                                    checked={
                                      watch('subtitleColorId') ===
                                      subtitleColor.id
                                    }
                                    // disabled={watch('subtitleId') === 1}
                                    onChange={() =>
                                      setValue(
                                        'subtitleColorId',
                                        subtitleColor.id
                                      )
                                    }
                                  />
                                )}
                                name="subtitleColorId"
                                control={control}
                              />
                              <img src={subtitleColor?.imagePath} alt="" />
                            </div>
                          </div>
                        </>
                      )
                    })}
                  </div>
                </div>
              </div>
              {isMobile && (
                <div className="pt-5 pb-20 w-[335px]">
                  <button
                    className="bg-main rounded-full w-full text-white font-bold h-[60px]"
                    onClick={handleModalOpen}
                    type="button"
                  >
                    저장
                  </button>
                </div>
              )}
            </div>
          </div>
          {!isMobile && (
            <div
              className="relative p-8 shadow-main w-full md:w-[816px] bg-white rounded-b-[20px] 
         before:content-[''] before:absolute before:top-[-10px] before:left-0 
         before:w-full before:h-[10px] before:bg-gradient-to-t before:from-black/10 before:to-transparent z-50"
            >
              <button
                className="bg-main rounded-full w-full text-white font-bold h-[60px]"
                onClick={handleModalOpen}
                type="button"
              >
                저장
              </button>
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}
