import React from 'react'

export const IncludeAddTooltip = (props: any) => {
  const { setTooltipStatus } = props

  return (
    <div
      className="flex flex-col p-[32px] gap-[10px] bg-white  w-[330px]  absolute border-[3px] border-main  rounded-[20px] top-8 md:left-44 z-50"
      onMouseLeave={() => {
        setTooltipStatus(false)
      }}
    >
      <p className=" leading-[19.2px] text-base   text-[10px]">
        <span className="font-bold text-[20px]">안내 이미지 예시</span>
      </p>
      <p>
        영상 우상단 구매링크 안내 이미지입니다. <br />
        (광고포함 문구는 유튜브 정책 필수)
      </p>
      <div className="flex flex-col gap-4">
        {' '}
        <img
          src="assets/img/includeAdd_add.png"
          alt=""
          className="w-[266px] h-[150px]"
        />
        <img
          src="assets/img/includeAdd_minus.png"
          alt=""
          className="w-[266px] h-[150px]"
        />
      </div>
    </div>
  )
}
