import { useQuery } from '@tanstack/react-query'
import { getBgm, getStickerLocation } from '../../service/list'

function useGetStickerLocation() {
  return useQuery({
    queryKey: ['get-sticker-location'],
    queryFn: () => getStickerLocation(),
    staleTime: 6 * 1000,
  })
}

export default useGetStickerLocation
