import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query'
import { Modal } from 'components/layout/Modal'
import { CashPaymentModal } from 'components/payment/CashPaymentModal'
import { useGetPointOrderId } from 'hooks/query/point/usetGetPoinOrderId'
import useCreateShortVideo from 'hooks/query/useCreateShortVideo'
import useCreateTextVideo from 'hooks/query/useCreateTextVideo'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { UseFormSetValue } from 'react-hook-form'
import { ShortVideoInfo, TextTypeVideoInfo } from 'service/list'
import { MyPointType, RequestPurchasePoint } from 'service/point'
import { Me } from 'service/user'
import useUserStore from 'store/useUserStore'
import {
  contentVideoFormValues,
  contentVideoTextTypeFormValues,
} from 'utils/type'

interface TextCashUseModalProps {
  isUseModal: boolean
  setUseModal: Dispatch<SetStateAction<boolean>>
  setSaveSuccessModal: Dispatch<SetStateAction<boolean>>
  currentPoint: number
  textVideoInfo: TextTypeVideoInfo
  textVideoPriceInfo: {
    sourceTypePrice?: number
    voicePrice: number
  }
  setValue: UseFormSetValue<contentVideoTextTypeFormValues>
  getMyPointRefetch: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<MyPointType, Error>>
}

const TextCashUseModal = ({
  isUseModal,
  setUseModal,
  currentPoint,
  textVideoInfo,
  textVideoPriceInfo,
  setSaveSuccessModal,
  setValue,
  getMyPointRefetch,
}: TextCashUseModalProps) => {
  const userInfo = useUserStore((state: any) => state.userInfo)
  const { isPending, mutate } = useCreateTextVideo()
  const [requestPurchasePoint, setRequestPurchasePoint] =
    useState<RequestPurchasePoint>({ orderId: '' })
  const { mutate: pointOrderIdMutate } = useGetPointOrderId()
  const [cashPaymentModal, setCashPaymentModal] = useState(false)
  const isValidPoint = () => {
    //현재 내 Point가 사용할 포인트보다 많은지 확인해야하는 함수

    const myCurrentPoint = currentPoint
    let totalPrice = textVideoPriceInfo.voicePrice
    if (textVideoPriceInfo.sourceTypePrice) {
      totalPrice += textVideoPriceInfo.sourceTypePrice
    }

    textVideoInfo.amount = totalPrice

    return myCurrentPoint >= totalPrice ? true : false
  }

  const handleCashPaymentModal = () => {
    pointOrderIdMutate(undefined, {
      onSuccess: (data) => {
        setRequestPurchasePoint(data)
        setCashPaymentModal(true)
      },
      onError: () => {},
    })
  }

  const [errorText, setErrorText] = useState({
    title: '',
    subtitle: <></>,
  })
  const [isModal, setModal] = useState<boolean>(false)
  const handleSubmit = () => {
    // Create a FormData object
    const formData = new FormData()

    // Append the non-file data
    Object.keys(textVideoInfo).forEach((key) => {
      if (key !== 'urls' && key !== 'files') {
        // @ts-ignore
        formData.append(key, textVideoInfo[key])
      }
    })

    const files = textVideoInfo.files
    if (files) {
      // Append the files
      files.forEach((file, index) => {
        formData.append(`files`, file)
      })
    }

    mutate(formData, {
      onSuccess: () => {
        setValue('idea', '')
        setValue('title', '')
        getMyPointRefetch()
        setUseModal(false)
        setSaveSuccessModal(true)
      },
      onError: (e) => {
        // @ts-ignore
        const serverErrorMsg = e.response?.data?.message || ''
        console.log(serverErrorMsg)
        setErrorText({
          title: '🚨 데이터 전송 실패',
          subtitle: (
            <>
              파일 이상이나 네트워크 장애 등의 문제로
              <br />
              데이터 전송에 실패했습니다.
              <br />
              다시 시도해주세요!
              <br />
              {serverErrorMsg ? serverErrorMsg : ''}
            </>
          ),
        })
        setModal((prev) => !prev)
      },
    })
  }
  useEffect(() => {}, [])
  return (
    <>
      <div className="h-screen w-full fixed left-0 top-0 flex flex-col justify-center items-center bg-black bg-opacity-50 text-center md:p-[40px] z-50">
        <div className="bg-white w-full md:h-auto h-full md:w-[600px] px-5 md:p-[40px] border  overflow-y-auto  pb-[300px] md:rounded-[20px]">
          <div className="sticky top-0 bg-white z-10   p-5  ">
            <p className="flex justify-center items-center relative ">
              {/* X 버튼 */}
              <button
                onClick={() => {
                  setUseModal(false)
                }}
                className="absolute right-0"
              >
                <img
                  src="assets/img/Icon_x.png"
                  alt="icon_x"
                  height={40}
                  width={40}
                />
              </button>

              {/* 중앙에 오는 텍스트 */}
              <p className="md:text-[32px] text-[24px] font-bold">캐시 사용</p>
            </p>
          </div>
          <div
            className="md:mt-[20px] md:px-[32px] md:py-[24px] p-[20px] bg-[#F6F8FB] rounded-[20px] h-auto mb-[8px]"
            style={{
              opacity: isPending ? 0.3 : 1,
              pointerEvents: isPending ? 'none' : 'auto',
            }}
          >
            <div className="flex justify-between font-bold text-[20px]">
              <p>보유 캐시</p>
              <p>{currentPoint.toLocaleString()}캐시</p>
            </div>
          </div>
          <div
            className="mt-[12px] bg-[#F6F8FB] h-auto rounded-[20px] px-[32px] py-[24px] flex flex-col justify-between gap-[17px]"
            style={{
              opacity: isPending ? 0.3 : 1,
              pointerEvents: isPending ? 'none' : 'auto',
            }}
          >
            <div className="flex font-bold text-[18px]">
              <p>사용내역</p>
            </div>

            <div className="flex gap-[10px]">
              <div className="w-[4px] h-auto bg-[#D4D9DF]"></div>
              <div className="flex flex-col w-full text-[#8F929B] font-bold gap-1">
                {' '}
                {textVideoPriceInfo.sourceTypePrice && (
                  <p className="w-full flex justify-between">
                    <span>영상 소스</span>
                    <span>
                      {' '}
                      {textVideoPriceInfo.sourceTypePrice.toLocaleString()}
                    </span>
                  </p>
                )}
                <p className="w-full flex justify-between">
                  <span>영상 목소리</span>
                  <span> {textVideoPriceInfo.voicePrice.toLocaleString()}</span>
                </p>
              </div>
            </div>
          </div>
          <div
            className="bg-[#AFECEC] rounded-[20px] mt-[8px] flex p-[20px] md:flex-row justify-between items-center md:px-[32px] md:py-[24px] h-[78px] "
            style={{
              opacity: isPending ? 0.3 : 1,
              pointerEvents: isPending ? 'none' : 'auto',
            }}
          >
            <p className="text-[18px] md:text-[24px] font-bold">총 사용캐시</p>
            <p className="text-[24px] font-bold">
              {' '}
              {(
                textVideoPriceInfo.voicePrice +
                (textVideoPriceInfo?.sourceTypePrice ?? 0)
              ).toLocaleString()}{' '}
            </p>
          </div>

          {!isValidPoint() ? (
            <div className="flex justify-between mt-[12px] font-bold ">
              <button
                className="bg-error w-full px-[22px] py-[19.5px] text-white rounded-[40px]"
                onClick={handleCashPaymentModal}
              >
                {`캐시가 부족해요! 충전하러 가기 >>`}
              </button>
            </div>
          ) : (
            <div className="flex justify-between mt-[12px] font-bold md:text-[18px] text-[14px] ">
              <button
                className="bg-black w-[34%] md:px-[22px] px-[11px] md:py-[19.5px] py-[14px] text-white rounded-[40px]"
                onClick={handleCashPaymentModal}
                style={{
                  opacity: isPending ? 0.3 : 1,
                  pointerEvents: isPending ? 'none' : 'auto',
                }}
              >
                캐시 충전
              </button>
              {!isPending ? (
                <button
                  className="bg-main w-[64%] md:px-[22px] px-[11px] md:py-[19.5px] py-[14px] text-white rounded-[40px]"
                  onClick={handleSubmit}
                  disabled={!isValidPoint() || isPending}
                >
                  {(
                    textVideoPriceInfo.voicePrice +
                    (textVideoPriceInfo?.sourceTypePrice ?? 0)
                  ).toLocaleString()}{' '}
                  캐시 사용하기
                </button>
              ) : (
                <button className="bg-[#FF8800] w-[64%] md:px-[22px] px-[11px] md:py-[19.5px] py-[14px] text-white rounded-[40px]">
                  데이터 전송중
                </button>
              )}
            </div>
          )}
        </div>
        {cashPaymentModal && (
          <CashPaymentModal
            setCashPaymentModal={setCashPaymentModal}
            affiliatePoint={userInfo?.affiliate?.point}
            userInfo={userInfo?.userInfo}
            userCurrentPoint={currentPoint}
            requestPurchasePoint={requestPurchasePoint}
          />
        )}
      </div>
      {isModal && (
        <Modal isModal={isModal}>
          <div className="bg-white w-[335px] h-[184px] p-[20px] border-t rounded-t-[20px]">
            <div className="flex flex-col items-center justify-center h-full gap-[24px]">
              <p className="font-bold text-[18px]">{errorText.title}</p>
              <div className="flex flex-col leading-[22px]">
                <p className=" text-[14px] ">{errorText.subtitle}</p>
              </div>
            </div>
          </div>
          <div className=" w-[335px]  text-[14px]  h-[60px]  flex justify-center items-center">
            <button
              className="text-white w-[100%] h-full bg-main rounded-b-[20px] border-b border-main font-bold"
              onClick={() => {
                setModal((prev) => !prev)
              }}
            >
              확인
            </button>
          </div>
        </Modal>
      )}
    </>
  )
}

export default TextCashUseModal
