import { useMutation } from '@tanstack/react-query'
import { deleteLink } from 'service/list'

function useDeleteLink() {
  return useMutation({
    mutationKey: ['delete-link'],
    mutationFn: deleteLink,
  })
}

export default useDeleteLink
