import React from 'react'
import { ReactComponent as PlayIcon } from 'svg/play.svg'
import { ReactComponent as PauseIcon } from 'svg/pause.svg'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { PlanTag } from 'components/planTag'
interface VoicePlayerProps {
  id: number
  index: number
  label?: string
  checked?: boolean
  disabled?: boolean
  onChange: (id: number) => void
  className?: string
  handleFn: (status: boolean, index: number) => void
  playing: boolean[]
  planName?: string
  cashRate?: number
  voiceRefs: React.MutableRefObject<(HTMLAudioElement | null)[]>
  src: string
}

export const VoicePlayer: React.FC<VoicePlayerProps> = ({
  id,
  index,
  label,
  checked,
  disabled,
  onChange,
  className,
  handleFn,
  playing,
  planName,
  voiceRefs,
  cashRate,
  src,
}) => {
  return (
    <>
      <div
        className={classNames(' cursor-pointer', {
          'w-[240px] h-[54px] flex gap-[8px] justify-between items-center bg-[#F9F9FB] rounded-[4px] px-4':
            !isMobile,
          'w-[170px] h-[54px] flex gap-[8px] justify-between items-center bg-[#F9F9FB] rounded-[4px] px-2':
            isMobile,
        })}
        key={`voice_${index}`}
        onClick={() => {
          onChange(id)
        }}
      >
        <div className="flex w-full">
          <div className="relative flex items-center" key={index}>
            <label className="relative flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={checked}
                onChange={() => onChange(id)}
                className="sr-only"
                disabled={disabled}
              />
              <div
                className={`w-[20px] h-[20px] rounded-full border-2 bg-main flex items-center justify-center ${
                  checked ? 'bg-white border-main' : 'bg-white'
                }`}
              >
                {checked && (
                  <div className="w-3 h-3 bg-main rounded-full"></div>
                )}
              </div>
            </label>
          </div>

          <span
            className={`text-[#2E343D] md:text-[18px] ml-2 ${className}  `}
            style={!checked && disabled ? { opacity: 0.3 } : {}}
          >
            {label}
          </span>
          {planName && (
            <div className="flex items-start h-full  p-t1 ml-[2px] mr-[8px]">
              <PlanTag planName={planName} className="" />
            </div>
          )}
          {cashRate && (
            <span className="flex items-start h-full  p-t1 ml-[2px] mr-[8px]">
              {new Array(cashRate).fill(0).map((el, i) => (
                <>
                  {' '}
                  <img
                    src="assets/img/cash.png"
                    alt="cash"
                    className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
                    key={`voiceCashRate_${i}`}
                  />
                </>
              ))}
            </span>
          )}
        </div>
        <button
          type="button"
          onClick={() => {
            handleFn(!playing[index], index)
          }}
        >
          {!playing[index] ? <PlayIcon /> : <PauseIcon />}
        </button>
        <div className="flex gap[12px] dis">
          <button type="button">
            <audio
              ref={(ref) => {
                voiceRefs.current[index] = ref
              }}
            >
              <source src={src} type="audio/mpeg" />
            </audio>
          </button>
        </div>
      </div>
    </>
  )
}
