import { useQuery } from '@tanstack/react-query'
import { getVoice } from '../../service/list'

function useGetVoice() {
  return useQuery({
    queryKey: ['get-voice'],
    queryFn: () => getVoice(),
    initialData: [
      {
        id: 0,
        name: '',
        service: '',
        planName: '',
        usable: false,
        path: '',
        originPrice: 0,
        price: 0,
        shortPath: '',
        cashRate: 0,
        default: 0,
        sort: 0,
      },
    ],
  })
}

export default useGetVoice
