import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
interface ToggleProps {
  isToggled: boolean
  handleToggle: () => void
}
const Toggle: React.FC<ToggleProps> = ({ isToggled, handleToggle }) => {
  return (
    <div className="flex  justify-center md:justify-end items-center relative ">
      <div className="flex items-center">
        <div
          className={`ml-3 font-bold toggle__label   ${
            isToggled ? 'text-[#8F929B] ' : 'text-[#FD6767]'
          } `}
        >
          연 결제
        </div>

        {isMobile ? (
          <>
            <p className=" absolute -top-7 -left-0   w-[118px] h-[24px] rounded-[4px] font-bold text-[#FD6767] bg-[#FFE8E8] text-[10px] flex justify-center items-center">
              ~ 40% 할인 + 무이자할부
            </p>
          </>
        ) : (
          <>
            <p className="ml-[10px] px-[10px] py-[6px] rounded-[6px] font-bold text-[#FD6767] bg-[#FFE8E8]">
              ~ 40% 할인 + 무이자할부
            </p>
          </>
        )}
      </div>
      <div className="flex">
        {isMobile ? (
          <>
            <p className=" absolute -top-14 -left-0  w-[115px] h-[24px]  rounded-[6px] font-bold text-[#FF8800] bg-[#FFF7E8] text-[10px] flex items-center justify-center">
              🎁 매 라운드 럭키드로우
            </p>
          </>
        ) : (
          <>
            <p className="ml-[10px] px-[10px]  py-[6px] rounded-[6px] font-bold text-[#FF8800] bg-[#FFF7E8]">
              🎁 매 라운드 럭키드로우
            </p>
          </>
        )}
      </div>

      <div className="px-4">
        {/* 토글 버튼 */}
        <label
          htmlFor="toggleButton"
          className="flex items-center cursor-pointer relative"
        >
          {/* 원형 이동 애니메이션 */}
          <div
            className={`absolute left-[2px] w-[28px] h-[28px] bg-white rounded-full shadow-md transition-transform duration-300 transform ${
              isToggled ? 'translate-x-[30px]' : ''
            }`}
          ></div>
          <div
            className={`w-[62px] h-[34px]  ${
              isToggled ? 'bg-[#5E6068]' : 'bg-[#FD6767]'
            } rounded-full shadow-inner toggle__line ${
              isToggled ? 'text-[#5E6068]' : 'text-[#8F929B]'
            }`}
          ></div>
          <div
            className={`ml-3 font-bold toggle__label ${
              isToggled ? 'text-[#5E6068]' : 'text-[#8F929B] '
            } font-bold`}
          >
            월 구독
          </div>
        </label>
        <input
          type="checkbox"
          id="toggleButton"
          className="hidden"
          checked={isToggled}
          onChange={handleToggle}
        />
      </div>
    </div>
  )
}

export default Toggle
