import React, { useEffect, useState } from 'react'
import { My } from '../components/My'
import { ShadowBox } from '../components/ShadowBox'
import { isMobile } from 'react-device-detect'
import { useLocation, useNavigate } from 'react-router-dom'
import useGetMePlan from '../hooks/query/useGetMePlan'

import { UsageRequestFactor } from '../service/list'
import useGetUsageRequest from '../hooks/query/useGetUsageRequest'

import { showErrorBox, showYoutubeAuth, UserType } from '../utils/common'
import { GuideToUse } from '../components/GuideToUse'
import classNames from 'classnames'
import { AfterGuideCircle } from '../components/freePlan/GuideCircle'
import { PromotionCircle } from '../components/upgrade/PromotionCircle'
import useGetShortUsageRequest from '../hooks/query/useGetShortUsageRequest'
import { ShortsVideoDailyBarChart } from '../components/chart/DailyBarChart'

import { Modal } from '../components/layout/Modal'
import { TextArea, TextAreaMyVideo } from '../components/atom/TextArea'
import { Controller, useForm } from 'react-hook-form'

import {
  EmptyPlan,
  FreePlan,
  LightPlan,
  ProAndPremiumPlanAnnual,
  ProAndPremiumPlanMonthly,
} from '../components/PlanDashBoard/PlanDashBoard'
import useCreateChallenge from '../hooks/query/challenge/useCreateChallenge'
import useGetChallenge from '../hooks/query/challenge/useGetChallenge'
import useUpdateChallenge from '../hooks/query/challenge/useUpdateChallenge'
import { ChallengeType } from '../service/challenge'
import useInsertLinkChallenge from '../hooks/query/challenge/useInsertLinkChallenge'
import { fail } from 'assert'
import { PlanPriceModal } from '../components/payment/PlanPriceModal'
import useUserStore from '../store/useUserStore'
import useGetTextContentRequest from 'hooks/query/useGetTextContentRequest'

export const MyVideos = () => {
  const PRODUCT_LONG_VIDEO = 'plv'
  const PRODUCT_SHORT_VIDEO = 'psv'
  const LINK_CONTENT_VIDEO = 'lcv'
  const TEXT_CONTENT_VIDEO = 'tcv'
  const userAgent = navigator.userAgent.toLowerCase()

  const location = useLocation()
  const navigate = useNavigate()
  const [totalItems, setTotalItems] = useState(0)
  const itemsPerPage = 80
  const [defaultPage, setDefaultPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const totalPages = Math.ceil(totalItems / itemsPerPage)

  const params = new URLSearchParams(location.search)
  const tType = params.get('type')
  let page = parseInt(params.get('page') || '', 10)

  const checkParamType = (type: string) => {
    if (type && type === 'plv') {
      return PRODUCT_LONG_VIDEO
    } else if (type && type === 'psv') {
      return PRODUCT_SHORT_VIDEO
    } else if (type && type === 'lcv') {
      return LINK_CONTENT_VIDEO
    } else if (type && type === 'tcv') {
      return TEXT_CONTENT_VIDEO
    }
    return 'plv'
  }

  const getTextByTab = (tab: string) => {
    switch (tab) {
      case PRODUCT_LONG_VIDEO:
        return '쿠팡, 알리 어필리에이트 상품으로 영상을 만들어보세요!'
      case PRODUCT_SHORT_VIDEO:
        return '쿠팡, 알리 어필리에이트 상품으로 영상을 만들어보세요!'
      case LINK_CONTENT_VIDEO:
        return '뉴스나 블로그 링크를 입력해서 컨텐츠 영상을 만들어보세요!'
      case TEXT_CONTENT_VIDEO:
        return '영상으로 만들고 싶은 내용을 입력해서 컨텐츠 영상을 만들어보세요!'
      default:
        return ''
    }
  }

  const changeTypeByParam = (param: string) => {
    if (param === 'psv') {
      return 'product'
    } else if (param === 'lcv') {
      return 'contents'
    }
    return 'psv'
  }

  const [tab, setTab] = useState(checkParamType(tType ?? ''))
  const [dashboardTab, setDashboardTab] = useState(PRODUCT_LONG_VIDEO)
  const [isPlanPriceModal, setPlanPriceModal] = useState<boolean>(false)
  const [linkSaveModal, setLinkSaveModal] = useState<boolean>(false)
  const [showRevenueModal, setShowRevenueModal] = useState<boolean>(false)
  const [failInfoModal, setFailInfoModal] = useState<boolean>(false)
  const [errorText, setErrorText] = useState({
    title: '',
    subtitle: <></>,
  })

  if (!page || isNaN(page) || page < 1) {
    page = defaultPage
    params.set('type', checkParamType(tab))
    params.set('page', page.toString())
    params.set('pageSize', itemsPerPage.toString())
    navigate({ search: params.toString() })
  }
  //* 패스트컷 비디오 사용량(상품 롱폼)
  const {
    data: usageRequest,
    refetch: usageRefetch,
    isError,
  } = useGetUsageRequest(page, itemsPerPage)

  //* 링크 / 상품 숏폼
  const {
    data: shortUsageRequest,
    refetch: shortUsageRefetch,
    isError: isShortError,
  } = useGetShortUsageRequest(page, itemsPerPage, changeTypeByParam(tab))

  //* 텍스트 비디오
  const {
    data: textContentUsageRequest,
    refetch: textContentUsageRefetch,
    isError: isTextContentError,
  } = useGetTextContentRequest(page, itemsPerPage)

  const { data: userPlanInfo, refetch, isLoading } = useGetMePlan()

  // user의 영상생성 개수 불러오기
  const userInfo = useUserStore((state: any) => state.userInfo)

  const [usageList, setUsageList] = useState<UsageRequestFactor[] | []>([])
  const openStatusDialog = (fcStatus: string) => {
    if (['재시도', '생성 실패'].includes(fcStatus)) {
      if (fcStatus === '생성 실패') {
        setErrorText({
          title: '🙏 영상 생성 실패',
          subtitle: (
            <>
              링크, 이미지, 상품 등에 문제가 발행하여
              <br />
              영상 생성에 실패했습니다.
              <br />
              자세한 이유와 처리 안내 등은
              <br />
              가입하신 이메일로 설명 드렸으니
              <br />꼭 확인 부탁드립니다.
            </>
          ),
        })
      } else if (fcStatus === '재시도') {
        setErrorText({
          title: '🧑‍💻 영상 생성 재시도',
          subtitle: (
            <>
              링크, 이미지, 상품 등에 문제가 발행하여
              <br />
              영상 생성을 재시도 합니다.
              <br />
              시간이 조금 더 소요되는 점 양해 부탁드립니다.
            </>
          ),
        })
      }

      setFailInfoModal((prev: any) => !prev)
    }
  }
  const createTable = () => {
    if (isMobile) {
      return (
        <>
          <div
            className="  rounded-b-[20px] md:rounded-tr-[20px]  md:py-[40px] md:px-8"
            style={{ border: '1px solid #EEF1F7' }}
          >
            <table className="min-w-full border-collapse py-4">
              <thead className="">
                <tr className="border-b text-center text-[#5E6068]">
                  <th className="w-[30px] px-2 py-5">NO</th>
                  <th className="px-2 py-5 text-left">제목</th>
                  <th className=" w-[79px] px-1 py-5">상태</th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-center text-[#5E6068] bg-[#EBF4FF]">
                  <td className="text-[20px]">💁🏻</td>
                  <td className="text-[14px]">
                    생성 완료 영상 스마트폰 다운로드는
                    <br />
                    구글드라이브 앱에서만 가능합니다!
                  </td>
                  <td className="">
                    <a
                      className="flex gap-1 items-center bg-white rounded-full text-[12px] px-2 py-2 a"
                      href={
                        userAgent.includes('android')
                          ? 'https://play.google.com/store/apps/details?id=com.google.android.apps.docs&hl=ko'
                          : 'https://apps.apple.com/kr/app/google-%EB%93%9C%EB%9D%BC%EC%9D%B4%EB%B8%8C-%EC%95%88%[…]%8C%EC%9D%BC-%EC%A0%80%EC%9E%A5%EA%B3%B5%EA%B0%84/id507874739'
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src="assets/img/drive.png" alt="drive" />
                      {`앱 설치 >`}
                    </a>
                  </td>
                </tr>
                {usageList.length > 0 ? (
                  <>
                    {usageList.map((el, index) => {
                      const {
                        mainTitle,
                        createdAt: requestedAt,
                        contentCreatedAt,
                        uploadedAt,
                        driveLink,
                        type,
                        status,
                      } = el

                      return (
                        <>
                          <tr className="text-center text-[#5E6068]">
                            <td
                              className={`px-1  align-top ${
                                index === 0 ? 'py-[24px]' : 'pb-[24px]'
                              }`}
                            >
                              {totalItems &&
                                (totalItems - itemsPerPage * (page - 1) - index)
                                  .toString()
                                  .padStart(2, '0')}
                            </td>

                            <td
                              className={`px-1  align-top text-left ${
                                index === 0 ? 'py-[24px]' : 'pb-[24px]'
                              }`}
                            >
                              {mainTitle !== ''
                                ? mainTitle
                                : '영상 생성 준비중...'}
                            </td>
                            <td
                              className={` flex items-start h-[24px] px-1  align-top text-left ${
                                index === 0 ? 'py-[24px]' : 'pb-[24px]'
                              }`}
                            >
                              <a
                                href={driveLink}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <button
                                  className={`text-[#FFFFFF] font-bold rounded-[20px] text-[12px] w-[79px] h-[24px] flex justify-center items-center gap-[4px]  ${
                                    driveLink && ' active:scale-95'
                                  } ${status === '생성 대기' && 'bg-[#C4DFFF]'}
                                  ${status === '재시도' && 'bg-[#FF8800]'}
                                  ${status === '생성 실패' && 'bg-[#2E343D]'}
                                  ${status === '생성 완료' && 'bg-[#3A8FF2]'}
                                  ${status === '등록 완료' && 'bg-[#464DEE]'} `}
                                  onClick={() => {
                                    openStatusDialog(status)
                                  }}
                                >
                                  <span>{status} </span>{' '}
                                  {['재시도', '생성 실패'].includes(status) &&
                                    '>'}
                                  {driveLink && (
                                    <img
                                      src="assets/img/icon_cloud.png"
                                      alt="cloud"
                                      height={14}
                                      width={14}
                                    />
                                  )}
                                </button>
                              </a>
                            </td>
                          </tr>
                        </>
                      )
                    })}
                  </>
                ) : (
                  <>
                    {' '}
                    <tr>
                      <td
                        colSpan={6}
                        rowSpan={10}
                        className="text-center py-[80px]"
                      >
                        <p className="text-[18px] font-bold leading-[28px]">
                          아직 생성 요청하신{' '}
                          {tab === PRODUCT_LONG_VIDEO ||
                          tab === PRODUCT_SHORT_VIDEO
                            ? '영상'
                            : '컨텐츠 영상'}
                          이 없어요!
                          <br />
                          {getTextByTab(tab)}
                          <br />
                          <span className="text-main">
                            <button
                              onClick={() => {
                                tab === PRODUCT_LONG_VIDEO ||
                                tab === PRODUCT_SHORT_VIDEO
                                  ? navigate('/video-editor')
                                  : navigate(
                                      `/short-video-editor?createType=${
                                        tab === LINK_CONTENT_VIDEO
                                          ? 'link'
                                          : 'text'
                                      }`
                                    )
                              }}
                            >
                              {tab === PRODUCT_LONG_VIDEO ||
                              tab === PRODUCT_SHORT_VIDEO
                                ? '상품 영상 만들러 가기 >>'
                                : '컨텐츠 영상 만들러 가기 >>'}
                            </button>
                          </span>
                        </p>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
          <div className="pagenation flex justify-center gap-[4px] mt-3 ">
            <button
              className="flex items-center justify-center w-[32px] h-[32px] border-[1px] border-[#DFE3E8] font-bold text-[14px] disabled:bg-[#EEF1F7]"
              onClick={() => handlePageEndChange(1)}
              disabled={currentPage === 1}
            >
              <img src="assets/img/icon_arrow_left_end.png" alt="icon" />
            </button>
            <button
              className="flex items-center justify-center w-[32px] h-[32px] border-[1px] border-[#DFE3E8] font-bold text-[14px] disabled:bg-[#EEF1F7]"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <img src="assets/img/icon_arrow_left.png" alt="icon" />
            </button>
            {renderPageNumbers()}
            <button
              className="flex items-center justify-center w-[32px] h-[32px] border-[1px] border-[#DFE3E8] font-bold text-[14px] disabled:bg-[#EEF1F7]"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <img src="assets/img/icon_arrow_right.png" alt="icon" />
            </button>
            <button
              className="flex items-center justify-center w-[32px] h-[32px] border-[1px] border-[#DFE3E8] font-bold text-[14px] disabled:bg-[#EEF1F7]"
              disabled={currentPage === totalPages}
              onClick={() => {
                handlePageEndChange(totalPages)
              }}
            >
              <img src="assets/img/icon_arrow_right_end.png" alt="icon" />
            </button>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div
            className=" w-full md:rounded-b-[20px] md:rounded-tr-[20px]  md:py-[40px] md:px-8"
            style={{ border: '1px solid #EEF1F7' }}
          >
            <table className="min-w-full border-collapse">
              <thead className="">
                <tr className="border-b text-center text-[#5E6068]">
                  <th className="w-[30px] px-2 pb-5">NO</th>

                  <th className="px-2 pb-5 text-left">제목</th>
                  <th className=" w-[42px] px-1 pb-5">요청</th>
                  <th className=" w-[42px] px-1 pb-5">생성</th>
                  {tab === PRODUCT_LONG_VIDEO && (
                    <th className=" w-[42px] px-1 pb-5">등록</th>
                  )}

                  <th className=" w-[79px] px-1 pb-5">상태</th>
                </tr>
              </thead>
              <tbody className="">
                {usageList?.length > 0 ? (
                  <>
                    {usageList.map((el, index) => {
                      const {
                        mainTitle,
                        createdAt: requestedAt,
                        uploadedAt,
                        contentCreatedAt,
                        driveLink,
                        type,
                        status,
                      } = el

                      return (
                        <>
                          <tr className="text-center text-[#5E6068]">
                            <td className="px-1  p-[12px]">
                              {totalItems &&
                                (totalItems - itemsPerPage * (page - 1) - index)
                                  .toString()
                                  .padStart(2, '0')}
                            </td>

                            <td className="px-1  p-[12px] text-left">
                              {mainTitle !== ''
                                ? mainTitle
                                : '영상 생성 준비중...'}
                            </td>
                            <td className="request px-1 p-[12px]">
                              {requestedAt}
                            </td>
                            <td className="create px-1 p-[12px]">
                              {contentCreatedAt}
                            </td>
                            {tab === PRODUCT_LONG_VIDEO && (
                              <td className="upload px-1   p-[12px]">
                                {uploadedAt}
                              </td>
                            )}
                            <td className="h-[24px] px-1  p-[12px]">
                              <a
                                href={driveLink}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <button
                                  className={`flex items-center justify-center gap-[4px] text-[#FFFFFF] font-bold rounded-[20px] text-[12px] w-[79px] h-[24px]
                                  ${driveLink && ' active:scale-95'} ${
                                    status === '생성 대기' && 'bg-[#C4DFFF]'
                                  }
                                  ${status === '재시도' && 'bg-[#FF8800]'}
                                  ${status === '생성 실패' && 'bg-[#2E343D]'}
                                  ${
                                    status === '생성 완료' && 'bg-[#3A8FF2]'
                                  }  ${
                                    status === '등록 완료' && 'bg-[#464DEE]'
                                  } `}
                                  onClick={() => {
                                    openStatusDialog(status)
                                  }}
                                >
                                  <span>{status}</span>{' '}
                                  {['재시도', '생성 실패'].includes(status) &&
                                    '>'}
                                  {driveLink && (
                                    <img
                                      src="assets/img/icon_cloud.png"
                                      alt="cloud"
                                      height={14}
                                      width={14}
                                    />
                                  )}
                                </button>
                              </a>
                            </td>
                          </tr>
                        </>
                      )
                    })}
                  </>
                ) : (
                  <>
                    {' '}
                    <tr>
                      <td
                        colSpan={6}
                        rowSpan={10}
                        className="text-center py-[80px]"
                      >
                        <p className="text-[18px] font-bold leading-[28px]">
                          아직 생성 요청하신{' '}
                          {tab === PRODUCT_LONG_VIDEO ||
                          tab === PRODUCT_SHORT_VIDEO
                            ? '영상'
                            : '컨텐츠 영상'}
                          이 없어요!
                          <br />
                          {getTextByTab(tab)}
                          <br />
                          <span className="text-main">
                            <button
                              onClick={() => {
                                tab === PRODUCT_LONG_VIDEO ||
                                tab === PRODUCT_SHORT_VIDEO
                                  ? navigate('/video-editor')
                                  : navigate(
                                      `/short-video-editor?createType=${
                                        tab === LINK_CONTENT_VIDEO
                                          ? 'link'
                                          : 'text'
                                      }`
                                    )
                              }}
                            >
                              {tab === PRODUCT_LONG_VIDEO ||
                              tab === PRODUCT_SHORT_VIDEO
                                ? '상품 영상 만들러 가기 >>'
                                : '컨텐츠 영상 만들러 가기 >>'}
                            </button>
                          </span>
                        </p>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
              {/* <div className="w-full">
               
              </div> */}
            </table>
            <div className=" pagenation flex justify-center gap-[4px] ">
              <button
                className="flex items-center justify-center w-[32px] h-[32px] border-[1px] border-[#DFE3E8] font-bold text-[14px] disabled:bg-[#EEF1F7]"
                onClick={() => handlePageEndChange(1)}
                disabled={currentPage === 1}
              >
                <img src="assets/img/icon_arrow_left_end.png" alt="icon" />
              </button>
              <button
                className="flex items-center justify-center w-[32px] h-[32px] border-[1px] border-[#DFE3E8] font-bold text-[14px] disabled:bg-[#EEF1F7]"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <img src="assets/img/icon_arrow_left.png" alt="icon" />
              </button>
              {renderPageNumbers()}
              <button
                className="flex items-center justify-center w-[32px] h-[32px] border-[1px] border-[#DFE3E8] font-bold text-[14px] disabled:bg-[#EEF1F7]"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <img src="assets/img/icon_arrow_right.png" alt="icon" />
              </button>
              <button
                className="flex items-center justify-center w-[32px] h-[32px] border-[1px] border-[#DFE3E8] font-bold text-[14px] disabled:bg-[#EEF1F7]"
                disabled={currentPage === totalPages}
                onClick={() => {
                  handlePageEndChange(totalPages)
                }}
              >
                <img src="assets/img/icon_arrow_right_end.png" alt="icon" />
              </button>
            </div>
          </div>
        </>
      )
    }
  }

  const renderPageNumbers = () => {
    const pagesPerBlock = 10
    const pageNumbers = []
    const currentBlock = Math.floor((currentPage - 1) / pagesPerBlock)
    const startPage = currentBlock * pagesPerBlock + 1
    const endPage = Math.min(startPage + pagesPerBlock - 1, totalPages)

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          className={classNames(
            `flex items-center justify-center w-[32px] h-[32px] border-[1px]  font-bold text-[14px]`,
            {
              'border-main text-main': currentPage === i,
              'border-[#DFE3E8]': currentPage !== i,
            }
          )}
          key={i}
          onClick={() => handlePageChange(i)}
          disabled={currentPage === i}
        >
          {i}
        </button>
      )
    }

    return pageNumbers
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    const params = new URLSearchParams(location.search)
    params.set('page', page.toString())
    params.set('pageSize', itemsPerPage.toString())
    navigate({ search: params.toString() })
  }
  const handlePageEndChange = (page: number) => {
    setCurrentPage(page)
    const params = new URLSearchParams(location.search)
    params.set('page', page.toString())
    params.set('pageSize', itemsPerPage.toString())
    navigate({ search: params.toString() })
  }

  const handleTab = (type: string) => {
    setTab(type)
  }
  const handleDashboardTab = (type: string) => {
    setDashboardTab(PRODUCT_LONG_VIDEO)
  }

  const createLIEl = () => {
    if (tab === PRODUCT_LONG_VIDEO || tab === PRODUCT_SHORT_VIDEO) {
      return (
        <>
          {' '}
          <li className="p-[4px] text-href font-bold underline">
            <a
              href="https://blog.fastseller.shop/youtubeuploadguide/"
              target="_blank"
              rel="noreferrer"
            >
              라이트플랜 영상 업로드 방법 가이드
            </a>
          </li>
          <li className="p-[4px]">
            영상 다운로드는 생성 완료된 시점으로부터 30일간 가능합니다.
          </li>
          <li className="p-[4px]">
            프로, 프리미엄 플랜의 경우, 유튜브 권한 설정이 완료된 상태여야
            유튜브 자동 업로드가 가능합니다.
          </li>
          <li className="p-[4px]">
            플랜별 영상 생성 개수는 사용자가 ‘생성 요청한 영상’의 개수를
            기준으로 카운팅 합니다.
          </li>
          <li className="p-[4px]">영상 생성은 요청 후 3일 내에 완료됩니다.</li>
          <li className="p-[4px]">
            프로,프리미엄 플랜은 1일 최대 3개까지 오전 10시, 오후 2시, 오후
            6시에 업로드 예약 됩니다
          </li>
          <li className="p-[4px]">
            생성과 업로드 일정 등은 서비스 현황에 따라 별도의 공지 없이 변동될
            수 있습니다.
          </li>
        </>
      )
    } else if (tab === LINK_CONTENT_VIDEO || tab === TEXT_CONTENT_VIDEO) {
      return (
        <>
          <li className="p-[4px] text-href font-bold underline">
            <a
              href="https://blog.fastseller.shop/youtubeshortsupload/"
              target="_blank"
              rel="noreferrer"
            >
              컨텐츠 영상 업로드 방법 가이드
            </a>
          </li>
          <li className="p-[4px]">
            컨텐츠 영상 파일 다운로드는 생성 완료된 시점으로부터 30일간
            가능합니다.
          </li>
          <li className="p-[4px]">영상 생성은 요청 후 3일 내에 완료됩니다.</li>
          <li className="p-[4px]">
            생성 일정 등은 서비스 현황에 따라 별도의 공지 없이 변동될 수
            있습니다.
          </li>
        </>
      )
    }
  }

  const chartElByTab = () => {
    if (dashboardTab === LINK_CONTENT_VIDEO) {
      return (
        <>
          <div className="flex flex-col w-full  p-5  bg-white rounded-[10px] justify-evenly h-[210px] md:w-[474px] relative">
            <p className="font-bold">2024. 11월</p>

            <ShortsVideoDailyBarChart barWidth={isMobile ? 4 : 8} />
            <div className="bg-white opacity-50 w-full h-full absolute"></div>
            <div className="bg-white border-[2px] border-[#D9DEE8] w-[87px] h-[37px] font-bold text-[14px] rounded-[8px] absolute left-[37.5%] md:left-[45%] top-[50%] flex items-center justify-center">
              <p className="text-center z-50">기능 준비중</p>
            </div>
          </div>
          <div className="flex w-full md:w-[248px]  p-5 bg-white rounded-[20px] items-center justify-evenly">
            <div className="items flex flex-col items-center gap-2">
              <p
                className={classNames(
                  'bg-[#C4DFFF] flex items-center justify-center',
                  {
                    'w-[50px] h-[50px]  rounded-[100px] ':
                      userInfo?.fastCutShortUsageSummary[0]?.ready.length ??
                      0 < 3,
                    'w-[70px] h-[50px] rounded-[50px] ':
                      userInfo?.fastCutShortUsageSummary[0]?.ready.length >= 3,
                  }
                )}
              >
                <span className="text-white font-bold text-[24px]">
                  {userInfo?.fastCutShortUsageSummary[0]?.ready ?? 0}
                </span>
              </p>

              <p className="font-bold">생성 대기</p>
            </div>
            <div className="items flex flex-col items-center gap-2">
              <p
                className={classNames(
                  'bg-[#3A8FF2]  flex items-center justify-center',
                  {
                    'w-[50px] h-[50px]  rounded-[100px] ':
                      userInfo?.fastCutShortUsageSummary[0]?.complete.length ??
                      0 < 3,
                    'w-[70px] h-[50px] rounded-[50px] ':
                      userInfo?.fastCutShortUsageSummary[0]?.complete.length >=
                      3,
                  }
                )}
              >
                <span className="text-white font-bold text-[24px]">
                  {' '}
                  {userInfo?.fastCutShortUsageSummary[0]?.complete ?? 0}
                </span>
              </p>
              <p className="font-bold">생성 완료</p>
            </div>
          </div>
        </>
      )
    } else if (dashboardTab === PRODUCT_LONG_VIDEO) {
      return (
        <>
          {userInfo?.userType === UserType.라이트 && (
            <>
              <LightPlan
                subscriptionType={
                  userPlanInfo?.planPrice?.planPrice[0].subscriptionType ?? ''
                }
                startDate={userPlanInfo?.subscriptions?.startDate ?? '--'}
                endDate={userPlanInfo?.subscriptions?.endDate ?? '--'}
                currentMonth={userPlanInfo?.subscriptions?.currentMonth ?? ''}
                subscriptions={userPlanInfo?.subscriptions}
                monthStart={userPlanInfo?.subscriptions.monthStart ?? '--'}
                monthEnd={userPlanInfo?.subscriptions.monthEnd ?? '--'}
              />
            </>
          )}
          {(userInfo?.userType === UserType.프로 ||
            userInfo?.userType === UserType.프리미엄) &&
          userInfo?.subscriptions?.subscriptionType === 'annual' ? (
            <>
              <ProAndPremiumPlanAnnual
                userType={userInfo?.userType}
                subscriptionType={
                  userPlanInfo?.planPrice?.planPrice[0].subscriptionType ?? ''
                }
                startDate={userPlanInfo?.subscriptions?.monthStart ?? '--'}
                endDate={userPlanInfo?.subscriptions?.monthEnd ?? '--'}
                currentMonth={userPlanInfo?.subscriptions?.currentMonth ?? ''}
                available={userPlanInfo?.usage?.available ?? false}
                couponBenefit={userInfo?.couponBenefit}
                isDone={userPlanInfo?.usage?.isDone}
                subscriptions={userPlanInfo?.subscriptions}
              />
            </>
          ) : (
            (userInfo?.userType === UserType.프로 ||
              userInfo?.userType === UserType.프리미엄) &&
            userInfo?.subscriptions?.subscriptionType === 'monthly' && (
              <>
                {' '}
                <ProAndPremiumPlanMonthly
                  userType={userInfo?.userType}
                  subscriptionType={
                    userPlanInfo?.planPrice?.planPrice[0].subscriptionType ?? ''
                  }
                  startDate={userPlanInfo?.subscriptions?.startDate ?? '--'}
                  endDate={userPlanInfo?.subscriptions?.endDate ?? '--'}
                  currentMonth={userPlanInfo?.subscriptions?.currentMonth ?? ''}
                  available={userPlanInfo?.usage?.available ?? false}
                  isDone={userPlanInfo?.usage?.isDone}
                  couponBenefit={userInfo?.couponBenefit}
                  subscriptions={userPlanInfo?.subscriptions}
                />
              </>
            )
          )}
          {userInfo?.userType === UserType.USER && (
            <>
              <EmptyPlan
                subscriptionType={
                  userPlanInfo?.planPrice?.planPrice[0].subscriptionType ?? ''
                }
                startDate={userPlanInfo?.subscriptions?.startDate ?? '--'}
                endDate={userPlanInfo?.subscriptions?.endDate ?? '--'}
                currentMonth={userPlanInfo?.subscriptions?.currentMonth ?? ''}
                setPlanPriceModal={setPlanPriceModal}
              />
            </>
          )}
          {userInfo?.userType === UserType.체험 && (
            <>
              <FreePlan
                userType={userInfo?.userType}
                subscriptionType={
                  userPlanInfo?.planPrice?.planPrice[0].subscriptionType ?? ''
                }
                startDate={userPlanInfo?.subscriptions?.startDate ?? '--'}
                endDate={userPlanInfo?.subscriptions?.endDate ?? '--'}
                currentMonth={userPlanInfo?.subscriptions?.currentMonth ?? ''}
                totalLimit={userPlanInfo?.usage?.totalLimit ?? 0}
                usedCount={userPlanInfo?.usage?.usedCount ?? 0}
                available={userPlanInfo?.usage?.available ?? false}
                couponBenefit={userInfo?.couponBenefit}
                isDone={userPlanInfo?.usage?.isDone}
                subscriptions={userPlanInfo?.subscriptions}
              />
            </>
          )}
        </>
      )
    } else {
      return null
    }
  }

  const handleChallengeBtn = () => {
    if (
      dashboardTab === PRODUCT_LONG_VIDEO &&
      userInfo?.challenge &&
      userPlanInfo?.subscriptions?.round
    ) {
      if (userPlanInfo?.subscriptions?.round <= 3) {
        return (
          <div className="">
            <button
              className="bg-[#735CFF] flex gap-1 rounded-[20px] w-[343px] md:w-[156px] h-[36px] justify-center items-center font-bold text-[14px] text-[white]"
              onClick={() => {
                setLinkSaveModal((prev) => !prev)
              }}
            >
              <span>✋챌린지 참여 선언</span>
            </button>
          </div>
        )
      } else if (userPlanInfo?.subscriptions?.round > 3) {
        return (
          <div className="">
            <button
              className="bg-[#007A7B] flex gap-1 rounded-[20px] w-[343px] md:w-[116px] h-[36px] justify-center items-center font-bold text-[14px] text-[white]"
              onClick={() => {
                setShowRevenueModal((prev) => !prev)
              }}
            >
              <img src="assets/img/money.png" alt="" width={20} height={20} />
              <span>수익 인증</span>
            </button>
          </div>
        )
      }
    }
  }
  useEffect(() => {
    if (usageRequest && tab === PRODUCT_LONG_VIDEO) {
      setUsageList(usageRequest.data)
      setTotalItems(usageRequest.totalCount)
    }
    if (isShortError && tab === LINK_CONTENT_VIDEO) {
      setUsageList([])
      setTotalItems(0)
    }
    if (shortUsageRequest && tab === LINK_CONTENT_VIDEO) {
      setUsageList(shortUsageRequest.data)
      setTotalItems(shortUsageRequest.totalCount)
    }
    if (isError && tab === PRODUCT_LONG_VIDEO) {
      setUsageList([])
      setTotalItems(0)
    }
    if (shortUsageRequest && tab === PRODUCT_SHORT_VIDEO) {
      setUsageList(shortUsageRequest.data)
      setTotalItems(shortUsageRequest.totalCount)
    }
    if (isShortError && tab === PRODUCT_SHORT_VIDEO) {
      setUsageList([])
      setTotalItems(0)
    }
    if (textContentUsageRequest && tab === TEXT_CONTENT_VIDEO) {
      setUsageList(textContentUsageRequest.data)
      setTotalItems(textContentUsageRequest.totalCount)
    }
    if (isTextContentError && tab === TEXT_CONTENT_VIDEO) {
      setUsageList([])
      setTotalItems(0)
    }
  }, [usageRequest, shortUsageRequest, textContentUsageRequest, tab])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const page = parseInt(params.get('page') || '', 10)
    if (page && tab === PRODUCT_LONG_VIDEO) {
      setCurrentPage(page)
      usageRefetch()
    } else if (page && tab === LINK_CONTENT_VIDEO) {
      setCurrentPage(page)
      shortUsageRefetch()
    } else if (page && tab === PRODUCT_SHORT_VIDEO) {
      setCurrentPage(page)
      shortUsageRefetch()
    } else if (page && tab === TEXT_CONTENT_VIDEO) {
      setCurrentPage(page)
      textContentUsageRefetch()
    }
  }, [location.search])

  useEffect(() => {
    let page = 1
    params.set('type', checkParamType(tab))
    params.set('page', page.toString())
    params.set('pageSize', itemsPerPage.toString())
    navigate({ search: params.toString() })
  }, [tab])
  return (
    <div className=" md:w-[816px] w-[375px] min-h-[100vh] my-0 mx-auto flex flex-col items-center px-4">
      <div className="md:w-[816px] w-[343px] flex flex-col gap-6 ">
        {!isMobile && (
          <My>
            <p>사용 현황</p>
          </My>
        )}
        <div className="flex justify-center ">
          <p className="font-bold text-[24px] md:text-[40px]">사용 현황</p>
        </div>
        <div className="my-videos flex flex-col  md:w-[816px] w-[343px] tab ">
          <div className="flex md:flex-row  flex-col-reverse  text-[18px] items-end md:items-center ">
            <div className="flex mt-6 md:mt-0 w-full ">
              <button
                className={classNames(
                  'md:w-[200px] w-[171.5px] h-[54px] flex items-center justify-center  rounded-t-[20px]',
                  {
                    'bg-[#F6F8FB]': dashboardTab === PRODUCT_LONG_VIDEO,
                    'bg-[#EEF1F7] text-[#B6BBC6]':
                      dashboardTab !== PRODUCT_LONG_VIDEO,
                  }
                )}
                onClick={() => {
                  handleDashboardTab(PRODUCT_LONG_VIDEO)
                }}
              >
                <p className="font-bold ">상품 영상</p>
              </button>
              {/* <button
                className={classNames(
                  'md:w-[200px] w-[171.5px] h-[54px] flex items-center justify-center  rounded-tr-[20px]',
                  {
                    'bg-[#F6F8FB]': dashboardTab === LINK_CONTENT_VIDEO,
                    'bg-[#EEF1F7] text-[#B6BBC6]':
                      dashboardTab !== LINK_CONTENT_VIDEO,
                  }
                )}
                onClick={() => {
                  handleDashboardTab(LINK_CONTENT_VIDEO)
                }}
              >
                <p className="font-bold ">컨텐츠 영상</p>
              </button> */}
            </div>
            <div className="flex flex-col md:flex-row md:ml-2 md:items-start md:justify-start items-center justify-center w-full  gap-[6px]">
              {handleChallengeBtn()}
              {dashboardTab === PRODUCT_LONG_VIDEO && (
                <>
                  {' '}
                  {showYoutubeAuth(
                    userInfo,
                    'flex justify-center items-center md:w-[248px] md:h-[36px] w-[343px] h-[46px] bg-error text-white font-bold text-[14px] rounded-[40px]'
                  )}
                </>
              )}
              {dashboardTab === PRODUCT_LONG_VIDEO && isMobile && (
                <>
                  <PromotionCircle
                    userInfo={userInfo}
                    planId={userInfo?.subscriptionType?.planId}
                    classNameProps="w-full h-[46px]  rounded-full text-white font-bold"
                  />
                </>
              )}
            </div>
          </div>
          <div className=" rounded-b-[20px] md:rounded-tr-[20px] bg-[#F6F8FB] md:px-10 md:py-8 p-[10px] flex flex-col md:flex-row w-full gap-4 md:justify-between relative">
            {chartElByTab()}
          </div>
        </div>
        {dashboardTab === PRODUCT_LONG_VIDEO && !isMobile && (
          <>
            <div className="flex justify-center">
              <PromotionCircle
                userInfo={userInfo}
                planId={userInfo?.subscriptionType?.planId}
                classNameProps="w-full h-[46px]  rounded-full text-white font-bold"
              />
            </div>
          </>
        )}
        <div className="list flex flex-col w-full">
          <div className="flex mt-6 md:mt-0 md:text-base text-[14px] ">
            <button
              className={classNames(
                'md:w-[140px] w-[171.5px] h-[54px] flex items-center justify-center  rounded-tl-[20px]',
                {
                  'border-t-[1px] border-l-[1px]': tab === PRODUCT_LONG_VIDEO,
                  'bg-[#EEF1F7] text-[#B6BBC6]': tab !== PRODUCT_LONG_VIDEO,
                }
              )}
              onClick={() => {
                handleTab(PRODUCT_LONG_VIDEO)
              }}
            >
              <p className="font-bold ">상품 롱폼</p>
            </button>
            <button
              className={classNames(
                'md:w-[140px] w-[171.5px] h-[54px] flex items-center justify-center  ',
                {
                  'border-t-[1px] border-l-[1px]': tab === PRODUCT_SHORT_VIDEO,
                  'bg-[#EEF1F7] text-[#B6BBC6]': tab !== PRODUCT_SHORT_VIDEO,
                }
              )}
              onClick={() => {
                handleTab(PRODUCT_SHORT_VIDEO)
              }}
            >
              <p className="font-bold ">상품 숏폼</p>
            </button>
            <button
              className={classNames(
                'md:w-[140px] w-[171.5px] h-[54px] flex items-center justify-center ',
                {
                  'border-t-[1px] border-r-[1px]': tab === LINK_CONTENT_VIDEO,
                  'bg-[#EEF1F7] text-[#B6BBC6]': tab !== LINK_CONTENT_VIDEO,
                }
              )}
              onClick={() => {
                handleTab(LINK_CONTENT_VIDEO)
              }}
            >
              <p className="font-bold ">링크 컨텐츠</p>
            </button>
            <button
              className={classNames(
                'md:w-[140px] w-[171.5px] h-[54px] flex items-center justify-center  rounded-tr-[20px]',
                {
                  'border-t-[1px] border-r-[1px]': tab === TEXT_CONTENT_VIDEO,
                  'bg-[#EEF1F7] text-[#B6BBC6]': tab !== TEXT_CONTENT_VIDEO,
                }
              )}
              onClick={() => {
                handleTab(TEXT_CONTENT_VIDEO)
              }}
            >
              <p className="font-bold ">텍스트 컨텐츠</p>
            </button>
          </div>
          <div>{createTable()}</div>
        </div>
        <div className="">
          {' '}
          <ul className="list-disc pl-8 text-left mt-4"> {createLIEl()}</ul>
        </div>
        <div className="flex justify-center">
          <GuideToUse />
        </div>
        <AuthSaveLinkModal
          linkSaveModal={linkSaveModal}
          setLinkSaveModal={setLinkSaveModal}
        />
        <RevenueModal
          showRevenueModal={showRevenueModal}
          setShowRevenueModal={setShowRevenueModal}
        />
        <FailInfoModal
          failInfoModal={failInfoModal}
          setFailInfoModal={setFailInfoModal}
          errorText={errorText}
        />
        {isPlanPriceModal && (
          <PlanPriceModal
            isPlanPriceModal={isPlanPriceModal}
            setPlanPriceModal={setPlanPriceModal}
            userInfo={userInfo}
          />
        )}
      </div>
    </div>
  )
}

const AuthSaveLinkModal = (props: any) => {
  const { linkSaveModal, setLinkSaveModal } = props

  const [challenge, setChallenge] = useState<ChallengeType | null>(null)
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      link: '',
    },
  })
  const [text, setText] = useState<string>('저장')
  const { data, isPending: getChallengePending, refetch } = useGetChallenge()
  const { mutate: updateMutate, isPending: updatePending } =
    useUpdateChallenge()
  const { mutate: createMutate, isPending: createPending } =
    useCreateChallenge()
  const handleCreateChallenge = (data: any) => {
    const { link } = data
    if (challenge) {
      updateMutate(
        { link, id: challenge.id },
        {
          onSuccess: () => {
            setText('저장됨')
            refetch()
          },
          onError: () => {
            console.log('challenge 업데이트 실패')
          },
        }
      )
    } else {
      createMutate(data, {
        onSuccess: () => {
          setText('저장됨')
          refetch()
        },
        onError: () => {
          console.log('challenge 생성 실패')
        },
      })
    }
  }

  useEffect(() => {
    if (data) {
      setChallenge(data)
      setText('저장됨')
      setValue('link', data.link)
    }
  }, [data, linkSaveModal])

  return (
    <>
      {' '}
      {linkSaveModal && (
        <Modal isModal={linkSaveModal}>
          <form
            onSubmit={handleSubmit(handleCreateChallenge, (err) => {
              console.log(err)
            })}
          >
            <div className="bg-white w-[330px] px-5 pt-8 border-t rounded-t-[20px] flex flex-col gap-6 overflow-auto h-[500px]">
              <div className="flex flex-col gap-6">
                <p className="font-bold text-[18px]">챌린지 참여 선언</p>
                <div>
                  <p className="text-left">
                    유튜브 영상 링크나 블로그 포스트 링크를 입력하고
                    저장해주세요.
                  </p>
                  <p className="text-left text-error">
                    참여 선언을 완료해야 수익인증 기능이 활성화 됩니다.
                  </p>
                </div>
              </div>
              <div className="h-auto pb-8">
                <div className=" relative">
                  <Controller
                    render={({ field: { onBlur, value, ...field } }) => (
                      <>
                        <TextAreaMyVideo
                          value={value}
                          {...field}
                          placeholder="참여선언 한 블로그나 유튜브 링크 입력"
                          className={classNames('bg-[#F6F8FB]', {
                            'text-[#8F929B]': text === '저장됨',
                          })}
                          disabled={createPending || text === '저장됨'}
                        />
                      </>
                    )}
                    name={'link'}
                    rules={{
                      required: {
                        value: true,
                        message: 'Link 입력은 필수입니다!',
                      },
                    }}
                    control={control}
                  />
                  <div className="flex gap-[16px] absolute right-2 -bottom-14 top-0 items-center ">
                    <button
                      type="button"
                      onClick={() => {
                        setValue('link', '')
                        setText('적용')
                      }}
                      disabled={createPending}
                    >
                      <img
                        className="w-[32x] h-[32px]"
                        src="assets/img/icon_circle_x.png"
                        alt="x"
                      />
                    </button>
                    <button
                      className={classNames(
                        'bg-[#6B8ACB] hover:bg-[#8FABE5]  text-white   w-[76px] h-[43px]   flex items-center justify-center rounded-[10px] font-bold disabled:opacity-[0.3]',
                        {
                          'active:scale-95': text === '적용',
                        }
                      )}
                      disabled={createPending || text === '저장됨'}
                    >
                      {text}
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <img src="assets/img/challenge/3step.png" alt="" />
              </div>
            </div>
            <div className="w-[330px] text-[14px]  h-[60px]  flex justify-center items-center font-bold">
              <button
                className=" w-[50%] h-full text-black bg-[#EEF1F7] rounded-bl-[20px] border-b border-[#EEF1F7] "
                onClick={() => {
                  setLinkSaveModal(false)
                }}
                disabled={createPending}
                type="button"
              >
                취소
              </button>
              <button
                className="text-white w-[50%] h-full bg-main rounded-br-[20px] border-b border-main"
                onClick={() => {
                  setLinkSaveModal(false)
                }}
                disabled={createPending}
                type="button"
              >
                확인
              </button>
            </div>
          </form>
        </Modal>
      )}
    </>
  )
}

const FailInfoModal = (props: any) => {
  const { failInfoModal, setFailInfoModal, errorText } = props

  return (
    <>
      {' '}
      {failInfoModal && (
        <Modal isModal={failInfoModal}>
          <div className="bg-white w-[400px] h-[230px] p-[20px] border-t rounded-t-[20px]">
            <div className="flex flex-col items-center justify-center h-full gap-[24px]">
              <p className="font-bold text-[18px]">{errorText.title}</p>
              <div className="flex flex-col leading-[22px]">
                <p className=" text-[14px] ">{errorText.subtitle}</p>
              </div>
            </div>
          </div>
          <div className="w-[400px] text-[14px]  h-[60px]  flex justify-center items-center">
            <button
              className="text-white w-[100%] h-full bg-main rounded-b-[20px] border-b border-main font-bold"
              onClick={() => {
                setFailInfoModal((prev: any) => !prev)
              }}
            >
              확인
            </button>
          </div>
        </Modal>
      )}
    </>
  )
}

const RevenueModal = (props: any) => {
  const [challenge, setChallenge] = useState<ChallengeType | null>(null)
  const [text, setText] = useState<string>('저장')
  const [day, setDay] = useState<number>(new Date().getDate())
  const [isOpenDay, setOpenDay] = useState<boolean>(false)

  const { mutate: updateMutate, isPending: updatePending } =
    useUpdateChallenge()
  const { mutate: createMutate, isPending: createPending } =
    useCreateChallenge()
  const handleCreateChallenge = () => {
    const link = watch('link')
    if (challenge) {
      updateMutate(
        { link, id: challenge.id },
        {
          onSuccess: () => {
            setText('저장됨')
            refetch()
          },
          onError: () => {
            console.log('challenge 업데이트 실패')
          },
        }
      )
    } else {
      createMutate(data, {
        onSuccess: () => {
          setText('저장됨')
          refetch()
        },
        onError: () => {
          console.log('challenge 생성 실패')
        },
      })
    }
  }

  const { showRevenueModal, setShowRevenueModal } = props

  const [textList, setTextList] = useState(new Array(18).fill('적용'))
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      link: '',
      challengeDetail: [
        { id: 0, link: '' },
        { id: 0, link: '' },
        { id: 0, link: '' },
        { id: 0, link: '' },
        { id: 0, link: '' },
        { id: 0, link: '' },
        { id: 0, link: '' },
        { id: 0, link: '' },
        { id: 0, link: '' },
        { id: 0, link: '' },
        { id: 0, link: '' },
        { id: 0, link: '' },
        { id: 0, link: '' },
        { id: 0, link: '' },
        { id: 0, link: '' },
        { id: 0, link: '' },
        { id: 0, link: '' },
        { id: 0, link: '' },
      ],
    },
  })
  const { data, isPending: getChallengePending, refetch } = useGetChallenge()

  const { mutate, isPending } = useInsertLinkChallenge()
  const handleUpdateChallengeDetail = (index: number) => {
    if (day === 1 || day === 15) {
    } else {
      // 1, 15일 아니면 안된다고 모달을 띄워야 함
      setOpenDay(true)
      return
    }
    const link = watch(`challengeDetail.${index}.link`)
    const id = watch(`challengeDetail.${index}.id`)

    mutate(
      { challengeDetailId: id, link },
      {
        onSuccess: () => {
          refetch()
          console.log('challenge detail 업데이트 성공')
        },
        onError: () => {
          console.log('challenge detail 업데이트 실패')
        },
      }
    )
  }
  const handleTextList = (index: number) => {
    const updateTextList = textList.map((el, i) =>
      i === index ? '적용' : textList[i]
    )

    setTextList(updateTextList)
  }

  useEffect(() => {
    if (data) {
      setChallenge(data)
      setValue('link', data.link)
      setText('저장됨')
      data.challengeDetail.forEach((el, index) => {
        if (el.link) {
          const updateList = textList
          updateList[index] = '저장됨'

          setTextList(updateList)
        }
        setValue(`challengeDetail.${index}.link`, el.link)
        setValue(`challengeDetail.${index}.id`, el.id)
      })
    }
  }, [data, showRevenueModal])

  return (
    <>
      {' '}
      {showRevenueModal && (
        <Modal isModal={showRevenueModal}>
          <div className="bg-white w-[330px] px-5 pt-8 border-t rounded-t-[20px] flex flex-col gap-4 md:max-h-[600px] max-h-[600px]  overflow-y-scroll ">
            <div className="flex flex-col gap-6">
              <p className="font-bold text-[18px]">수익 인증</p>
              <div>
                <p className="text-left ">
                  유튜브 영상 링크나 블로그 포스트 링크를 입력
                </p>
                <p className="text-left text-error">
                  참여 선언을 완료해야 수익인증 기능이 활성화 됩니다.
                </p>

                <div className="flex flex-col items-start bg-[#2E343D] p-[10px] rounded-[10px] mt-2">
                  {' '}
                  <p className="text-main font-bold">💡 꼭 지켜주세요!</p>
                  <ul className=" list-disc flex flex-col items-start text-white ml-6">
                    <li>3라운드까지 미션 성공 후 4라운드부터</li>
                    <li className="text-error">
                      매월 1일 / 15일에 작성 후 링크 저장
                    </li>
                    <li>챌린지 페이지의 수익인증 가이드 준수</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="h-auto  flex flex-col gap-6">
              {challenge?.challengeDetail.map((el, index) => {
                return (
                  <div className="flex flex-col items-start relative gap-2">
                    <p>
                      {' '}
                      <span className="font-bold">인증 {index + 1}</span>{' '}
                      <span>(1일, 15일 작성/저장만 유효)</span>
                    </p>

                    <Controller
                      render={({ field: { onBlur, value, ...field } }) => (
                        <>
                          <TextAreaMyVideo
                            value={value}
                            {...field}
                            placeholder="수익인증 한 블로그나 유튜브 링크 입력 해당 라운드 1일, 15일 작성/저장만 유효"
                            className={classNames('bg-[#F6F8FB]', {
                              'text-[#8F929B]': textList[index] === '저장됨',
                            })}
                            disabled={isPending || textList[index] === '저장됨'}
                          />
                        </>
                      )}
                      name={`challengeDetail.${index}.link`}
                      control={control}
                    />
                    <div className="flex gap-[16px] absolute right-2 -bottom-24 top-0 items-center ">
                      <button
                        type="button"
                        onClick={() => {
                          setValue(`challengeDetail.${index}.link`, '')
                          handleTextList(index)
                        }}
                        disabled={isPending}
                      >
                        <img
                          className="w-[32x] h-[32px]"
                          src="assets/img/icon_circle_x.png"
                          alt="x"
                        />
                      </button>
                      <button
                        className={classNames(
                          'bg-[#6B8ACB] hover:bg-[#8FABE5] text-white  w-[74px] h-[43px]   flex items-center justify-center rounded-[10px] font-bold disabled:opacity-[0.3]',
                          {
                            ' active:scale-95': textList[index] === '적용',
                          }
                        )}
                        disabled={isPending || textList[index] === '저장됨'}
                        onClick={() => {
                          handleUpdateChallengeDetail(index)
                        }}
                      >
                        {textList[index]}
                      </button>
                    </div>
                  </div>
                )
              })}

              {/* 참여 선언 */}
              <div className=" relative flex flex-col">
                <span className="font-bold text-left mb-1">
                  챌린지 참여 선언
                </span>
                <Controller
                  render={({ field: { onBlur, value, ...field } }) => (
                    <>
                      <TextAreaMyVideo
                        value={value}
                        {...field}
                        placeholder="참여선언 한 블로그나 유튜브 링크 입력"
                        className={classNames('bg-[#F6F8FB]', {
                          'text-[#8F929B]': text === '저장됨',
                        })}
                        disabled={createPending || text === '저장됨'}
                      />
                    </>
                  )}
                  name={'link'}
                  rules={{
                    required: {
                      value: true,
                      message: 'Link 입력은 필수입니다!',
                    },
                  }}
                  control={control}
                />
                <div className="flex gap-[16px] absolute right-2 -bottom-14 top-0 items-center ">
                  <button
                    type="button"
                    onClick={() => {
                      setValue('link', '')
                      setText('적용')
                    }}
                    disabled={createPending}
                  >
                    <img
                      className="w-[32x] h-[32px]"
                      src="assets/img/icon_circle_x.png"
                      alt="x"
                    />
                  </button>
                  <button
                    className={classNames(
                      'bg-[#6B8ACB] hover:bg-[#8FABE5]  text-white   w-[76px] h-[43px]   flex items-center justify-center rounded-[10px] font-bold disabled:opacity-[0.3]',
                      {
                        'active:scale-95': text === '적용',
                      }
                    )}
                    disabled={createPending || text === '저장됨'}
                    onClick={handleCreateChallenge}
                  >
                    {text}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[330px] text-[14px]  h-[60px]  flex justify-center items-center font-bold">
            <button
              className=" w-[50%] h-full text-black bg-[#EEF1F7] rounded-bl-[20px] border-b border-[#EEF1F7] "
              onClick={() => {
                setShowRevenueModal(false)
              }}
              disabled={isPending}
              type="button"
            >
              취소
            </button>
            <button
              className="text-white w-[50%] h-full bg-main rounded-br-[20px] border-b border-main font-bold"
              onClick={() => {
                setShowRevenueModal(false)
              }}
              disabled={isPending}
              type="button"
            >
              확인
            </button>
          </div>
        </Modal>
      )}
      <Modal isModal={isOpenDay}>
        <div className="bg-white w-[335px] px-5 pt-8 border-t rounded-t-[40px] flex flex-col gap-4 md:max-h-[600px] max-h-[600px]  overflow-y-scroll ">
          <div className="flex flex-col gap-4">
            <p className="font-bold text-[18px]">
              🧑‍💻 오늘은 수익 인증일이 아니에요!
            </p>
            <p className="text-[14px] pb-8">
              수익 인증은{' '}
              <span className="text-error font-bold">
                매 월 1일에 한 번, 15일에 한 번<br />
              </span>{' '}
              총 2회 해주세요
            </p>
          </div>
        </div>
        <div className="w-[335px] text-[14px]  h-[60px]  flex justify-center items-center font-bold">
          <button
            className="text-white w-[100%] h-full bg-main rounded-b-[40px] border-b border-main font-bold"
            onClick={() => {
              setOpenDay(false)
            }}
            disabled={isPending}
            type="button"
          >
            확인
          </button>
        </div>
      </Modal>
    </>
  )
}
