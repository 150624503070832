import { useQuery } from '@tanstack/react-query'
import { getPlans, getPlansPrice } from '../../../service/plans'

function useGetPlansPrice(subscriptionType: string) {
  return useQuery({
    queryKey: [`get-plans-price-${subscriptionType}`],
    queryFn: () => getPlansPrice(subscriptionType),
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 0,
  })
}

export default useGetPlansPrice
