import { Input } from 'components/atom/Input'
import { TextArea } from 'components/atom/TextArea'
import React, { CSSProperties, Dispatch, PropsWithChildren } from 'react'
import { isMobile } from 'react-device-detect'
import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { CSSTransition } from 'react-transition-group'
import { LinkDetailType } from 'service/list'
import { Me, Usage } from 'service/user'
import { UserType } from 'utils/common'
import { FormValues } from 'utils/type'

interface ProductListV1Props {
  watch: UseFormWatch<FormValues>
  setValue: UseFormSetValue<FormValues>
  control: Control<FormValues, any>
  fields: FieldArrayWithId<FormValues, 'products', 'id'>[]
  errors: FieldErrors<FormValues>
  setErrorText: Dispatch<React.SetStateAction<string>>
  userInfo: Me
  usage: Usage | undefined
  error: {
    products: {
      link: boolean
      product: boolean
    }[]
    mainTitle: boolean
    thumbnail: boolean
  }
  setError: any
  isImageDivOpen: boolean[]
  setImageDivOpen: Dispatch<React.SetStateAction<boolean[]>>
  productsRef: React.MutableRefObject<HTMLInputElement | null>
  setTooltipStatus: Dispatch<React.SetStateAction<boolean>>
  tooltipStatus: boolean
}
export const ProductListV1 = ({
  watch,
  setValue,
  control,
  fields,
  errors,
  setErrorText,
  userInfo,
  usage,
  error,
  setError,
  isImageDivOpen,
  setImageDivOpen,
  productsRef,
  setTooltipStatus,
  tooltipStatus,
}: PropsWithChildren<ProductListV1Props>) => {
  const PRODUCT_NAME_PATTERN = /^[^<>\uD800-\uDFFF]*$/
  const URL_PATTERN =
    /^https:\/\/(s\.click\.aliexpress\.com|link\.coupang\.com\/a)/
  const getGridItemStyle = (selected: boolean): CSSProperties => ({
    width: '130px',
    height: '130px',
    position: 'relative',
    borderRadius: '4px',
    border: '1px solid #D9DEE8',
    overflow: 'hidden', // 이미지가 부모의 경계를 넘어가지 않도록 설정
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FFFFFF',
    opacity: selected ? 1 : 0.3, // 선택되지 않은 경우 투명도 조절
  })

  // @ts-ignore
  const styles: { [key: string]: CSSProperties } = {
    input: {
      width: '100%',
      padding: '10px',
      marginBottom: '10px',
      borderRadius: '4px',
      border: '1px solid #ccc',
    },
    gridContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)',
    },
    gridContainerMobile: {
      gridTemplateColumns: 'repeat(3, 1fr)', // 각 아이템의 너비를 유지하면서 자동으로 간격 조정
      justifyItems: 'center',
    },
    gridItemMobile: {
      width: '93px',
      height: '93px',
    },
    image: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      objectFit: 'contain', // 비율을 유지하며 부모를 꽉 채우도록 설정
    },
    checkboxContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    checkbox: {
      marginRight: '8px',
      width: '16px',
      height: '16px',
    },
    label: {
      fontSize: '16px',
      color: '#333',
    },
  }

  const handleImageDivOpen = (index: number) => {
    // 해당되는 index의 bool을 교체해줘야해
    const updateImageDivOpen = isImageDivOpen.map((el, idx) =>
      idx === index ? !el : el
    )
    setImageDivOpen(updateImageDivOpen)
  }

  const handleUploadCheckboxChange = (
    productIndex: number,
    fileIndex: number
  ) => {
    const currentFiles = watch(`products.${productIndex}.files`, [])

    const currentSelectedFiles = watch(
      `products.${productIndex}.selectedFiles`,
      []
    )

    const fileToToggle = currentFiles[fileIndex]
    const isSelected = currentSelectedFiles.includes(fileToToggle)

    if (isSelected) {
      // 이미 선택된 파일을 선택 해제
      setValue(
        `products.${productIndex}.selectedFiles`,
        currentSelectedFiles.filter((file) => file !== fileToToggle)
      )
    } else {
      // 선택된 파일이 3개 이상이면 추가 선택을 막습니다.
      if (currentSelectedFiles.length >= 3) return

      // 선택되지 않은 파일을 선택
      setValue(`products.${productIndex}.selectedFiles`, [
        ...currentSelectedFiles,
        fileToToggle,
      ])
    }
  }
  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: any
  ) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files)
      // 업로드한 파일을 products[index].files에 추가
      // @ts-ignore
      setValue(`products.${index}.files`, [
        ...(watch(`products.${index}.files`) ?? []),
        ...selectedFiles,
      ])

      // 현재 선택된 파일 배열 가져오기
      const prevSelected = watch(`products.${index}.selectedFiles`, [])
      const additionalSelectionCount = selectedFiles.length
      const currentSelectionCount = prevSelected ? prevSelected.length : 0

      // 선택된 파일 상태 업데이트 (최대 3개만 선택 가능)
      if (currentSelectionCount + additionalSelectionCount > 3) {
        const allowedSelectionCount = 3 - currentSelectionCount
        // @ts-ignore
        setValue(`products.${index}.selectedFiles`, [
          ...prevSelected,
          ...selectedFiles.slice(0, allowedSelectionCount),
        ])
      } else {
        // @ts-ignore
        setValue(`products.${index}.selectedFiles`, [
          ...prevSelected,
          ...selectedFiles,
        ])
      }
    }
  }

  return (
    <div className="flex flex-col items-center w-[375px] md:w-[736px] gap-6 ">
      {fields.map((item, index) => (
        <>
          <div
            className="flex flex-col items-center w-[375px] md:w-full gap-1"
            key={index}
          >
            <div
              key={index}
              className="w-[343px] md:w-full flex justify-between"
            >
              <p className="text-[18px] font-bold">상품 {index + 1}</p>
            </div>
            <div className="relative flex flex-col">
              <Controller
                key={`link_${index}`}
                render={({ field }) =>
                  isMobile ? (
                    <>
                      {' '}
                      <TextArea
                        inputRef={index === 0 ? productsRef : null}
                        disabled={
                          usage &&
                          !usage?.available &&
                          userInfo?.userType !== UserType.체험
                        }
                        error={error.products[index].link}
                        {...field}
                        placeholder="쿠팡파트너스 또는 알리어필리에이트 링크 Ctrl+c, Ctrl+v 로 붙여넣기"
                        style={{
                          borderColor:
                            errors.products &&
                            errors.products[index] &&
                            errors.products[index]?.link?.message ===
                              'invalid' &&
                            'red',
                        }}
                      />
                    </>
                  ) : (
                    <Input
                      inputRef={index === 0 ? productsRef : null}
                      disabled={
                        usage &&
                        !usage?.available &&
                        userInfo?.userType !== UserType.체험
                      }
                      {...field}
                      placeholder="쿠팡파트너스 또는 알리어필리에이트 링크 Ctrl+c, Ctrl+v 로 붙여넣기"
                      error={error.products[index].link}
                    />
                  )
                }
                name={`products.${index}.link`}
                control={control}
                rules={{
                  required:
                    (index === 0 || watch(`products.${index}.productName`)) !==
                    ''
                      ? 'required'
                      : false,
                  validate:
                    index === 0 || watch(`products.${index}.productName`) !== ''
                      ? (value) => {
                          const updateError = { ...error }
                          updateError.products[index].link = false
                          setError(updateError)
                          return URL_PATTERN.test(value) || `invalid`
                        }
                      : undefined,
                }}
              />
              {errors.products &&
                errors.products[index]?.link &&
                errors.products[index]?.link?.message === 'invalid' && (
                  <span className="text-error">유효하지 않은 링크입니다!</span>
                )}
              {index === 0 && (
                <>
                  <img
                    src="assets/img/icon_tooltip.png"
                    alt="툴팁"
                    className="absolute right-5 top-[24px]  w-[24px] h-[24px]"
                    onMouseOver={() => {
                      setTooltipStatus(true)
                    }}
                  />
                  {tooltipStatus && (
                    <div
                      className="flex flex-col p-[16px] md:p-[32px] gap-[10px] bg-white w-[200px] md:w-[360px] h-auto absolute border-[3px] border-main  rounded-[40px] bottom-[50px] right-0"
                      onMouseLeave={() => {
                        setTooltipStatus(false)
                      }}
                    >
                      <p className=" md:leading-[19.2px] md:text-base  leading-[12px] text-[10px]">
                        <span className="font-bold">
                          쿠팡파트너스? 알리어필리에이트?
                        </span>
                        <br />위 서비스들의 계정을 생성하셔야, 제휴마케팅
                        리워드를 받을 수 있는 상품 링크를 복사할 수 있어요!
                        <br />
                        아직 만들지 않으셨다면,
                        <br />
                        패스트컷의 가이드를 보고 쉽게 만들어보세요!
                      </p>
                      <p className="flex gap-2 md:text-base text-[10px]">
                        <img
                          src="assets/img/coupang_icon.png"
                          alt="coupang"
                          width={24}
                          height={24}
                          className="md:w-[24px] md:h-[24px] w-[16px] h-[16px]"
                        />
                        <a
                          href="https://blog.fastseller.shop/coupangpartnersguide/"
                          target="_blank"
                          rel="noreferrer"
                          className="md:text-base text-[10px]"
                        >
                          {' '}
                          {`쿠팡 파트너스 가이드 >`}
                        </a>
                      </p>
                      <p className="flex gap-2 md:text-base text-[10px]">
                        <img
                          src="assets/img/ali_icon.png"
                          alt="ali"
                          className="md:w-[24px] md:h-[24px] w-[16px] h-[16px]"
                        />
                        <a
                          href="https://blog.fastseller.shop/aliexpressaffiliatteguide/"
                          target="_blank"
                          rel="noreferrer"
                        >{`알리익스프레스 어필리에이트 가이드 >`}</a>
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="product_title">
              <Controller
                key={`productName_${index}`}
                render={({ field }) => (
                  <>
                    {isMobile ? (
                      <>
                        {' '}
                        <TextArea
                          error={error.products[index].product}
                          disabled={
                            usage &&
                            !usage?.available &&
                            userInfo?.userType !== UserType.체험
                          }
                          {...field}
                          placeholder="영상에 표기될 상품명을 깔끔하게 정리해서 적어주세요! (50자 이하)"
                          maxLength={100}
                        />
                      </>
                    ) : (
                      <Input
                        error={error.products[index].product}
                        disabled={
                          usage &&
                          !usage?.available &&
                          userInfo?.userType !== UserType.체험
                        }
                        {...field}
                        placeholder="영상에 표기될 상품명을 깔끔하게 정리해서 적어주세요! (50자 이하)"
                        maxLength={100}
                      />
                    )}

                    <div
                      className={`flex gap-[4px] mt-1 md:flex-row flex-col  ${
                        (field.value.length > 50 ||
                          !PRODUCT_NAME_PATTERN.test(field.value)) &&
                        'text-error'
                      }`}
                    >
                      <span>{field.value.length}/50</span>
                      <span>{`이모지와 꺽쇠괄호(< >)는 허용되지 않습니다.`}</span>
                      {errors.products &&
                        errors.products[index]?.productName && (
                          <span className="text-error ml-[10px]">
                            {errors.products[index]?.productName?.type ===
                              'required' && '상품명을 입력해주세요.'}
                          </span>
                        )}
                    </div>
                  </>
                )}
                name={`products.${index}.productName`}
                control={control}
                rules={{
                  required:
                    (index === 0 || watch(`products.${index}.productName`)) !==
                    ''
                      ? 'required'
                      : false,
                  validate:
                    index === 0 || watch(`products.${index}.productName`) !== ''
                      ? (value) => {
                          const updateError = { ...error }
                          updateError.products[index].product = false
                          setError(updateError)
                          return (
                            PRODUCT_NAME_PATTERN.test(value) ||
                            '이모지와 꺽쇠괄호(< >)는 허용되지 않습니다.'
                          )
                        }
                      : undefined,
                  maxLength: 50,
                }}
              />
            </div>
            <div className="flex justify-between md:w-full w-[343px]  bg-[#EEF1F7] items-center px-5 py-[13px] rounded-[10px]">
              <p className="font-bold text-[14px]">
                [선택] 이미지 업로드{' '}
                {
                  watch(`products.${index}.selectedFiles`, []).filter(
                    (image) => image
                  ).length
                }
                /3
                <span className="font-[400]">
                  {isMobile ? <> (최대 3장)</> : <> (최대 3장 선택 가능)</>}
                </span>
              </p>
              <button
                className="flex items-center justify-center gap-[8px] w-[65px] h-[44px] md:text-base text-[14px] "
                type="button"
                onClick={() => {
                  handleImageDivOpen(index)
                }}
              >
                <span>{isImageDivOpen[index] ? '접기' : '펼치기'}</span>
                <img
                  src="assets/img/icon_up_arrow.png"
                  alt="arrow"
                  className={`w-[12.8px] h-[8.25px]  transition-transform duration-500 ${
                    isImageDivOpen[index] ? '' : 'rotate-180'
                  }`}
                />
              </button>
            </div>
            <CSSTransition
              in={isImageDivOpen[index]}
              timeout={200}
              classNames="content"
              unmountOnExit
            >
              <div
                style={{
                  width: '100%',
                  background: '#F6F8FB',
                  borderRadius: '10px',
                  padding: '20px',
                  maxHeight: 'calc(2 * 230px)', // 2줄까지만 보이도록 높이 제한 (2줄 높이 + 갭)
                  overflowY: 'auto', // 스크롤 가능하게 설정
                  scrollbarWidth: 'thin', // Firefox용 기본 스크롤바 폭 설정
                  scrollbarColor: '#888 #e0e0e0', // Firefox용 스크롤바 색상 설정
                }}
              >
                {index === 0 && (
                  <>
                    <p className="text-[14px] text-error mb-1">
                      상품 페이지 대표 이미지 외에 따로 이미지 추가를 원하시는
                      경우에만 업로드 해주세요.
                    </p>
                    <p className="text-[14px] text-error mb-1">
                      상품 페이지 영상이 없는 경우에만 적용되며, 상품 영상
                      생성시 업로드 이미지를 앞쪽에 배치합니다.
                    </p>
                  </>
                )}
                <div className="flex items-center space-x-2 mb-3">
                  <span className="text-[#8F929B] text-[16px] text-sm">
                    총 20MB | jpg, jpeg, png
                  </span>
                  <button
                    onClick={(event) => {
                      event.preventDefault() // 기본 form 제출 동작을 방지
                      document.getElementById(`file-input-${index}`)?.click() // 파일 선택 창 열기
                    }}
                    className="flex items-center space-x-1 px-3 py-2 text-white rounded-full bg-[#6B8ACB] text-[14px] font-bold"
                  >
                    <img src="assets/img/icon_image.png" alt="" width={17} />
                    <span>파일 업로드</span>
                  </button>
                  <input
                    id={`file-input-${index}`}
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    multiple
                    className="hidden"
                    onChange={(e) => handleFileChange(e, index)}
                  />
                </div>

                <div
                  style={{
                    ...styles.gridContainer,
                    ...(window.innerWidth <= 768
                      ? styles.gridContainerMobile
                      : {}),
                  }}
                >
                  {watch(`products.${index}.files`, []).map(
                    (file, fileIndex) => {
                      const selectedFiles = watch(
                        `products.${index}.selectedFiles`,
                        []
                      )
                      console.log('selectedFiles:::::', selectedFiles)
                      return (
                        <div style={{ marginBottom: '20px' }} key={fileIndex}>
                          <div
                            style={{
                              ...getGridItemStyle(
                                watch(
                                  `products.${index}.selectedFiles`,
                                  []
                                ).includes(file)
                              ),
                              ...(window.innerWidth <= 768
                                ? styles.gridItemMobile
                                : {}),
                            }}
                          >
                            <img
                              src={URL.createObjectURL(file)}
                              key={`Image ${fileIndex + 1}`}
                              alt=""
                              style={styles.image}
                            />
                          </div>
                          <div style={styles.checkboxContainer}>
                            <input
                              type="checkbox"
                              checked={watch(
                                `products.${index}.selectedFiles`,
                                []
                              ).includes(file)}
                              onChange={() =>
                                handleUploadCheckboxChange(index, fileIndex)
                              }
                              style={styles.checkbox}
                            />
                            <span style={styles.label}>
                              {String(fileIndex + 1).padStart(2, '0')}
                            </span>
                          </div>
                        </div>
                      )
                    }
                  )}
                </div>
              </div>
            </CSSTransition>
          </div>
        </>
      ))}
    </div>
  )
}
