import { useLocation, useNavigate } from 'react-router-dom'

import { useEffect, useState } from 'react'
import { useLogOut } from '../../hooks/query/useLogOut'
import { isMobile } from 'react-device-detect'
import useGetMe from '../../hooks/query/useGetMe'

import {
  GNB,
  getLocalStorage,
  onLogOut,
  removeLocalStorage,
  saveLocalStorage,
  UserType,
  PC_SELECT_BTN_LIST,
  MOBILE_SELECT_BTN_LIST,
  MOBILE_NO_USER_BTN_LIST,
} from '../../utils/common'
import { FreeButton } from '../atom/FreeButton'

import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'
import useUserStore from '../../store/useUserStore'
import usePrevStore from '../../store/usePrevStore'
import usePlanStore from '../../store/usePlanStore'
import useGetMePlan from '../../hooks/query/useGetMePlan'
const Header = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('token')
  const userType = urlParams.get('userType')
  const navigate = useNavigate()
  const sample = getLocalStorage('guide_sample')
  if (!sample) {
    saveLocalStorage('guide_sample', '1')
  }
  const location = useLocation()
  if (token) {
    localStorage.setItem('token', token)
    const searchParams = new URLSearchParams(location.search)
    searchParams.delete('token')
    const baseUrl = location.pathname
    const modifiedUrl = `${baseUrl}${
      searchParams.toString() ? '?' + searchParams.toString() : ''
    }`

    navigate(modifiedUrl)
  }

  const { pathname } = useLocation()
  const [logOutStatus, setLogOutStatus] = useState<boolean>(false)
  const [hambugerStatus, setHambugerStatus] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isPriceGuideOpen, setPiceGuideOpen] = useState(false)

  const { mutate: logoutMutate } = useLogOut()
  const set = useUserStore((state: any) => state.set)
  const prevSet = usePrevStore((state: any) => state.set)
  const setRefetch = useUserStore((state: any) => state.setRefetch)
  const setPlanRefetch = usePlanStore((state: any) => state.setRefetch)
  const setPlan = usePlanStore((state: any) => state.set)
  const { data: userData, refetch } = useGetMe()
  const { data: userPlanData, refetch: planRefetch } = useGetMePlan()

  set({ userInfo: userData })
  setPlan({ userPlanInfo: userPlanData })

  const moveToPageByPath = (path: string) => {
    setHambugerStatus(false)
    switch (path) {
      case 'sign-in':
        navigate('sign-in')
        break
      case 'sign-up':
        navigate('sign-up')
        break
      case 'video-editor':
        navigate('/video-editor')
        break
      case 'youtube-auth':
        navigate('/youtube-auth')
        break
      case 'my-payments':
        navigate('/my-payments')
        break
      case 'my-videos':
        navigate('/my-videos')
        break
      case 'price-guide':
        navigate('/price-guide')
        break
      case 'shortform-price-guide':
        navigate('/shortform-price-guide')
        break
      case 'faq':
        navigate('/QnA')
        break
      case 'short-video-editor':
        navigate('/short-video-editor')
        break
      case 'my-cash-dashboard':
        navigate('/my-cash-dashboard')
        break
      case 'event-page':
        navigate('/event-page')
        break
      case 'my-page':
        navigate('/my-page')
        break
      case 'challenge':
        navigate('/challenge')
        break
      case 'coupon-pck':
        navigate('coupon-pack')
        break
      default:
        navigate('/')
        break
    }
  }
  const handleHambugerIconClick = () => {
    setHambugerStatus(!hambugerStatus)
  }

  const createMobileBtn = () => {
    return (
      <>
        <div
          className="flex justify-center mb-[18px] mt-[30px] "
          onClick={handleHambugerIconClick}
        >
          <button>
            <img src="assets/img/Icon_x.png" alt="x" width={32} height={32} />
          </button>
        </div>

        <div className="flex flex-col items-center px-[26px] py-[8px]">
          {MOBILE_NO_USER_BTN_LIST.filter(
            (el) =>
              (userData && el.showForLoggedIn) ||
              (!userData && el.showForLoggedOut)
          ).map((el, index) => {
            if (el.path === 'price') {
              return (
                <div className={`flex  justify-center py-[16px] w-full gap-2`}>
                  <button
                    onClick={() => {
                      moveToPageByPath('price-guide')
                    }}
                  >
                    상품 영상 플랜 가격 안내
                  </button>
                  /
                  <button
                    onClick={() => {
                      moveToPageByPath('shortform-price-guide')
                    }}
                  >
                    컨텐츠 영상 가격 안내
                  </button>
                </div>
              )
            } else if (el.path === 'global') {
              return <></>
            } else {
              return (
                <>
                  <button
                    className=" py-[16px] w-full flex items-center  justify-center"
                    onClick={() => {
                      moveToPageByPath(el.path)
                    }}
                  >
                    <p>{el.name}</p>
                    {el.event && (
                      <p className="bg-[#FF8800] text-white p-1 font-bold text-[10px] rounded-[4px] ml-1 h-[20px] flex items-center">
                        <span>페이백</span>
                      </p>
                    )}
                  </button>
                </>
              )
            }
          })}

          <div className="h-[1px] w-full bg-[gray] mt-[16px] "></div>

          {userData?.userInfo ? (
            <div className="flex flex-col gap-[8px] mt-[16px] w-full items-center">
              {MOBILE_SELECT_BTN_LIST.map((el, inex) => {
                if (el.path === 'my-page') {
                  return (
                    <button
                      className="py-[16px] w-full  text-center"
                      onClick={() => {
                        moveToPageByPath('my-page')
                      }}
                    >
                      마이페이지{' '}
                      <span className="text-[#2E343D]  font-bold">{`(${userData?.userInfo?.name})`}</span>
                    </button>
                  )
                }
                if (el.path === 'blog') {
                  return (
                    <a
                      className="py-[16px] w-full text-center"
                      href="https://blog.fastseller.shop/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      블로그
                    </a>
                  )
                } else if (el.path === 'logout') {
                  return (
                    <button className="py-[16px] w-full" onClick={onLogOut}>
                      로그아웃
                    </button>
                  )
                } else if (
                  el.path === 'youtube-auth' &&
                  userData &&
                  (userData.userType === UserType.프로 ||
                    userData.userType === UserType.프리미엄 ||
                    userData.userType === UserType.체험)
                ) {
                  return (
                    <button
                      onClick={() => {
                        moveToPageByPath('youtube-auth')
                      }}
                      className="py-[16px] w-full"
                    >
                      자동 업로드 설정
                    </button>
                  )
                }
                return (
                  <button
                    className="py-[16px] w-full"
                    onClick={() => {
                      moveToPageByPath(el.path)
                    }}
                  >
                    {el.name}
                  </button>
                )
              })}
            </div>
          ) : (
            // 로그인 안되어 있을 때 버튼 리스트
            <div className="flex flex-col gap-[16px] mt-[32px] w-full items-center">
              <button
                className="text-[#2E343D] py-[13px] w-full "
                onClick={() => {
                  moveToPageByPath('sign-in')
                }}
              >
                로그인
              </button>
              <FreeButton
                className=" p-[20px] h-[48px] bg-href  text-white font-bold rounded-[30px] flex justify-center items-center"
                onClick={() => {
                  moveToPageByPath('sign-up')
                }}
              >
                가입하고 무료체험
              </FreeButton>
            </div>
          )}
        </div>
      </>
    )
    // 공통 요소
  }

  const createSelectBtn = () => {
    //우상단 선택 요소들
    return (
      <>
        <div
          className="flex flex-col bg-[#FFFFFF] w-[169px] h-auto justify-center items-center absolute right-[30px] border rounded-[10px] top-20 z-50 text-[14px]"
          onMouseLeave={() => setLogOutStatus(false)}
        >
          <div className="hover:bg-[#F6F6F6] w-full h-[46px]  flex items-center justify-center">
            <button
              onClick={() => {
                moveToPageByPath('my-page')
              }}
              className="w-full h-full"
            >
              마이페이지
            </button>
          </div>

          {PC_SELECT_BTN_LIST.map((el, inex) => {
            if (el.path === 'blog') {
              return (
                <div className="hover:bg-[#F6F6F6] w-full h-[46px]  flex items-center justify-center">
                  <a
                    className="py-[16px] w-full text-center"
                    href="https://blog.fastseller.shop/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    블로그
                  </a>
                </div>
              )
            } else if (el.path === 'logout') {
              return (
                <div className="hover:bg-[#F6F6F6] w-full h-[46px]  flex items-center justify-center">
                  <button onClick={onLogOut} className="w-full h-full">
                    로그아웃
                  </button>
                </div>
              )
            } else if (
              el.path === 'youtube-auth' &&
              userData &&
              (userData.userType === UserType.프로 ||
                userData.userType === UserType.프리미엄 ||
                userData.userType === UserType.체험)
            ) {
              return (
                <div className="relative hover:bg-[#F6F6F6] w-full h-[46px] flex items-center justify-center">
                  <button
                    onClick={() => {
                      moveToPageByPath('youtube-auth')
                    }}
                    className="w-full h-full"
                  >
                    자동 업로드 설정
                  </button>
                </div>
              )
            }
            return (
              <div className="hover:bg-[#F6F6F6] w-full h-[46px]  flex items-center justify-center">
                <button
                  onClick={() => {
                    moveToPageByPath(el.path)
                  }}
                  className="w-full h-full"
                >
                  {el.name}
                </button>
              </div>
            )
          })}
        </div>
      </>
    )
  }

  const createButtonEl = () => {
    if (userData?.userInfo && userData?.userInfo) {
      const { name } = userData.userInfo

      return (
        <>
          <div
            className="font-bold md:py-[13px] px-[2px] md:px-[20px] flex items-center gap-2 relative"
            onMouseEnter={() => {
              setLogOutStatus(true)
            }}
          >
            <p className="text-[14px] hover:text-[#768296] ">{`MY (${name})`}</p>
            <img
              src={
                logOutStatus
                  ? 'assets/img/icon_arrow.png'
                  : 'assets/img/icon_arrow2.png'
              }
              alt="arrow"
              className="w-[12.8px] h-[8.25px]"
            />
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className="flex  text-[14px]">
            <button
              className="font-bold py-[13px] px-[17px] md:px-[34px]  hover:text-[#768296]"
              onClick={() => {
                moveToPageByPath('sign-in')
              }}
            >
              로그인
            </button>
            <FreeButton
              className="bg-href  text-white font-bold  py-[6px] md:py-[13px] px-[17px] md:px-[34px] rounded-[30px] md:rounded-[60px]"
              onClick={() => {
                moveToPageByPath('sign-up')
              }}
            >
              가입하고 무료체험
            </FreeButton>
          </div>
        </>
      )
    }
  }

  useEffect(() => {
    if (refetch && typeof refetch === 'function') {
      setRefetch(refetch)
    } else {
      console.log('Refetch not ready:', refetch)
    }

    if (planRefetch && typeof planRefetch === 'function') {
      setPlanRefetch(planRefetch)
    } else {
      console.log('planRefetch not ready:', planRefetch)
    }
  }, [refetch, setRefetch, planRefetch, setPlanRefetch])

  useEffect(() => {
    if (hambugerStatus) {
      // scroll 금지'
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [hambugerStatus])
  useEffect(() => {
    if (
      pathname !== '/sign-in' &&
      pathname !== '/sign-up' &&
      pathname !== '/terms'
    ) {
      prevSet({ prevUrl: pathname })
    }

    if (userData?.userInfo && !userData?.userInfo.termsAccepted) {
      const userType = urlParams.get('userType')
      navigate(`/terms?userType=${userType}`)
    }
  }, [pathname])

  // useEffect 내부에서 사용
  useEffect(() => {
    const node = document.getElementById('fastcut-extension-installed')

    if (node?.dataset.installed === 'true') {
      console.log('✅ Fastcut 확장 프로그램 설치 감지 (DOM)')
      saveLocalStorage('fs_extension', true)
    } else {
      console.log('✅ Fastcut 확장 프로그램 설치 감지 (DOM) 실패')
      saveLocalStorage('fs_extension', false)
    }

    const handler = (event: any) => {
      console.log('✅ Fastcut 익스텐션 설치 이벤트 감지!', event.detail)
      saveLocalStorage('fs_extension', true)
    }

    window.addEventListener('FASTCUT_EXTENSION_INSTALLED', handler)

    return () => {
      window.removeEventListener('FASTCUT_EXTENSION_INSTALLED', handler)
    }
  }, [pathname])

  return (
    <div
      className={classNames('border-b border-[#D9DEE8] border-gray w-[100%] ', {
        'mb-[50px]':
          pathname !== '/my-page' && pathname !== '/challenge' && !isMobile,
        'mb-6':
          pathname !== '/my-page' && pathname !== '/challenge' && isMobile,
        'mb-[24px]': pathname === '/my-page',
        'mb-0': pathname === '/challenge',
      })}
    >
      <header
        className="flex items-center justify-between max-w-[1400px] h-[80px] md:h-[100px] px-3 md:px-4 my-0 mx-auto relative"
        onMouseLeave={() => setLogOutStatus(false)}
      >
        {logOutStatus && createSelectBtn()}
        {hambugerStatus && (
          <div className="fixed bg-[#FFFFFF] border-t w-full h-[100vh] left-0 top-0 z-[999] overflow-y-auto pb-[200px]">
            {createMobileBtn()}
          </div>
        )}

        <div className="flex items-center  ">
          <button className="mr-8">
            <h1
              onClick={() => {
                navigate('/')
              }}
            >
              <img
                className="w-[100px] md:w-[150px] y-[27px]"
                src="/assets/img/logo/fastcut_ai_kr.png"
                alt="logo"
              />
            </h1>
          </button>
          {!isMobile && (
            <div className="flex text-[14px]">
              {GNB.map((el, index) => {
                if (el.path === 'global') {
                  return (
                    <a
                      className={`flex items-center font-bold md:py-[13px] px-[5px]  md:px-[24px] hover:text-[#768296]  `}
                      href="https://fastcut.ai"
                      target="_blank"
                      rel="noreferrer"
                      key={index}
                    >
                      {el.name}
                    </a>
                  )
                } else if (el.path === 'short-video-editor') {
                  return (
                    <p
                      className={`flex items-center  md:py-[13px] px-[5px]  md:px-[24px]  relative`}
                      onMouseOver={() => {
                        setIsOpen(true)
                      }}
                      onMouseLeave={() => {
                        setIsOpen(false)
                      }}
                      key={index}
                    >
                      <span className="font-bold">{el.name}</span>
                      <span>
                        <img
                          src="assets/img/icon_arrow2.png"
                          alt="arrow"
                          className={`w-[12.8px] h-[8.25px] ml-2  transition-transform duration-500  ${
                            !isOpen ? '' : 'rotate-180'
                          }`}
                        />
                      </span>
                      {isOpen && (
                        <div className=" absolute w-[241px] top-10 bg-white border-[1px] border-[#D9DEE8] rounded-[6px]">
                          <button
                            className={`flex  items-center  py-3 px-3  hover:bg-[#F6F6F6] cursor-pointer w-full h-full`}
                            onClick={() => {
                              navigate('/short-video-editor?createType=link')
                              setIsOpen((prev) => !prev)
                            }}
                          >
                            링크로 만들기
                          </button>
                          <button
                            className={`flex  items-center  py-3 px-3  hover:bg-[#F6F6F6] cursor-pointer w-full h-full`}
                            onClick={() => {
                              navigate('/short-video-editor?createType=text')
                              setIsOpen((prev) => !prev)
                            }}
                          >
                            <span>텍스트로 만들기 - 다국어 지원 🌏</span>
                          </button>
                        </div>
                      )}
                    </p>
                  )
                } else if (el.path === 'price-guide') {
                  return (
                    <p
                      className={`flex items-center  md:py-[13px] px-[5px]  md:px-[24px] relative`}
                      onMouseOver={() => {
                        setPiceGuideOpen(true)
                      }}
                      onMouseLeave={() => {
                        setPiceGuideOpen(false)
                      }}
                      key={index}
                    >
                      <span className="font-bold">{el.name}</span>
                      <span>
                        <img
                          src="assets/img/icon_arrow2.png"
                          alt="arrow"
                          className={`w-[12.8px] h-[8.25px] ml-2  transition-transform duration-500  ${
                            !isPriceGuideOpen ? '' : 'rotate-180'
                          }`}
                        />
                      </span>
                      {isPriceGuideOpen && (
                        <div className=" absolute w-[140px] top-10 bg-white border-[1px] border-[#D9DEE8] rounded-[6px]">
                          <button
                            className={`flex  items-center  py-3 px-3  hover:bg-[#F6F6F6] cursor-pointer w-full h-full`}
                            onClick={() => {
                              setPiceGuideOpen((prev) => !prev)
                              moveToPageByPath('price-guide')
                            }}
                          >
                            <span>상품 영상 플랜 가격</span>
                          </button>
                          <button
                            className={`flex  items-center  py-3 px-3 hover:bg-[#F6F6F6] cursor-pointer w-full h-full`}
                            onClick={() => {
                              setPiceGuideOpen((prev) => !prev)
                              moveToPageByPath('shortform-price-guide')
                            }}
                          >
                            컨텐츠 영상 가격
                          </button>
                        </div>
                      )}
                    </p>
                  )
                } else if (el.path === 'my-videos') {
                  return (
                    userData && (
                      <>
                        {' '}
                        <button
                          className={`flex  items-center font-bold md:py-[13px] px-[5px]  md:px-[24px]  hover:text-[#768296] cursor-pointer ${
                            pathname === `/${el.path}` && 'text-main'
                          }`}
                          onClick={() => {
                            moveToPageByPath(el.path)
                          }}
                          key={index}
                        >
                          {el.name}
                          {el.event && el.name === '챌린지' && (
                            <p className="bg-[#FF8800] text-white p-1 font-bold text-[10px] rounded-[4px] ml-1 h-[20px] flex items-center">
                              <span>페이백</span>
                            </p>
                          )}{' '}
                        </button>
                      </>
                    )
                  )
                } else {
                  return (
                    <button
                      className={`flex  items-center font-bold md:py-[13px] px-[5px]  md:px-[24px]  hover:text-[#768296] cursor-pointer ${
                        pathname === `/${el.path}` && 'text-main'
                      }`}
                      onClick={() => {
                        moveToPageByPath(el.path)
                      }}
                      key={index}
                    >
                      {el.name}
                      {el.event && el.name === '챌린지' && (
                        <p className="bg-[#FF8800] text-white p-1 font-bold text-[10px] rounded-[4px] ml-1 h-[20px] flex items-center">
                          <span>페이백</span>
                        </p>
                      )}{' '}
                    </button>
                  )
                }
              })}
            </div>
          )}
        </div>
        {isMobile ? (
          <>
            <button onClick={handleHambugerIconClick}>
              <img
                src="/assets/img/Icon_hambuger.png"
                alt="hambuger"
                width={32}
              />
            </button>
          </>
        ) : (
          createButtonEl()
        )}
      </header>
    </div>
  )
}

export default Header
