import classNames from 'classnames'
import { CircularCheckbox } from 'components/CircularCheckbox'
import { CircularCheckboxWithPlayBtn } from 'components/CircularCheckboxWithPlayBtn'
import { Modal } from 'components/layout/Modal'
import { PlanTag } from 'components/planTag'
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { isMobile } from 'react-device-detect'
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { SubtitleColorType, SubtitleType } from 'service/list'
import { Me } from 'service/user'
import { contentVideoFormValues, FormValues } from 'utils/type'
import SubtitleToolltip from './SubtitleTooltip'
import { Sale } from 'components/Sale'
interface SubtitleDisplayProps {
  watch: UseFormWatch<contentVideoFormValues>
  setValue: UseFormSetValue<contentVideoFormValues>
  control: Control<contentVideoFormValues, any>
  translatePlanName?: (planName: string) => string
  userInfo: Me
  subTitleList: SubtitleType[] | []
  subtitleColorList: SubtitleColorType[] | []
  subtitleDivRef?: React.MutableRefObject<HTMLDivElement | null>
  setSubtitleTooltip: Dispatch<SetStateAction<boolean>>
  subtitleTooltip: boolean
}

export const SubtitleDisplay = ({
  watch,
  setValue,
  control,
  translatePlanName,
  userInfo,
  subTitleList,
  subtitleDivRef,
  setSubtitleTooltip,
  subtitleTooltip,
  subtitleColorList,
}: PropsWithChildren<SubtitleDisplayProps>) => {
  const [isOpen, setModalOpen] = useState<boolean>(false)
  const findBgmNameById = () => {
    const [subtitle] = subTitleList.filter(
      (el) => el.id === watch('subtitleId')
    )
    const [subtitleColor] = subtitleColorList.filter(
      (el) => el?.id === watch('subtitleColorId')
    )
    return (
      <>
        <p className="flex items-center gap-1">
          <span className="bg-[#D9DEE8] px-1 py-[2px] text-[#8F929B] rounded-[4px] text-[14px] ">
            스타일
          </span>
          <span>{subtitle?.name}</span>
        </p>
        <p className="flex items-center gap-1">
          <span className="bg-[#D9DEE8] px-1 py-[2px] text-[#8F929B] rounded-[4px] text-[14px] ">
            스타일
          </span>
          <img src={subtitleColor?.imagePath} alt="" />
        </p>
      </>
    )
  }
  const handleModalOpen = () => {
    setModalOpen((prev) => !prev)
  }
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden') // Tailwind 유틸리티 클래스 사용
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [isOpen])

  return (
    <>
      <div className="subTitle  mb-10" ref={subtitleDivRef}>
        <div className="flex md:flex-row flex-col md:items-center justify-between">
          <p className="flex font-bold text-[18px] relative items-center gap-1">
            나레이션 자막
            <SubtitleToolltip
              subTitleList={subTitleList}
              setSubtitleTooltip={setSubtitleTooltip}
              subtitleTooltip={subtitleTooltip}
            />
            <Sale />
          </p>
          <p className="md:w-[570px] w-full md:h-[61px] rounded-[10px] bg-[#F6F8FB] px-4 py-5 flex items-center justify-between font-bold">
            <div className="flex gap-4 md:flex-row flex-col ">
              {findBgmNameById()}
            </div>
            <button
              className="px-2 py-[6px] bg-[#2E343D] text-[white] rounded-[4px] text-[14px]"
              onClick={() => {
                setModalOpen((prev) => !prev)
              }}
              type="button"
            >
              선택 {'>'}
            </button>
          </p>
        </div>
      </div>
      <Modal isModal={isOpen}>
        <div className="md:h-screen h-full w-full fixed left-0 top-0 flex flex-col justify-center items-center bg-black bg-opacity-50 text-center  z-50">
          <div
            className={classNames('', {
              'bg-white md:w-[816px] h-auto  shadow-main rounded-t-[20px] px-8 pt-8  flex flex-col   gap-8':
                !isMobile,
              'bg-white w-full h-full  shadow-main   flex flex-col   gap-8 pt-10 px-4  ':
                isMobile,
            })}
          >
            <div className="flex flex-col  gap-4  ">
              <p
                className={classNames('flex items-center', {
                  'justify-center': isMobile,
                  'justify-between': !isMobile,
                })}
              >
                <span className="font-bold text-[24px] text-left">
                  자막 선택
                </span>
              </p>
              <p className="md:text-left">
                내 영상 컨셉에 맞춰 {isMobile && <br />}어울리는 자막 스타일과
                색상을 선택해보세요!
              </p>
              <p className="text-[14px] text-error text-left">할인 이벤트중</p>
              <div className="flex gap-2">
                <p className="flex gap-1 items-center">
                  <img
                    src="assets/img/cash.png"
                    alt=""
                    className="w-[11px] h-[13px]"
                  />
                  <s className="text-[#5E6068]">100캐시</s>
                  <span className="text-main">10캐시</span>
                </p>
                <p className="flex gap-1 items-center">
                  <img
                    src="assets/img/cash.png"
                    alt=""
                    className="w-[11px] h-[13px]"
                  />
                  <img
                    src="assets/img/cash.png"
                    alt=""
                    className="w-[11px] h-[13px]"
                  />

                  <s className="text-[#5E6068]">200캐시</s>
                  <span className="text-main">50캐시</span>
                </p>
              </div>
            </div>
            <div className="overflow-auto ">
              <div className="md:overflow-auto md:h-auto  md:pb-4">
                <div className="flex flex-col gap-4 mb-8">
                  <p className="font-bold text-[18px] text-left">스타일</p>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[22px] gap-y-[16px] ">
                    {subTitleList?.map((subtitle, index) => {
                      return (
                        <>
                          <div className="" key={index}>
                            <div className="flex">
                              <Controller
                                render={({ field }) => (
                                  <CircularCheckbox
                                    {...field}
                                    id={index}
                                    checked={
                                      watch('subtitleId') === subtitle?.id
                                    }
                                    disabled={!userInfo}
                                    onChange={() =>
                                      setValue('subtitleId', subtitle?.id)
                                    }
                                    label={subtitle.name}
                                  />
                                )}
                                name="subtitleId"
                                control={control}
                              />
                              {new Array(subtitle?.cashRate)
                                .fill(0)
                                .map((el) => (
                                  <>
                                    {' '}
                                    <img
                                      src="assets/img/cash.png"
                                      alt="cash"
                                      className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
                                    />
                                  </>
                                ))}
                            </div>
                          </div>
                        </>
                      )
                    })}
                  </div>
                </div>
                <div className="flex flex-col gap-4">
                  <p className="font-bold text-[18px] text-left">색상</p>
                  <div className="grid grid-cols-2 md:grid-cols-4  gap-x-[22px] gap-y-[16px] ">
                    {subtitleColorList?.map((subtitleColor, index) => {
                      return (
                        <>
                          <div className="" key={index}>
                            <div className="flex">
                              <Controller
                                render={({ field }) => (
                                  <CircularCheckbox
                                    {...field}
                                    id={index}
                                    checked={
                                      watch('subtitleColorId') ===
                                      subtitleColor.id
                                    }
                                    // disabled={watch('subtitleId') === 1}
                                    onChange={() =>
                                      setValue(
                                        'subtitleColorId',
                                        subtitleColor.id
                                      )
                                    }
                                  />
                                )}
                                name="subtitleColorId"
                                control={control}
                              />
                              <img src={subtitleColor?.imagePath} alt="" />
                            </div>
                          </div>
                        </>
                      )
                    })}
                  </div>
                </div>
              </div>
              {isMobile && (
                <div className="pt-5 pb-20 w-[335px]">
                  <button
                    className="bg-main rounded-full w-full text-white font-bold h-[60px]"
                    onClick={handleModalOpen}
                    type="button"
                  >
                    저장
                  </button>
                </div>
              )}
            </div>
          </div>
          {!isMobile && (
            <div
              className="relative p-8 shadow-main w-full md:w-[816px] bg-white rounded-b-[20px] 
         before:content-[''] before:absolute before:top-[-10px] before:left-0 
         before:w-full before:h-[10px] before:bg-gradient-to-t before:from-black/10 before:to-transparent z-50"
            >
              <button
                className="bg-main rounded-full w-full text-white font-bold h-[60px]"
                onClick={handleModalOpen}
                type="button"
              >
                저장
              </button>
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}
