import { useQuery } from '@tanstack/react-query'
import { getBgm, getSubtitleColor } from '../../service/list'

function useGetSubtitleColor() {
  return useQuery({
    queryKey: ['get-subtitle-color'],
    queryFn: () => getSubtitleColor(),
    staleTime: 6 * 1000,
  })
}

export default useGetSubtitleColor
