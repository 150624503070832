import React, { PropsWithChildren, useState } from 'react'
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { FormValues } from '../../../utils/type'
import { Me } from '../../../service/user'
import { CircularCheckbox } from '../../CircularCheckbox'
import { IntroTooltip } from '../IntroTooltip'
import { IncludeAddTooltip } from '../IncludeAddTooltip'

interface IncludeAddDisplayProps {
  watch: UseFormWatch<FormValues>
  setValue: UseFormSetValue<FormValues>
  control: Control<FormValues, any>
  userInfo: Me
}
export const IncludeAddDisplay = ({
  watch,
  setValue,
  control,
  userInfo,
}: PropsWithChildren<IncludeAddDisplayProps>) => {
  const [tooltipStatus, setTooltipStatus] = useState<boolean>(false)
  return (
    <div className="price  my-[40px]">
      <div className="flex flex-col  md:mb-[16px] gap-4 relative ">
        <p className="flex items-center font-bold text-[18px]">
          영상 우상단 안내 이미지
          <span
            className="text-[white] text-[14px] bg-[#8C9DC2] px-3 py-1 rounded-[4px] ml-2"
            onMouseOver={() => {
              setTooltipStatus((prev) => !prev)
            }}
            onMouseLeave={() => {
              setTooltipStatus((prev) => !prev)
            }}
          >
            예시
          </span>
        </p>
        {tooltipStatus && (
          <IncludeAddTooltip setTooltipStatus={setTooltipStatus} />
        )}
        <div className="grid grid-cols-2 cursor-pointer">
          {[
            { name: '넣기', value: true },
            { name: '빼기', value: false },
          ].map((el, index) => {
            return (
              <div>
                <label
                  onClick={() => {
                    setValue('includeAdd', el.value)
                  }}
                >
                  <Controller
                    render={({ field }) => (
                      <CircularCheckbox
                        {...field}
                        id={index}
                        checked={watch('includeAdd') === el.value}
                        label={el.name}
                        onChange={() => {
                          setValue('includeAdd', el.value)
                        }}
                        disabled={
                          !userInfo || userInfo?.userType === 'normalUser'
                        }
                      />
                    )}
                    name="includeAdd"
                    control={control}
                  />
                </label>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
