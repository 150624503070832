import { useQuery } from '@tanstack/react-query'
import { getIntro, getOutro } from '../../service/list'

function useGetIntro() {
  return useQuery({
    queryKey: ['get-intro'],
    queryFn: () => getIntro(),
    staleTime: 6 * 1000,
  })
}

export default useGetIntro
