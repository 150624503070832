import React, { Dispatch, SetStateAction } from 'react'
import { ReactComponent as PlayIcon } from 'svg/play.svg'
import { ReactComponent as PauseIcon } from 'svg/pause.svg'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { PlanTag } from 'components/planTag'
interface SpeedPlayerProps {
  id: number
  index: number
  label?: string
  checked?: boolean
  disabled?: boolean
  onChange: (id: number) => void
  className?: string
  handleFn: (status: boolean, index: number) => void
  playing: boolean[]
  planName?: string
  cashRate?: number
  tempoRefs: React.MutableRefObject<(HTMLAudioElement | null)[]>
  src: string
  tempoPlaying: boolean[]
  setTempoPlaying: Dispatch<SetStateAction<boolean[]>>
}

export const SpeedPlayer: React.FC<SpeedPlayerProps> = ({
  id,
  index,
  label,
  checked,
  disabled,
  onChange,
  className,
  handleFn,
  playing,
  planName,
  tempoRefs,
  cashRate,
  src,
  tempoPlaying,
  setTempoPlaying,
}) => {
  return (
    <>
      <div
        className={classNames(' cursor-pointer', {
          'w-[360px] h-[54px] flex gap-[8px] justify-between items-center bg-[#F9F9FB] rounded-[4px] px-4':
            !isMobile,
          'w-[343px] h-[54px] flex gap-[8px] justify-between items-center bg-[#F9F9FB] rounded-[4px] px-4':
            isMobile,
        })}
        key={`voice_${index}`}
        onClick={() => {
          onChange(id)
        }}
      >
        <div className="flex w-full">
          <div className="relative flex items-center" key={index}>
            <label className="relative flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={checked}
                onChange={() => onChange(id)}
                className="sr-only"
                disabled={disabled}
              />
              <div
                className={`w-[20px] h-[20px] rounded-full border-2 bg-main flex items-center justify-center ${
                  checked ? 'bg-white border-main' : 'bg-white'
                }`}
              >
                {checked && (
                  <div className="w-3 h-3 bg-main rounded-full"></div>
                )}
              </div>
            </label>
          </div>

          <p
            className={`text-[#2E343D] md:text-[18px] ml-2 ${className} flex   `}
            style={!checked && disabled ? { opacity: 0.3 } : {}}
          >
            {label}
            {cashRate ? (
              <span className="flex h-full ml-[2px] mr-[8px]">
                {new Array(cashRate).fill(0).map((el, i) => (
                  <>
                    <img
                      src="assets/img/cash.png"
                      alt="cash"
                      className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
                      key={`voiceCashRate_${i}`}
                    />
                  </>
                ))}
              </span>
            ) : (
              ''
            )}
            {planName && (
              <div className="flex items-start h-full  p-t1 ml-[2px] mr-[8px]">
                <PlanTag planName={planName} className="" />
              </div>
            )}
          </p>
        </div>
        <button
          type="button"
          onClick={() => {
            handleFn(!playing[index], index)
          }}
        >
          {!playing[index] ? <PlayIcon /> : <PauseIcon />}
        </button>
        <div className="flex gap-[12px]">
          <audio
            ref={(ref) => {
              tempoRefs.current[index] = ref
              if (ref) {
                ref.onended = () => {
                  const updatedBgmPlayingState = [...tempoPlaying]
                  updatedBgmPlayingState[index] = false
                  setTempoPlaying(updatedBgmPlayingState)
                }
              }
            }}
          >
            <source src={src} type="audio/mpeg" />
          </audio>
        </div>
      </div>
    </>
  )
}
