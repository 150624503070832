import classNames from 'classnames'
import React from 'react'
import { isMobile } from 'react-device-detect'

export const WithShortsTooltip = (props: any) => {
  const { setWithShortsStatus } = props

  return (
    <div
      className={classNames(
        'flex flex-col p-[16px] md:p-[32px] gap-[10px] bg-white   h-[225px] absolute border-[3px] border-main  rounded-[20px] ',
        {
          'w-[330px] bottom-5 left-0': !isMobile,
          'w-[280px] bottom-5 -left-32': isMobile,
        }
      )}
      onMouseLeave={() => {
        setWithShortsStatus(false)
      }}
    >
      <p className=" md:leading-[19.2px] md:text-base  leading-[12px] text-[10px]">
        <span className="font-bold text-[20px]">숏폼도 함께 생성하기?</span>
      </p>
      <p>
        숏폼도 함께 생성하기를 체크하시면, 링크 입력하신 각 상품 영상이 숏폼
        형태로도 생성됩니다.
      </p>
      <p className="text-error text-[14px]">할인 이벤트 중</p>
      <p className="flex gap-2  text-[14px] items-center">
        <img
          src="assets/img/cash.png"
          alt="coupang"
          className="w-[11px] h-[13px]"
        />
        <span className="font-bold">숏폼 영상 1개</span>{' '}
        <s className="text-[#5E6068]">700캐시</s>{' '}
        <span className="font-bold text-main">300캐시</span>
      </p>
    </div>
  )
}
