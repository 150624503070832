import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'

import {
  promptGroup,
  promptType,
  TempoType,
  ToneType,
  VoiceGroupFactor,
  VoiceTagType,
} from 'service/list'
import classNames from 'classnames'
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { contentVideoFormValues } from 'utils/type'

import { CircularCheckboxWithPlayBtn } from 'components/CircularCheckboxWithPlayBtn'
import { Me } from 'service/user'
import { isMobile } from 'react-device-detect'
import { Modal } from 'components/layout/Modal'
import { PlanTag } from 'components/planTag'
import TooltipIcon from 'components/TooltipIcon'
import { CircularCheckbox } from 'components/CircularCheckbox'
import { TypeCastVoicePlayer } from 'components/video/voice/TypeCastVoicePlayer'
import { VoicePlayer } from 'components/video/voice/VoicePlayer'
import { SpeedPlayer } from 'components/video/voice/SpeedPlayer'

interface AiScriptDisplayProps {
  watch: UseFormWatch<contentVideoFormValues>
  setValue: UseFormSetValue<contentVideoFormValues>
  control: Control<contentVideoFormValues, any>

  toneList: ToneType[] | undefined
  tempoList: TempoType[] | undefined
  voiceList: VoiceGroupFactor[] | undefined
  userInfo: Me
  setPromptStatus: Dispatch<SetStateAction<boolean[]>>
  setVoicePlaying: Dispatch<SetStateAction<boolean[]>>
  setTempoPlaying: Dispatch<SetStateAction<boolean[]>>
  setToneTooltip: Dispatch<SetStateAction<boolean[]>>
  promptStatus: boolean[]
  voicePlaying: boolean[]
  toneTooltip: boolean[]
  tempoPlaying: boolean[]
  voiceTags: VoiceTagType | undefined
  selectedVoiceId: number
}

export const AiScriptDisplay = ({
  watch,
  setValue,
  control,
  toneList,
  tempoList,
  userInfo,
  setPromptStatus,
  promptStatus,
  setVoicePlaying,
  voicePlaying,
  voiceList,
  voiceTags,
  selectedVoiceId,
  setToneTooltip,
  toneTooltip,
  tempoPlaying,
  setTempoPlaying,
}: PropsWithChildren<AiScriptDisplayProps>) => {
  const promptRefs = useRef<(HTMLOrSVGElement | null)[]>([])
  const voiceRefs = useRef<(HTMLAudioElement | null)[]>([])
  const tempoRefs = useRef<(HTMLAudioElement | null)[]>([])
  const tonRefs = useRef<(HTMLOrSVGElement | null)[]>([])
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [isGenderSelectBoxOpen, setGenderSelectBoxOpen] =
    useState<boolean>(false)
  const [isAgeSelectBoxOpen, setAgeSelectBoxOpen] = useState<boolean>(false)
  const [isMobilePromptSelectBoxOpen, setMobilePromptSelectBoxOpen] =
    useState<boolean>(false)

  const [genderWords, setGenderWords] = useState<string[]>(
    voiceTags?.genders ?? ['남성', '여성']
  )

  const [ageWords, setAgeWords] = useState<string[]>(
    voiceTags?.ages ?? ['청년', '중년 이상', '청소년']
  )

  const [tempoName, setTempoName] = useState<string>('')
  const [selectedPrompt, setSelectedPrompt] = useState<
    promptGroup | { planName: string; introduction: string }
  >({
    planName: '',
    introduction: '',
  })
  const [toneName, setToneName] = useState<string>('')
  const [voiceName, setVoiceName] = useState<string>('')
  const [genderValue, setGenderValue] = useState<string>('')
  const [ageValue, setAgeValue] = useState<string>('')
  const [voiceListValue, setVoiceListValue] = useState<VoiceGroupFactor[] | []>(
    []
  )

  const handleListModalOpen = () => {
    setModalOpen((prev) => !prev)
  }

  const handleVoicePlayButtonClick = (status: boolean, index: number) => {
    const updatedPlayingState = voicePlaying.map((value, idx) =>
      idx === index ? status : false
    )
    setVoicePlaying(updatedPlayingState)
    if (voiceRefs.current[index]) {
      if (status) {
        voiceRefs.current[index]?.play()
      } else {
        voiceRefs.current[index]?.pause()
      }
    }
  }

  const handleTempoPlayButtonClick = (status: boolean, index: number) => {
    const updatedPlayingState = tempoPlaying.map((value, idx) =>
      idx === index ? status : false
    )
    setTempoPlaying(updatedPlayingState)
    if (tempoRefs.current[index]) {
      if (status) {
        tempoRefs.current[index]?.play()
      } else {
        tempoRefs.current[index]?.pause()
      }
    }
  }
  const handleGenderChange = (gender: string) => {
    setGenderWords(
      (prev) =>
        prev.includes(gender)
          ? prev.filter((item) => item !== gender) // 체크 해제
          : [...prev, gender] // 체크 선택
    )
  }
  const handleAgeChange = (age: string) => {
    setAgeWords(
      (prev) =>
        prev.includes(age)
          ? prev.filter((item) => item !== age) // 체크 해제
          : [...prev, age] // 체크 선택
    )
  }

  const handleMouseOver = (index: number) => {
    const updatedToneTooltipState = toneTooltip.map((value, idx) =>
      idx === index ? true : value
    )

    setToneTooltip(updatedToneTooltipState)
  }

  const handleMouseLeave = (index: number) => {
    const updatedToneTooltipState = toneTooltip.map((value, idx) =>
      idx === index ? false : value
    )
    setToneTooltip(updatedToneTooltipState)
  }

  useEffect(() => {
    voiceRefs.current.forEach((audioRef, index) => {
      if (audioRef && !voicePlaying[index]) {
        audioRef.pause()
        audioRef.currentTime = 0
      }
    })
  }, [voicePlaying])

  useEffect(() => {
    tempoRefs.current.forEach((audioRef, index) => {
      if (audioRef && !tempoPlaying[index]) {
        audioRef.pause()
        audioRef.currentTime = 0
      }
    })
  }, [tempoPlaying])

  useEffect(() => {
    if (voiceList) {
      let filter = voiceList

      // 1차: 성별 필터링
      if (genderWords.length > 0) {
        filter = filter.filter((el) => genderWords.includes(el?.gender ?? ''))
      } else {
        filter = []
      }

      // 2차: 연령 필터링
      if (ageWords.length > 0) {
        filter = filter.filter((el) => ageWords.includes(el.age ?? ''))
      } else {
        filter = []
      }

      // 상태 업데이트
      const prevVoiceId = watch('voiceId')

      setVoiceListValue(filter)
      setVoicePlaying(new Array(filter.length).fill(false))
    }
  }, [genderWords, ageWords, voiceList])

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('overflow-hidden') // Tailwind 유틸리티 클래스 사용
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [isModalOpen])

  useEffect(() => {}, [])

  useEffect(() => {
    const aiToneTypeId = watch('aiToneTypeId')
    const tempoId = watch('tempoId')
    if (voiceList && selectedVoiceId) {
      //voice아이디를 가지고 list에서 찾아서 gender와 age를 셋팅해야함

      const [voice] = voiceList.filter((el) => el.id === selectedVoiceId)
      if (voice) {
        setVoiceName(voice?.name)
        setAgeValue(voice.age ?? '')
        setGenderValue(voice.gender ?? '')
      }
    }
    if (toneList && aiToneTypeId) {
      const selectedToneId = watch('aiToneTypeId')
      const [tone] = toneList.filter((el) => el.id === selectedToneId)
      setToneName(tone?.name)
    }
    if (tempoList && tempoId) {
      const selectedTempoId = watch('tempoId')
      const [tempo] = tempoList.filter((el) => el.id === selectedTempoId)
      setTempoName(tempo?.name)
    }
  }, [
    toneList,
    tempoList,
    watch('aiToneTypeId'),
    watch('tempoId'),
    voiceList,
    selectedVoiceId,
  ])

  return (
    <>
      <div className="bgm  mb-10">
        <div className="flex  md:flex-row flex-col md:items-center justify-between">
          <p className="font-bold text-[18px] mb-4 md:mb-0 ">AI 생성 대본</p>
          <p className="md:w-[630px]  md:h-[61px] w-full rounded-[10px] bg-[#F6F8FB] px-4 py-5 flex items-center justify-between font-bold">
            <div className="flex md:flex-row flex-col md:items-center md:gap-5 gap-4">
              <p className="flex items-center text-[14px] gap-1">
                <span className="bg-[#D9DEE8] px-1 py-[2px] text-[#8F929B] rounded-[4px] ">
                  속도
                </span>
                <span className="">{tempoName}</span>
              </p>
              {!isMobile && <span className="text-[#9CA3AF] text-lg">•</span>}
              <p className="flex items-center text-[14px] gap-1">
                <span className="bg-[#D9DEE8] px-1 py-[2px] text-[#8F929B] rounded-[4px] ">
                  말투
                </span>
                <span className="">{toneName}</span>
              </p>{' '}
              {!isMobile && <span className="text-[#9CA3AF] text-lg">•</span>}
              <p className="flex items-center text-[14px] gap-1">
                <span className="bg-[#D9DEE8] px-1 py-[2px] text-[#8F929B] rounded-[4px] ">
                  목소리
                </span>
                <div className="flex items-center">
                  <span
                    className="font-bold text-white text-[11px] px-1 py-[2px] rounded-[4px]"
                    style={{
                      background: `linear-gradient(to right,#6095FF,#00CBB3)`,
                    }}
                  >
                    UPDATE
                  </span>
                </div>
                <span className="">{voiceName}</span>
              </p>
            </div>
            <button
              className="px-2 py-[6px] bg-[#2E343D] text-[white] rounded-[4px] text-[14px]"
              onClick={handleListModalOpen}
              type="button"
            >
              선택 {'>'}
            </button>
          </p>
        </div>
      </div>

      <Modal isModal={isModalOpen}>
        <div className="md:h-screen h-full w-full fixed left-0 top-0 flex flex-col justify-center items-center bg-black bg-opacity-50 text-center  z-100">
          <div
            className={classNames('', {
              'bg-white w-[816px] h-auto  rounded-t-[20px] px-8 pt-8  flex flex-col   gap-8 ':
                !isMobile,
              'bg-white  w-full h-full    flex flex-col   gap-8 pt-10 px-4':
                isMobile,
            })}
          >
            <div className="flex flex-col  gap-4  ">
              <p className="flex flex-col gap-4 md:items-start items-center">
                <span className="font-bold text-[24px] text-left">
                  AI 생성 대본 효과 선택
                </span>
                <span>
                  AI 생성 대본 컨셉에 맞춰 어울리는 나레이션 말투와 목소리를
                  선택해보세요!
                </span>
              </p>
            </div>
            <div className="flex flex-col  overflow-auto md:scrollbar-visible md:h-[500px] pb-40    gap-8 md:pb-0">
              <div className="ai-prompt flex flex-col items-start">
                <p className="font-bold text-[18px] mb-[16px]">속도</p>
                <div className=" w-full md:h-[40px] rounded-[10px] grid md:grid-cols-2 grid-cols-1 gap-y-4 ">
                  {tempoList?.map((tempo, index) => {
                    return (
                      <>
                        <div className="flex" key={index}>
                          <Controller
                            render={({ field }) => (
                              <>
                                <SpeedPlayer
                                  {...field}
                                  id={index}
                                  index={index}
                                  playing={tempoPlaying}
                                  handleFn={handleTempoPlayButtonClick}
                                  checked={watch('tempoId') === tempo?.id}
                                  onChange={() => {
                                    setValue('tempoId', tempo.id)
                                    setTempoName(tempo?.name)
                                  }}
                                  label={tempo.name}
                                  className="text-left w-full"
                                  src={tempo.path}
                                  tempoPlaying={tempoPlaying}
                                  setTempoPlaying={setTempoPlaying}
                                  tempoRefs={tempoRefs}
                                />{' '}
                              </>
                            )}
                            rules={{
                              validate: (value) => {
                                return Number(watch('aiPromptTypeId')) === 0
                                  ? 'tempo ID must not be 0'
                                  : true
                              },
                            }}
                            name="tempoId"
                            control={control}
                          />
                          {new Array(tempo?.cashRate).fill(0).map((el, i) => (
                            <>
                              {' '}
                              <img
                                src="assets/img/cash.png"
                                alt="cash"
                                className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
                                key={`tempo_cash_${i}`}
                              />
                            </>
                          ))}
                          <div className="flex gap-[12px]">
                            <button type="button">
                              <audio
                                ref={(ref) => {
                                  tempoRefs.current[index] = ref
                                }}
                              >
                                <source src={tempo.path} type="audio/mpeg" />
                              </audio>
                            </button>
                          </div>
                        </div>
                      </>
                    )
                  })}
                </div>
              </div>
              <div className="ai-prompt flex flex-col items-start md:min-h-auto">
                <p className="font-bold text-[18px] mb-[16px]">나레이션 말투</p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[53.67px] gap-y-[16px] ">
                  {toneList?.map((tone, index) => {
                    return (
                      <>
                        <div
                          className="w-full flex justify-between md:items-center"
                          key={`tone_${tone.id}_${index}`}
                        >
                          <div className="flex items-center h-full gap-1">
                            <Controller
                              render={({ field }) => (
                                <CircularCheckbox
                                  {...field}
                                  id={index}
                                  checked={watch('aiToneTypeId') === tone.id}
                                  onChange={() => {
                                    setValue('aiToneTypeId', tone.id)
                                    setToneName(tone.name)
                                  }}
                                  label={tone.name}
                                />
                              )}
                              name="aiToneTypeId"
                              control={control}
                            />

                            <TooltipIcon
                              tone={tone}
                              index={index}
                              tonRefs={tonRefs}
                              toneTooltip={toneTooltip}
                              handleMouseOver={handleMouseOver}
                              handleMouseLeave={handleMouseLeave}
                              className={
                                index % 2 === 1
                                  ? '-left-60  md:w-[343px] '
                                  : '  md:w-[343px]'
                              }
                            />
                            {/* {new Array(tone?.cashRate).fill(0).map((el) => (
                              <>
                                {' '}
                                <img
                                  src="assets/img/cash.png"
                                  alt="cash"
                                  className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
                                />
                              </>
                            ))} */}
                          </div>
                        </div>
                      </>
                    )
                  })}
                </div>
              </div>
              <div className="ai-prompt flex flex-col items-start md:min-h-[300px]">
                <p className="font-bold text-[18px] md:mb-[16px]">목소리 </p>
                <div className="mb-4">
                  <p className="text-[14px] text-error text-left">
                    {' '}
                    할인 이벤트중
                  </p>
                  <div
                    className={classNames('', {
                      'flex gap-8': !isMobile,
                      'grid grid-cols-2': isMobile,
                    })}
                  >
                    {[
                      { cashRate: 1, price: 500, originPrice: 100 },
                      { cashRate: 2, price: 1000, originPrice: 300 },
                      { cashRate: 3, price: 1500, originPrice: 500 },
                      { cashRate: 4, price: 2000, originPrice: 600 },
                    ].map((el) => (
                      <>
                        <span className="text-main flex gap-1 items-center">
                          {el.cashRate &&
                            new Array(el.cashRate)
                              .fill(1)
                              .map((el) => (
                                <img
                                  src="assets/img/cash.png"
                                  alt="check_circle"
                                  className="w-[11px] h-[13px]"
                                />
                              ))}
                          <s className="text-[#5E6068]">
                            {el.price.toLocaleString()}
                          </s>{' '}
                          {el.originPrice} 캐시
                        </span>
                      </>
                    ))}
                  </div>
                </div>
                {isMobile && (
                  <p className=" text-main text-left mb-4">
                    {selectedPrompt.introduction}
                  </p>
                )}
                <div
                  className={classNames('', {
                    'select_box flex gap-4 mb-[16px]': !isMobile,
                    'select_box flex flex-col gap-4 mb-[16px]': isMobile,
                  })}
                >
                  <div className="flex items-center gap-2">
                    <span>성별</span>{' '}
                    <div
                      className="relative"
                      onMouseLeave={() => {
                        setGenderSelectBoxOpen(false)
                      }}
                    >
                      <button
                        className={classNames('', {
                          'w-[140px] h-[33px] px-4 py-1 border-[1px] border-[#D4D9DF] rounded-[6px] font-[500] text-[14px] text-left':
                            !isMobile,
                          'w-[140px] h-[51px] px-4 py-1 border-[1px] border-[#D4D9DF] rounded-[6px] font-[500] text-left':
                            isMobile,
                        })}
                        value={genderValue}
                        type="button"
                        onClick={() => {
                          setGenderSelectBoxOpen((prev) => !prev)
                        }}
                      >
                        {' '}
                        {genderWords.length > 0
                          ? genderWords.join(', ')
                          : '성별'}
                      </button>
                      <img
                        src="assets/img/chevron-down.png"
                        className={classNames('', {
                          'w-[16px] h-[16px] absolute right-2 bottom-2':
                            !isMobile,
                          'w-[16px] h-[16px] absolute right-2 bottom-4':
                            isMobile,
                        })}
                        alt=""
                        onClick={() => {
                          setGenderSelectBoxOpen((prev) => !prev)
                        }}
                      />
                      {isGenderSelectBoxOpen && (
                        <div className=" absolute bg-white z-50 w-[120px] h-[110px] shadow-main flex flex-col px-3 py-1  gap-2 justify-center rounded-[10px]">
                          {voiceTags?.genders.map((gender, index) => {
                            return (
                              <>
                                <div
                                  className="flex items-center gap-2"
                                  key={`voice_tag_${index}`}
                                >
                                  <input
                                    id={`gender-${index}`} // 고유한 id
                                    type="checkbox"
                                    className="w-[20px] h-[20px]"
                                    value={gender}
                                    checked={genderWords.includes(gender)}
                                    onChange={() => handleGenderChange(gender)}
                                  />
                                  <label htmlFor={`gender-${index}`}>
                                    {gender}
                                  </label>
                                </div>
                              </>
                            )
                          })}
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    className={classNames('flex items-center gap-2', {
                      '': !isMobile,
                    })}
                  >
                    <span>연령</span>{' '}
                    <div
                      className="relative"
                      onMouseLeave={() => {
                        setAgeSelectBoxOpen(false)
                      }}
                    >
                      <button
                        className={classNames('', {
                          ' w-[179px] h-[33px] px-4 py-1 border-[1px] border-[#D4D9DF] rounded-[6px] font-[500] text-[14px] text-left':
                            !isMobile,
                          'w-[197px] h-[51px] px-4 py-1 border-[1px] border-[#D4D9DF] rounded-[6px] font-[500]  text-left':
                            isMobile,
                        })}
                        value={ageValue}
                        type="button"
                        onClick={() => {
                          setAgeSelectBoxOpen((prev) => !prev)
                        }}
                      >
                        {' '}
                        {ageWords.length > 0 ? ageWords.join(', ') : '연령'}
                      </button>
                      <img
                        src="assets/img/chevron-down.png"
                        className={classNames('', {
                          'w-[16px] h-[16px] absolute right-2 bottom-2':
                            !isMobile,
                          'w-[16px] h-[16px] absolute right-2 bottom-4':
                            isMobile,
                        })}
                        alt=""
                        onClick={() => {
                          setAgeSelectBoxOpen((prev) => !prev)
                        }}
                      />{' '}
                      {isAgeSelectBoxOpen && (
                        <div className=" absolute bg-white z-50 w-[120px] h-[140px] shadow-main flex flex-col px-3 py-1  gap-2 justify-center rounded-[10px] right-0">
                          {voiceTags?.ages.map((age, index) => {
                            return (
                              <label
                                className="w-full flex justify-start gap-2 items-center"
                                key={`voiceTag_ages_${index}`}
                              >
                                <input
                                  id={`age-${index}`} // 고유한 id
                                  type="checkbox"
                                  className="w-[20px] h-[20px]"
                                  value={age}
                                  checked={ageWords.includes(age)}
                                  onChange={() => handleAgeChange(age)}
                                />
                                <span>{age}</span>
                              </label>
                            )
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-3  gap-x-1 gap-y-2 md:pb-4">
                  {voiceListValue?.map((voice, index) => {
                    return (
                      <>
                        <Controller
                          render={({ field }) =>
                            voice.service === 'typecast' ? (
                              <>
                                <TypeCastVoicePlayer
                                  {...field}
                                  id={index}
                                  index={index}
                                  playing={voicePlaying}
                                  handleFn={handleVoicePlayButtonClick}
                                  checked={watch('voiceId') === voice.id}
                                  onChange={() => {
                                    setValue('voiceId', voice.id)
                                    setVoiceName(voice.name)
                                  }}
                                  label={voice.name}
                                  voiceRefs={voiceRefs}
                                  src={voice.shortPath}
                                  cashRate={voice?.cashRate}
                                  imagePath={voice.image ?? ''}
                                />
                              </>
                            ) : (
                              <>
                                {' '}
                                <VoicePlayer
                                  {...field}
                                  id={index}
                                  index={index}
                                  playing={voicePlaying}
                                  handleFn={handleVoicePlayButtonClick}
                                  checked={watch('voiceId') === voice.id}
                                  onChange={() => {
                                    setValue('voiceId', voice.id)
                                    setVoiceName(voice.name)
                                  }}
                                  label={voice.name}
                                  voiceRefs={voiceRefs}
                                  src={voice.shortPath}
                                  cashRate={voice?.cashRate}
                                />
                              </>
                            )
                          }
                          name="voiceId"
                          control={control}
                        />
                      </>
                    )
                  })}
                </div>
              </div>
              {isMobile && (
                <div
                  className={classNames(
                    'bg-white  w-[335px]   flex flex-col   gap-8 z-50 ',
                    {}
                  )}
                >
                  <button
                    className="bg-main rounded-full w-full text-white font-bold h-[60px]  z-50"
                    onClick={handleListModalOpen}
                    type="button"
                  >
                    저장
                  </button>
                </div>
              )}
            </div>
          </div>
          {!isMobile && (
            <div
              className="relative p-8 shadow-main w-full md:w-[816px] bg-white rounded-b-[20px] 
        before:content-[''] before:absolute before:top-[-10px] before:left-0 
        before:w-full before:h-[10px] before:bg-gradient-to-t before:from-black/10 before:to-transparent"
            >
              <button
                className="bg-main rounded-full w-full text-white font-bold h-[60px]  z-50"
                onClick={handleListModalOpen}
                type="button"
              >
                저장
              </button>
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}
