import { useQuery } from '@tanstack/react-query'
import { getLinks } from 'service/list'

function useGetLinks(pageSize: number, page: number) {
  return useQuery({
    queryKey: ['get-links'],
    queryFn: () => getLinks(pageSize, page),
    staleTime: 6 * 1000,
  })
}

export default useGetLinks
