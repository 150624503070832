import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { FormValues } from '../../../utils/type'
import { Me } from '../../../service/user'
import { CircularCheckbox } from '../../CircularCheckbox'
import { IntroTooltip } from '../IntroTooltip'
import { isMobile } from 'react-device-detect'
import classNames from 'classnames'
import { IntroFactor, OutroFactor } from 'service/list'
import { VideoComponent } from '../VideoComponent'
import { PlanTag } from 'components/planTag'
import { Modal } from 'components/layout/Modal'

interface IntroDisplayProps {
  watch: UseFormWatch<FormValues>
  setValue: UseFormSetValue<FormValues>
  control: Control<FormValues, any>
  userInfo: Me
  translatePlanName: (planName: string) => string
  introList: IntroFactor[] | []
}
export const IntroDisplay = ({
  watch,
  setValue,
  control,
  userInfo,
  translatePlanName,
  introList,
}: PropsWithChildren<IntroDisplayProps>) => {
  // const [introTooltipStatus, setIntroTooltipStatus] = useState<boolean>(false)
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([])
  const videoRef = useRef<HTMLVideoElement | null>(null)

  const [videoPlaying, setVideoPlaying] = useState<boolean[]>(
    new Array(introList?.length).fill(false)
  )
  const [isIntroModal, setIntroModal] = useState<boolean>(false)
  const [selectedIntroIndex, setSelectedIntroIndex] = useState<number>(0)

  const introMap = useMemo(() => {
    if (introList) {
      const map = new Map<number, (typeof introList)[0]>()
      introList.forEach((item) => {
        map.set(item.id, item) // 숫자를 그대로 사용 가능
      })
      setVideoPlaying(new Array(introList?.length).fill(false))
      return map
    }
  }, [introList])

  const handleSelectedIntro = (behavior: boolean) => {
    if (videoRef.current && behavior) {
      console.log(videoRef.current!.readyState, '선택됨')
      if (videoRef.current!.readyState >= 2) {
        try {
          videoRef.current!.muted = true
          videoRef.current!.currentTime = 0
          videoRef.current?.play().catch((error) => {
            console.warn('Playback failed:', error)
          })
        } catch (e) {
          console.log(e, 'not play video index:')
        }
      }
    } else if (videoRef.current && !behavior) {
      if (videoRef.current!.readyState >= 2) {
        try {
          videoRef.current!.currentTime = 1
          videoRef.current?.pause()
        } catch (e) {
          console.log(e, 'not play video index:')
        }
      }
    }
  }

  const handleVideoEnded = (index: number) => {
    const updatedPlayingState = [...videoPlaying]
    updatedPlayingState[index] = false
    setVideoPlaying(updatedPlayingState)
  }

  const handlePlayButtonClick = (index: number) => {
    const updatedPlayingState = videoPlaying.map((value, idx) =>
      idx === index ? true : false
    )

    setVideoPlaying(updatedPlayingState)
  }

  // 선택된 항목 가져오기
  const selectedIntroId = watch('introId')
  const selectedItem = introMap?.get(selectedIntroId) ?? 0

  const renderSelectedItem = () => {
    if (!selectedItem) return null

    return selectedItem.name === '없음' ? (
      <div
        className="flex md:flex-col relative w-[138px] h-[78px] justify-center outline outline-[4px] outline-main"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
      >
        <button type="button" className="text-white font-bold z-40">
          인트로 없음
        </button>
      </div>
    ) : (
      <div
        className={classNames(
          'flex w-[138px] relative outline outline-4 outline-main'
        )}
        onMouseEnter={!isMobile ? () => handleSelectedIntro(true) : undefined}
        onMouseLeave={!isMobile ? () => handleSelectedIntro(false) : undefined}
        onClick={isMobile ? () => handleSelectedIntro(true) : undefined}
      >
        {videoRef ? (
          <video
            ref={videoRef}
            src={selectedItem.path}
            playsInline
            width={138}
            loop
            autoPlay
            muted
            height={78}
            preload="auto"
          />
        ) : (
          <img
            src={selectedItem.imagePath}
            className="w-[138px] h-[78px]"
            loading="lazy"
            alt=""
          />
        )}
        <div className="absolute w-[138px] h-[78px] flex">
          <span className="font-bold text-white text-[14px] ml-1 mt-1">
            선택됨
          </span>
        </div>
      </div>
    )
  }

  const renderIntroItem = (intro: OutroFactor, index: number) => {
    const isSelected = watch('introId') === intro.id

    return (
      <div
        key={index}
        className="flex md:flex-col relative"
        onMouseEnter={
          !isMobile ? () => handlePlayButtonClick(index) : undefined
        }
        onMouseLeave={!isMobile ? () => handleVideoEnded(index) : undefined}
        onClick={isMobile ? () => handlePlayButtonClick(index) : undefined}
      >
        {intro?.name !== '없음' ? (
          <VideoComponent
            videoRefs={videoRefs}
            src={intro.path}
            className={classNames({
              'outline outline-[4px] outline-main': isSelected,
            })}
            imagePath={intro.imagePath}
            index={index}
            selected={isSelected}
            videoPlaying={videoPlaying}
          />
        ) : (
          <div
            className={classNames(
              'flex  md:flex-col  relative w-[138px] h-[78px] justify-center',
              {
                ' outline outline-[4px] outline-main':
                  intro.name === '없음' && intro.id === watch('introId'),
              }
            )}
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
            onClick={() => {
              setSelectedIntroIndex(index)
              setValue('introId', intro.id)
            }}
          >
            <button
              type="button"
              className={classNames(
                'text-white font-bold z-40 w-[138px] h-[78px]'
              )}
            >
              인트로 없음
            </button>
          </div>
        )}
        {index === (isMobile ? 3 : 3) ? (
          <div
            className={`absolute w-[138px] h-[78px] flex items-center justify-center $ `}
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
          >
            <button
              type="button"
              onClick={() => {
                setIntroModal((prev) => !prev)
              }}
              className=" text-white font-bold z-40"
            >
              {introList?.length - (isMobile ? 4 : 5)}개 더보기
            </button>
          </div>
        ) : (
          <div
            className={classNames('absolute w-[138px] h-[78px] flex', {
              'bg-opacity-50': isSelected,
            })}
            style={{
              backgroundColor: isSelected ? 'rgba(0, 0, 0, 0.5)' : undefined,
            }}
            onClick={() => {
              setSelectedIntroIndex(index)
              setValue('introId', intro.id)
            }}
          >
            <div className="flex items-start h-full pt-1 mt-[6px] ml-[6px]">
              <PlanTag planName={translatePlanName(intro.planName)} />
            </div>
            <Controller
              name="introId"
              control={control}
              render={({ field }: any) => (
                <label className="relative flex w-full cursor-pointer items-end justify-end mr-1 mb-1">
                  <input
                    type="checkbox"
                    className="sr-only"
                    checked={isSelected}
                    disabled={!intro.usable}
                    onChange={() => {
                      setSelectedIntroIndex(index)
                      setValue('introId', intro.id)
                    }}
                  />
                  <div
                    className={classNames(
                      'w-[20px] h-[20px] rounded-full border-2 flex items-center justify-center',
                      {
                        'bg-white border-main': isSelected,
                        'bg-white': !isSelected,
                      }
                    )}
                  >
                    {isSelected && (
                      <div className="w-3 h-3 bg-main rounded-full"></div>
                    )}
                  </div>
                </label>
              )}
            />
          </div>
        )}
      </div>
    )
  }
  return (
    <>
      <div className="likeCommentWatch  my-[40px] z-999">
        <div className="flex items-center md:mb-[16px] gap-4 ">
          <p className="font-bold text-[18px]">영상 시작점 인트로 화면</p>
          <p className="flex  items-center gap-1 ">
            <span
              className="font-bold text-white text-[11px] px-1 py-[2px] rounded-[4px]"
              style={{
                background: `linear-gradient(to right,#6095FF,#00CBB3)`,
              }}
            >
              UPDATE
            </span>
            {!isMobile && (
              <span className="text-[14px] text-[#5E6068]">
                인트로 클립으로 영상을 소개하세요!
              </span>
            )}
          </p>
        </div>
        {isMobile && (
          <div className="mb-[16px] md:mb-0">
            <p className="text-[14px] text-[#5E6068]">
              인트로 클립으로 영상을 소개하세요!
            </p>
          </div>
        )}
        <div className="flex gap-4 md:items-center md:flex-row flex-col">
          <div className="selected_video ">{renderSelectedItem()}</div>
          {/* 좋구알 비디오 리스트  */}
          <div
            className={classNames('', {
              'flex md:flex-row flex-col gap-[24px] md:gap-1 justify-between bg-[#EEF1F7] md:h-[118px]  px-[10px] py-5 rounded-[10px]':
                !isMobile,
              'grid grid-cols-2 gap-x-[27px] gap-y-5 bg-[#EEF1F7]  px-[10px]  py-5 rounded-[10px] ':
                isMobile,
            })}
          >
            {introList?.map((intro, index) => {
              if (index <= (isMobile ? 3 : 3))
                return <>{renderIntroItem(intro, index)}</>

              return <> </>
            })}
          </div>
        </div>
      </div>
      <Modal isModal={isIntroModal}>
        <div className="h-screen w-full fixed left-0 top-0 flex flex-col justify-center items-center bg-black bg-opacity-50 text-center  z-50">
          <div className="bg-white md:w-[816px] w-[335px] md:h-[670px] h-[630px] shadow-main rounded-[20px] md:p-8  p-4 flex flex-col   gap-4 ">
            <div className="flex flex-col h-[480px]  gap-4  ">
              <p className="flex justify-between items-center">
                <span className="font-bold text-[24px] text-left">
                  영상 시작점 인트로 화면
                </span>
                <button
                  onClick={() => {
                    setIntroModal(false)
                  }}
                  className=""
                >
                  <img
                    src="assets/img/Icon_x.png"
                    alt="icon_x"
                    height={40}
                    width={40}
                  />
                </button>
              </p>
              <p className="w-full text-left">
                인트로 클립으로 영상을 소개하세요! <br />
                인트로 영상에 있는 제목 영역에는 썸네일 생성시 입력된 내용이
                들어갑니다.
                <br /> 효과 선택시
                <span className="font-bold text-main"> 상품 영상 롱폼</span>에
                적용됩니다. (숏폼에는 적용되지 않아요)
              </p>
              <div className="flex justify-between items-center flex-col gap-4 scrollbar-visible h-[500px]">
                <div className="flex w-full justify-between items-center">
                  <div className="flex gap-2 items-center">
                    <p>
                      총{` `}
                      <span className="font-bold text-main">
                        {introList?.length}개
                      </span>
                    </p>
                  </div>
                </div>
                <div className=" grid md:grid-cols-5  md:gap-x-[11.5px] md:gap-y-6   grid-cols-2  gap-x-[10px] gap-y-5">
                  {introList?.map((intro, index) => {
                    if (intro.name === '없음') {
                      return (
                        <div
                          className="flex  md:flex-col  relative w-[138px] h-[78px] justify-center"
                          key={index}
                          style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          }}
                          onClick={() => {
                            setSelectedIntroIndex(index)
                            setValue('introId', intro.id)
                          }}
                        >
                          <button
                            type="button"
                            onClick={() => {
                              handleVideoEnded(index)
                            }}
                            className=" text-white font-bold z-50"
                          >
                            인트로 없음
                          </button>
                          <div
                            className={classNames(
                              'absolute w-[138px] h-[78px] flex  justify-center',
                              {
                                'outline-[4px] outline outline-main':
                                  intro.id === watch('introId'),
                              }
                            )}
                          >
                            <div className="flex items-start h-full  pt-1   mt-[6px] ml-[6px] ">
                              <PlanTag
                                planName={translatePlanName(intro?.planName)}
                                className=""
                              />
                            </div>
                            <Controller
                              render={({ field }: any) => (
                                <label className="relative flex w-full  cursor-pointer  items-end  justify-end mr-1 mb-1 ">
                                  <input
                                    type="checkbox"
                                    className="sr-only"
                                    checked={watch('introId') === intro.id}
                                    disabled={
                                      !userInfo ||
                                      userInfo?.userType === 'normalUser' ||
                                      userInfo?.userType === 'lightUser'
                                    }
                                    onChange={() => {
                                      setSelectedIntroIndex(index)
                                      setValue('introId', intro.id)
                                    }}
                                  />
                                  <div
                                    className={`w-[20px] h-[20px] rounded-full border-2 bg-main flex items-center justify-center ${
                                      watch('introId') === intro.id
                                        ? 'bg-white border-main'
                                        : 'bg-white'
                                    }`}
                                  >
                                    {watch('introId') === intro.id && (
                                      <div className="w-3 h-3 bg-main rounded-full"></div>
                                    )}
                                  </div>
                                </label>
                              )}
                              name="introId"
                              control={control}
                            />
                          </div>
                        </div>
                      )
                    }

                    return (
                      <>
                        <div
                          className={`flex  md:flex-col  relative w-[138px] h-[78px]  video_${index}`}
                          key={index}
                          onMouseEnter={
                            !isMobile
                              ? () => handlePlayButtonClick(index)
                              : undefined
                          }
                          onMouseLeave={
                            !isMobile
                              ? () => handleVideoEnded(index)
                              : undefined
                          }
                          onClick={
                            isMobile
                              ? () => handlePlayButtonClick(index)
                              : undefined
                          }
                        >
                          <VideoComponent
                            videoRefs={videoRefs}
                            src={intro.path}
                            className={classNames({
                              ' outline outline-[4px] outline-main':
                                intro.id === watch('introId'),
                            })}
                            imagePath={intro.imagePath}
                            index={index}
                            selected={true}
                            videoPlaying={videoPlaying}
                          />

                          <>
                            <div
                              style={{
                                backgroundColor:
                                  watch('introId') === intro.id
                                    ? 'rgba(0, 0, 0, 0.5)'
                                    : '',
                              }}
                              className={classNames(
                                'absolute w-[138px] h-[78px] flex'
                              )}
                              onClick={() => {
                                setSelectedIntroIndex(index)
                                setValue('introId', intro.id)
                              }}
                            >
                              <div className="flex items-start h-full  pt-1   mt-[6px] ml-[6px] ">
                                <PlanTag
                                  planName={translatePlanName(intro?.planName)}
                                  className=""
                                />
                              </div>
                              <Controller
                                render={({ field }: any) => (
                                  <label className="relative flex w-full  cursor-pointer  items-end  justify-end mr-1 mb-1 ">
                                    <input
                                      type="checkbox"
                                      className="sr-only"
                                      checked={watch('introId') === intro.id}
                                      disabled={
                                        !userInfo ||
                                        userInfo?.userType === 'normalUser' ||
                                        userInfo?.userType === 'lightUser'
                                      }
                                      onChange={() => {
                                        setSelectedIntroIndex(index)
                                        setValue('introId', intro.id)
                                      }}
                                    />
                                    <div
                                      className={`w-[20px] h-[20px] rounded-full border-2 bg-main flex items-center justify-center ${
                                        watch('introId') === intro.id
                                          ? 'bg-white border-main'
                                          : 'bg-white'
                                      }`}
                                    >
                                      {watch('introId') === intro.id && (
                                        <div className="w-3 h-3 bg-main rounded-full"></div>
                                      )}
                                    </div>
                                  </label>
                                )}
                                name="introId"
                                control={control}
                              />
                            </div>
                          </>
                        </div>
                      </>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="py-5">
              <button
                className="bg-main rounded-full w-full text-white font-bold h-[60px]"
                onClick={() => {
                  setIntroModal(false)
                }}
              >
                저장
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
