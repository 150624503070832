import { useQuery } from '@tanstack/react-query'
import { getLanguages, getSourceType } from 'service/list'

function useGetLanguages() {
  return useQuery({
    queryKey: ['get-languages'],
    queryFn: () => getLanguages(),
    staleTime: 6 * 1000,
  })
}

export default useGetLanguages
