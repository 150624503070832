import { useQuery } from '@tanstack/react-query'
import { getSourceType } from 'service/list'

function useGetSourceType() {
  return useQuery({
    queryKey: ['get-source-type'],
    queryFn: () => getSourceType(),
    staleTime: 6 * 1000,
  })
}

export default useGetSourceType
