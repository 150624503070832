import classNames from 'classnames'
import { PaymentModal } from 'components/payment/PaymentModal'
import useGetPlansPrice from 'hooks/query/plans/useGetPlansPrice'
import useGetPaymentOrderRequests from 'hooks/query/useGetPaymentOrderRequests'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  AffiliateBenefit,
  CouponBenefit,
  DefaultBenefit,
  InviteBenefit,
  PaymentOrderRequest,
} from 'service/payment'
import { subscriptionType } from 'service/plans'
import { UserType } from 'utils/common'

export const AnnualUpgradeBanner = (props: any) => {
  const { userInfo, classNameProps, selectedPlanId, setBrandPayModal } = props

  const navigate = useNavigate()
  const { mutate: getPaymentOrderRequestsMutate } = useGetPaymentOrderRequests()

  const [monthPrice, setMonthPrice] = useState(0)
  const [selectedPlan, setSelectedPlan] = useState('')
  const [isUpgrade, setUpgrade] = useState(false)
  const [paymentModal, setPaymentModal] = useState<boolean>(false)
  const [isExist, setExist] = useState<boolean>(false)
  const [planId, setPlanId] = useState(0)
  const [type, setType] = useState('annual')
  const [text, setText] = useState('')
  const [saveMoneyByPlan, setSaveMoneyByPlan] = useState(0)
  const [couponBenefit, setCouponBenefit] = useState<null | CouponBenefit>(null)
  const [paymentOrderRequest, setPaymentOrderRequest] =
    useState<PaymentOrderRequest>({
      orderId: '',
      productName: '',
      subscriptionType: '',
      price: 0,
      vat: 0,
      id: 0,
    })

  const {
    isLoading: priceIsLoading,
    data: priceData,
    refetch: priceRefetch,
  } = useGetPlansPrice(type)

  const [affiliateBenefit, setAffiliateBenefit] =
    useState<null | AffiliateBenefit>(null)
  const [inviteeBenefit, setInviteBenefit] = useState<null | InviteBenefit>(
    null
  )
  const [defaultBenefit, setDefaultBenefit] = useState<null | DefaultBenefit>(
    null
  )

  const selectBg = (planName: string) => {
    if (planName === 'LIGHT' || planName === '라이트') {
      return 'rgba(96, 184, 251, 1),rgba(11, 123, 209, 1),rgba(156, 224, 255, 1)'
    } else if (planName === 'PREMIUM' || planName === '프리미엄') {
      return 'rgba(0, 128, 130, 1),rgba(0, 94, 94, 1),rgba(29, 197, 194, 1)'
    } else if (planName === 'PRO' || planName === '프로') {
      return 'rgba(96, 16, 157, 1),rgba(14, 0, 94, 1),rgba(190, 73, 198, 1)'
    } else if (planName === 'FREE' || planName === '무료') {
      return 'rgba(128, 127, 123, 1),rgba(60, 68, 68, 1),rgba(165, 179, 177, 1)'
    }
    return 'rgba(0, 128, 130, 1),rgba(0, 94, 94, 1),rgba(29, 197, 194, 1)'
  }

  const handlePromotion = () => {
    const [annualPlan] =
      priceData?.prices?.filter((el) => el.id === selectedPlanId) ?? []

    if (annualPlan?.name === '프리미엄') {
      setText(`${annualPlan?.name} 1년 40% 할인받기`)
      setType(subscriptionType.ANNUAL)
      setSaveMoneyByPlan(268900)
      setSelectedPlan(annualPlan?.name)
      setExist(true)
      setPlanId(annualPlan?.id)

      return
    } else if (annualPlan?.name === '프로') {
      setText(`${annualPlan?.name} 1년 40% 할인받기`)
      setType(subscriptionType.ANNUAL)
      setSaveMoneyByPlan(212900)
      setSelectedPlan(annualPlan?.name)
      setExist(true)
      setPlanId(annualPlan?.id)
      return
    } else if (annualPlan?.name === '라이트') {
      setText(`${annualPlan?.name} 1년 40% 할인받기`)
      setType(subscriptionType.ANNUAL)
      setSaveMoneyByPlan(68900)
      setSelectedPlan(annualPlan?.name)
      setExist(true)
      setPlanId(annualPlan?.id)
      return
    }
  }

  const getPaymentOrderRequests = () => {
    const [annualPlan] =
      priceData?.prices?.filter((el) => el.id === selectedPlanId) ?? []

    if (
      annualPlan &&
      annualPlan.planPrice[0]?.subscriptionType === subscriptionType.ANNUAL
    ) {
      const planPriceId = annualPlan.planPrice[0]?.id
      if (planId) {
        getPaymentOrderRequestsMutate(
          { planId, planPriceId },
          {
            onSuccess: (data) => {
              setPaymentOrderRequest(data.orderRequest)
              if (data.affiliateBenefit) {
                setAffiliateBenefit(data.affiliateBenefit)
              }
              if (data?.couponBenefit) {
                setCouponBenefit(data.couponBenefit)
              }
              if (data?.inviteBenefit) {
                setInviteBenefit(data.inviteBenefit)
              }
              if (data?.defaultBenefit && type === 'annual') {
                setDefaultBenefit(data.defaultBenefit)
              }

              setPaymentModal(true)
            },
            onError: (e) => {
              console.log(e)
            },
          }
        )
      }
    }
  }

  useEffect(() => {
    handlePromotion()
  }, [paymentModal, priceData, userInfo])

  return (
    <>
      {isExist ? (
        <>
          <div
            className="change_annual_modal flex flex-col my-11 gap-4 bg-[#F6F8FB] rounded-[20px] py-6 px-8 cursor-pointer"
            onClick={() => {
              getPaymentOrderRequests()
            }}
          >
            <p className="text-[20px]">
              연 결제 하면
              <br />
              <span className="font-bold">
                {saveMoneyByPlan.toLocaleString()}원 할인
              </span>
              {` `}
              받을 수 있어요!
            </p>
            <button
              className={classNames('text-[14px]', {
                'w-full h-[34px]  rounded-[10px] text-white font-bold':
                  !classNameProps,
                'w-full h-[46px]  rounded-full text-white font-bold':
                  classNameProps,
              })}
              style={{
                background: `linear-gradient(to right, ${selectBg(
                  selectedPlan
                )})`,
              }}
            >
              {' '}
              {text ?? ''}
            </button>
          </div>
        </>
      ) : (
        <></>
      )}
      {paymentModal && (
        <PaymentModal
          setPaymentModal={setPaymentModal}
          paymentOrderRequest={paymentOrderRequest}
          affiliateBenefit={affiliateBenefit}
          couponBenefit={couponBenefit}
          setCouponBenefitData={setCouponBenefit}
          setAffiliateBenefitData={setAffiliateBenefit}
          affiliatePlan={userInfo?.affiliate?.plan}
          inviteBenefit={inviteeBenefit}
          userInfo={userInfo}
          planId={planId}
          type={'annual'}
          upgrade={isUpgrade}
          monthPrice={monthPrice}
          defaultBenefit={defaultBenefit}
          setDefaultBenefitData={setDefaultBenefit}
          setBrandPayModal={setBrandPayModal}
        />
      )}
    </>
  )
}
