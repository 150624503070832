import { useQuery } from '@tanstack/react-query'
import {
  getLanguages,
  getSourceType,
  getVoiceLanguageGroup,
} from 'service/list'

function useGetVoiceLanguageGroup(id: number) {
  return useQuery({
    queryKey: ['get-voice-language-group', id],
    queryFn: () => getVoiceLanguageGroup(id),
    staleTime: 6 * 1000,
    enabled: !!id,
  })
}

export default useGetVoiceLanguageGroup
