import { useQueries } from '@tanstack/react-query'
import { getLinkDetailById } from 'service/list'

function useGetLinkDetail(ids: number[]) {
  return useQueries({
    queries: ids.map((id) => ({
      queryKey: ['link', id], // 동적 쿼리 키
      queryFn: () => getLinkDetailById(id), // 각 id에 대한 데이터 요청
      enabled: ids.length > 0, // ids 배열이 비어 있지 않으면 요청 시작
    })),
  })
}

export default useGetLinkDetail
