import { useQuery } from '@tanstack/react-query'
import { getMe, getMePlan } from '../../service/user'
import {
  getShortUsageRequest,
  getTextShortsUsageRequest,
  getUsageRequest,
} from '../../service/list'

function useGetTextContentRequest(page: number, pageSize: number) {
  return useQuery({
    queryKey: ['get-text-short-usage-request'],
    queryFn: () => getTextShortsUsageRequest(page, pageSize),
    refetchOnWindowFocus: true,
    retry: false,
    initialData: { totalCount: 0, data: [] },
  })
}

export default useGetTextContentRequest
