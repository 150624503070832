import classNames from 'classnames'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'

export const PayBackBanner = (props: any) => {
  const { width, gap } = props
  const navigate = useNavigate()
  const moveToChallnege = () => {
    navigate('/challenge')
  }
  return isMobile ? (
    <>
      <div
        className={classNames(
          `bg-[#010101] flex w-[375px] px-[14px] py-2 gap-2`
        )}
      >
        <div className="flex flex-col justify-center">
          <p className="font-[800] font-[Tenada] text-white text-[20px]">
            1년 무료! 페이백 챌린지
          </p>
          <p className="font-[500] text-white text-[14px]">
            <span className="font-bold">선착순 1,000명 한정</span> <br /> 수익
            2배의 기회를 누리세요!
          </p>
        </div>
        <div className="flex flex-col justify-center items-end">
          <img
            src="https://s3.ap-northeast-2.amazonaws.com/pub.angelleague.io/fast_cut_front/challenge/payback_100.jpg"
            width={68}
            height={45}
            alt="payback"
          />
          <button
            className="w-[146px] h-[38px] rounded-[60px] font-bold border-2px border-white"
            style={{
              background: 'linear-gradient(to bottom, #EDE093,#E5A907)',
            }}
            onClick={moveToChallnege}
          >
            선착순 페이백 받기
          </button>
        </div>
      </div>
    </>
  ) : (
    <>
      <div
        className={classNames(
          `bg-[#010101] flex  h-[128px]  w-[1000px] rounded-[20px] gap-[136px] justify-center items-center`
        )}
      >
        <div>
          <p className="font-[800] font-[Tenada] text-white text-[32px]">
            1년 무료! 페이백 챌린지
          </p>
          <p className="font-[500] text-white text-[18px]">
            <span className="font-bold">선착순 1,000명 한정</span> <br /> 수익
            2배의 기회를 누리세요!
          </p>
        </div>
        <div>
          <img
            src="https://s3.ap-northeast-2.amazonaws.com/pub.angelleague.io/fast_cut_front/challenge/payback_100.jpg"
            width={192}
            height={128}
            alt="payback"
          />
        </div>
        <div>
          <button
            className="w-[150px] h-[50px] rounded-[60px] font-bold"
            style={{
              background: 'linear-gradient(to bottom, #EDE093,#E5A907)',
            }}
            onClick={moveToChallnege}
          >
            챌린지 상세보기
          </button>
        </div>
      </div>
    </>
  )
}

export const PayBackBannerHome = (props: any) => {
  const { width, gap } = props
  const navigate = useNavigate()
  const moveToChallnege = () => {
    navigate('/challenge')
  }

  return isMobile ? (
    <>
      <div
        className={classNames(
          `bg-[#010101] flex flex-col  h-[319px]  w-[375px]   items-center justify-center `
        )}
      >
        <div>
          <p className="font-[800] font-[Tenada] text-white text-[32px] text-center">
            1년 무료! 페이백 챌린지
          </p>
          <p className="font-[500] text-white text-[18px] text-center">
            <span className="font-bold">선착순 1,000명 한정</span> <br /> 수익
            2배의 기회를 누리세요!
          </p>
        </div>
        <div>
          <img
            src="https://s3.ap-northeast-2.amazonaws.com/pub.angelleague.io/fast_cut_front/challenge/payback_100.jpg"
            width={192}
            height={128}
            alt="payback"
          />
        </div>
        <div>
          <button
            className="w-[335px] h-[50px] rounded-[60px] font-bold border-2px border-white"
            style={{
              background: 'linear-gradient(to bottom, #EDE093,#E5A907)',
            }}
            onClick={moveToChallnege}
          >
            선착순 페이백 받기
          </button>
        </div>
      </div>
    </>
  ) : (
    <>
      <div
        className={classNames(
          `bg-[#010101] flex  h-[128px]  w-[816px] rounded-[20px] gap-[68.5px] justify-center items-center`
        )}
      >
        <div>
          <p className="font-[800] font-[Tenada] text-white text-[32px]">
            1년 무료! 페이백 챌린지
          </p>
          <p className="font-[500] text-white text-[18px]">
            <span className="font-bold">선착순 1,000명 한정</span> <br /> 수익
            2배의 기회를 누리세요!
          </p>
        </div>
        <div>
          <img
            src="https://s3.ap-northeast-2.amazonaws.com/pub.angelleague.io/fast_cut_front/challenge/payback_100.jpg"
            width={192}
            height={128}
            alt="payback"
          />
        </div>
        <div>
          <button
            className="w-[150px] h-[50px] rounded-[60px] font-bold"
            style={{
              background: 'linear-gradient(to bottom, #EDE093,#E5A907)',
            }}
            onClick={moveToChallnege}
          >
            선착순 페이백 받기
          </button>
        </div>
      </div>
    </>
  )
}

export const PayBackBannerVideo = (props: any) => {
  const { width, gap } = props
  const navigate = useNavigate()
  const moveToChallnege = () => {
    navigate('/challenge')
  }

  return isMobile ? (
    <>
      <div
        className={classNames(
          `bg-[#010101] flex flex-col  h-[157px]  w-[375px]   items-center justify-center p-5 gap-2`
        )}
      >
        <div className="flex justify-between w-full ">
          <div className="flex flex-col w-full">
            <p className="font-[800] font-[Tenada] text-white text-[20px] text-left">
              1년 무료! 페이백 챌린지
            </p>
            <p className="font-[500] text-white text-[14px] text-leftr">
              <span className="font-bold">선착순 1,000명 한정</span> <br /> 다시
              없을 특별한 혜택을 누리세요!
            </p>
          </div>
          <img
            src="https://s3.ap-northeast-2.amazonaws.com/pub.angelleague.io/fast_cut_front/challenge/payback_100.jpg"
            width={87}
            height={58}
            alt="payback"
          />
        </div>

        <div>
          <button
            className="w-[335px] h-[50px] rounded-[60px] font-bold border-2px border-white"
            style={{
              background: 'linear-gradient(to bottom, #EDE093,#E5A907)',
            }}
            onClick={moveToChallnege}
          >
            선착순 페이백 받기
          </button>
        </div>
      </div>
    </>
  ) : (
    <>
      <div
        className={classNames(
          `bg-[#010101] flex  h-[128px]  w-[816px] rounded-[20px] gap-[68.5px] justify-center items-center`
        )}
      >
        <div>
          <p className="font-[800] font-[Tenada] text-white text-[32px]">
            1년 무료! 페이백 챌린지
          </p>
          <p className="font-[500] text-white text-[18px]">
            <span className="font-bold">선착순 1,000명 한정</span> <br /> 수익
            2배의 기회를 누리세요!
          </p>
        </div>
        <div>
          <img
            src="https://s3.ap-northeast-2.amazonaws.com/pub.angelleague.io/fast_cut_front/challenge/payback_100.jpg"
            width={192}
            height={128}
            alt="payback"
          />
        </div>
        <div>
          <button
            className="w-[150px] h-[50px] rounded-[60px] font-bold"
            style={{
              background: 'linear-gradient(to bottom, #EDE093,#E5A907)',
            }}
            onClick={moveToChallnege}
          >
            선착순 페이백 받기
          </button>
        </div>
      </div>
    </>
  )
}
