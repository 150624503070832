import useUserStore from 'store/useUserStore'
import classNames from 'classnames'
import { Input } from 'components/atom/Input'
import { TextArea } from 'components/atom/TextArea'
import useConfirmPoint from 'hooks/query/point/useConfirmPoint'
import { useShortVideoData } from 'hooks/useShortVideoData'
import React, {
  CSSProperties,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { isMobile } from 'react-device-detect'
import { Controller, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { getShortImageList, ShortVideoInfo } from 'service/list'
import {
  getGridItemStyle,
  getLocalStorage,
  saveLocalStorage,
  styles,
} from 'utils/common'
import { contentVideoFormValues } from 'utils/type'
import ShortVideoTitleTooltip from '../ShortVideoTitleTooltip'
import { CircularCheckbox } from 'components/CircularCheckbox'
import { ShadowBox } from 'components/ShadowBox'
import { CSSTransition } from 'react-transition-group'
import ContentTooltip from '../ContentToolitip'
import { ShortBgmDisplay } from 'components/video/factor/ShortBgmDisplay'
import { clickLogo } from 'utils/metaAd'
import AnimationTitleTooltip from '../AnimationTitleTooltip'
import { SubtitleDisplay } from '../SubtitleDisplay'
import { AiScriptDisplay } from '../AiScriptDisplay'
import { Modal } from 'components/layout/Modal'
import CashUseModal from 'components/CashUseModal'
import { Spinner } from 'components/atom/Spinner'
import { VideoTypeModal } from 'components/VideoTypeModal'
import { MixTypeModal } from 'components/MixTypeModal'
import { ImageTypeModal } from 'components/ImageTypeModal'
import ConfirmPaymentCashModal from 'components/payment/ConfirmPaymentCashModal'

interface LinkTypeProps {
  tab: 'link' | 'text'
  setTab: Dispatch<SetStateAction<'link' | 'text'>>
  changeTab: (string: 'link' | 'text') => void
}
export const LinkType = ({
  tab,
  setTab,
  changeTab,
}: PropsWithChildren<LinkTypeProps>) => {
  const [promptStatus, setPromptStatus] = useState<boolean[]>([])

  const urlParams = new URLSearchParams(window.location.search)

  const paymentKey = urlParams.get('paymentKey')
  const orderId = urlParams.get('orderId')
  const amount = urlParams.get('amount')
  const bonus = urlParams.get('bonus')
  const pType = urlParams.get('pType')
  const mType = urlParams.get('mType')
  const memory = urlParams.get('m')
  const promotionCode = urlParams.get('promotionCode')
  const valid = urlParams.get('valid')

  const PRODUCT_NAME_PATTERN = /^[^<>\uD800-\uDFFF]*$/

  const shortVideoData = getLocalStorage('shortVideoData')

  const {
    myPointData,
    getMyPointRefetch,
    bgmList,
    subTitleList,
    voiceList,
    toneList,
    animationList,
    aiPromptDurationList,
    bgList,
    templateList,
    voiceTags,
    subtitleColorList,
    tempoList,
  } = useShortVideoData()
  const { mutate: confirmPointMutate, isPending } = useConfirmPoint()
  const navigate = useNavigate()
  const userInfo = useUserStore((state: any) => state.userInfo)

  const userCheck = () => {
    if (!userInfo) {
      navigate('/sign-up')
      return
    }
  }
  const location = useLocation()
  const { pathname } = useLocation()
  const [isCrawlingLoading, setCrawlingLoading] = useState<boolean>(false)
  const [isSuccess, setSuccess] = useState<boolean>(false)
  const [isRequest, setRequest] = useState<boolean>(true)
  const [saveSuccessModal, setSaveSuccessModal] = useState<boolean>(false)
  const [essentialCheck, setEssentialCheck] = useState<boolean>(false)
  const [isUseModal, setUseModal] = useState<boolean>(false)
  const [isModal, setModal] = useState<boolean>(false)
  const [isImageTypeModal, setImageTypeModal] = useState<boolean>(false)
  const [isMixTypeModal, setMixTypeModal] = useState<boolean>(false)
  const [isVideoTypeModal, setVideoTypeModal] = useState<boolean>(false)
  const [hasLinkError, setHasLinkError] = useState<boolean>(false)

  const [amountValue, setAmountValue] = useState(0)
  const [bonusValue, setBonusValue] = useState(0)
  const [errorText, setErrorText] = useState<string>('')
  const [errorCode, setErrorCode] = useState<number>(0)

  const [shortVideoInfo, setShortVideoInfo] = useState<ShortVideoInfo>({
    mainTitle: '',
    link: '',
    backgroundId: 0,
    aiToneTypeId: 0,
    bgmId: 0,
    voiceId: 0,
    animationId: 0,
    templateId: 0,
    subtitleId: 0,
    aiPromptDurationId: 0,
    amount: 0,
    subtitleColorId: 0,
    tempoId: 0,
    urls: [],
    files: null,
  })

  const [shortVideoPriceInfo, setShortVideoPriceInfo] = useState({
    bgPrice: 0,
    bgmPrice: 0,
    voicePrice: 0,
    templatePrice: 0,
    subtitlePrice: 0,
  })

  //tooltip 관련 useState
  const [contentTooltipStatus, setContentTooltipStatus] =
    useState<boolean>(false)
  const [titleTooltip, setTitleTooltip] = useState<boolean>(false)
  const [animationTooltip, setAnimationTooltip] = useState<boolean>(false)
  const [bgmTooltip, setBgmTooltip] = useState<boolean>(false)
  const [subtitleTooltip, setSubtitleTooltip] = useState<boolean>(false)

  const [images, setImages] = useState<string[]>([])
  const [uploadImages, setUploadImages] = useState<File[]>([])
  const [selectedImages, setSelectedImages] = useState(
    new Array(images.length).fill(true)
  )
  const [selectedUploadImages, setSelectedUploadImages] = useState(
    new Array(uploadImages.length).fill(true)
  )

  // inputRef
  const mainTitleInputRef = useRef<null | HTMLInputElement>(null)

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<contentVideoFormValues>({
    defaultValues: {
      link: '',
      mainTitle: '',
      animationId: 0,
      voiceId: 0,
      bgmId: 0,
      backgroundId: 0,
      templateType: '',
      templateId: 0,
      aiToneTypeId: 0,
      subtitleId: 0,
      aiPromptDurationId: 0,
      subtitleColorId: 0,
    },
  })

  const linkValue = watch('link')

  useEffect(() => {
    if (linkValue === '') {
      setImages([]) // link 값이 빈 문자열이 될 때 이미지를 초기화
      setSelectedImages([]) // link 값이 빈 문자열이 될 때 이미지를 초기화
      setUploadImages([])
      setSelectedUploadImages([]) // link 값이 빈 문자열이 될 때 이미지를 초기화
    }
  }, [linkValue])

  const onSubmit = async (data: any) => {
    const selectedImageData = images.filter((_, index) => selectedImages[index])
    const selectedUploadImageData = uploadImages.filter(
      (_, index) => selectedUploadImages[index]
    )

    const urls = selectedImageData || []
    const files = selectedUploadImageData || []

    userCheck()
    let {
      mainTitle,
      link,
      voiceId,
      bgmId,
      animationId,
      aiToneTypeId,
      backgroundId,
      templateId,
      subtitleId,
      templateType,
      aiPromptDurationId,
      subtitleColorId,
      tempoId,
    } = data

    const prevInfo = {
      mainTitle,
      link,
      voiceId,
      bgmId,
      aiToneTypeId,
      backgroundId,
      templateId,
      subtitleId,
      templateType,
      animationId,
      aiPromptDurationId,
      subtitleColorId,
      tempoId,
    }
    saveLocalStorage('shortVideoData', prevInfo)
    mainTitle = mainTitle.trim()

    // 가격 정보 및 계산(배경영상, 배경음악, 목소리)
    const bgInfo = bgList?.filter((el) => el.id === backgroundId)
    const bgmInfo = bgmList?.filter((el) => el.id === bgmId)
    const voiceInfo = voiceList?.filter((el) => el.id === voiceId)
    const subtitleInfo = subTitleList?.filter((el) => el.id === subtitleId)

    const temType: 'clip' | 'image' | 'mix' = templateType
    let templateInfo = templateList
      ? templateList[temType]?.list.filter((el) => el.id === templateId)
      : []

    if (temType === 'image' && !templateInfo.length) {
      templateInfo = templateList
        ? templateList['etc']?.list.filter((el) => el.id === templateId)
        : []
    }

    setShortVideoPriceInfo({
      bgPrice:
        backgroundId !== 0 && bgInfo ? Number(bgInfo[0]?.originPrice) : 0,
      bgmPrice: bgmInfo ? Number(bgmInfo[0].originPrice) : 0,
      voicePrice: voiceInfo ? Number(voiceInfo[0].originPrice) : 0,
      templatePrice: templateInfo ? Number(templateInfo[0].originPrice) : 0,
      subtitlePrice: subtitleInfo ? Number(subtitleInfo[0].originPrice) : 0,
    })

    const shortVideoInfo = {
      mainTitle,
      link,
      voiceId,
      bgmId,
      animationId,
      backgroundId,
      templateId,
      subtitleId,
      subtitleColorId,
      aiToneTypeId,
      aiPromptDurationId,
      tempoId,
      urls,
      amount: 0,
      files,
    }

    setShortVideoInfo(shortVideoInfo)
    setUseModal(true)
  }

  const [isOpen, setIsOpen] = useState(true)

  const toggleContent = () => {
    setIsOpen(!isOpen)
  }

  const [voicePlaying, setVoicePlaying] = useState<boolean[]>(
    new Array(voiceList?.length).fill(false)
  )

  const [tempoPlaying, setTempoPlaying] = useState<boolean[]>(
    new Array(tempoList?.length).fill(false)
  )

  const [bgmPlaying, setBgmPlaying] = useState<boolean[]>(
    new Array(bgmList?.length).fill(false)
  )

  const [toneTooltip, setToneTooltip] = useState<boolean[]>([])

  //여러개 tooltip 관리

  const btnRef = useRef<HTMLButtonElement>(null)

  const voiceRefs = useRef<(HTMLAudioElement | null)[]>([])
  const bgmRefs = useRef<(HTMLAudioElement | null)[]>([])

  const handleBgmPlayButtonClick = (status: boolean, index: number) => {
    const updatedPlayingState = bgmPlaying.map((value, idx) =>
      idx === index ? status : false
    )
    setBgmPlaying(updatedPlayingState)

    if (bgmRefs.current[index]) {
      if (status) {
        bgmRefs.current[index]?.play()
      } else {
        bgmRefs.current[index]?.pause()
      }
    }
  }

  const setVideoData = () => {
    if (shortVideoData && userInfo) {
      const {
        mainTitle,
        link,
        voiceId,
        bgmId,
        aiToneTypeId,
        backgroundId,
        templateId,
        templateType,
        animationId,
        aiPromptDurationId,
        subtitleId,
        subtitleColorId,
        tempoId,
      } = shortVideoData

      if (memory === 'true') {
        setValue('mainTitle', mainTitle)
        setValue('link', link)
      }
      if (backgroundId) {
        setValue('backgroundId', backgroundId)
      }

      if (subtitleId) {
        setValue('subtitleId', subtitleId)
      } else if (!subtitleId || Number(templateId) === 0) {
        if (subTitleList) {
          setValue('subtitleId', subTitleList[0].id)
        }
      }

      if (templateId) {
        setValue('templateId', templateId)
      } else if (!templateId || Number(templateId) === 0) {
        if (templateList) {
          setValue('templateId', templateList.mix.list[0].id)
          setValue('templateType', 'mix')
        }
      }

      if (templateType) {
        setValue('templateType', templateType)
      }

      if (animationId) {
        setValue('animationId', animationId)
      }

      if (aiToneTypeId) {
        if (toneList) {
          const [tone] = toneList?.filter((el) => el?.id === aiToneTypeId)
          if (tone) {
            setValue('aiToneTypeId', aiToneTypeId)
          } else {
            setValue('aiToneTypeId', toneList[0]?.id)
          }
        }
      } else if (toneList) {
        setValue('aiToneTypeId', toneList[0]?.id)
      }

      if (voiceId) {
        setValue('voiceId', voiceId)
      }

      if (bgmId) {
        setValue('bgmId', bgmId)
      }
      if (aiPromptDurationId) {
        if (aiPromptDurationList) {
          setValue('aiPromptDurationId', aiPromptDurationList[1].id)
        }
      }
      if (subtitleColorId) {
        setValue('subtitleColorId', subtitleColorId)
      } else if (subtitleColorList) {
        setValue('subtitleColorId', subtitleColorList[0]?.id)
      }

      if (tempoId) {
        setValue('tempoId', tempoId)
      } else if (tempoList) {
        setValue('tempoId', tempoList[0]?.id)
      }
    } else {
      if (toneList?.length) {
        setToneTooltip(new Array(toneList.length).fill(false))
        setValue('aiToneTypeId', toneList[0].id)
      }

      if (voiceList) {
        setValue('voiceId', voiceList[0].id)
      }
      if (templateList) {
        setValue('templateId', templateList.mix.list[0].id)
        setValue('templateType', 'mix')
      }
      if (bgList) {
        setValue('backgroundId', bgList[0].id)
      }
      if (animationList) {
        setValue('animationId', animationList[1].id)
      }
      if (bgmList) {
        setValue('bgmId', bgmList[0].id)
      }

      if (subTitleList) {
        setValue('subtitleId', subTitleList[0].id)
      }

      if (aiPromptDurationList) {
        setValue('aiPromptDurationId', aiPromptDurationList[1].id)
      }
      if (subtitleColorList) {
        setValue('subtitleColorId', subtitleColorList[0]?.id)
      }

      if (tempoList) {
        setValue('tempoId', tempoList[0]?.id)
      }
    }
  }
  useEffect(() => {
    voiceRefs.current.forEach((audioRef, index) => {
      if (audioRef && !voicePlaying[index]) {
        audioRef.pause()
        audioRef.currentTime = 0
      }
    })
  }, [voicePlaying])

  useEffect(() => {
    bgmRefs.current.forEach((audioRef, index) => {
      if (audioRef && !bgmPlaying[index]) {
        audioRef.pause()
        audioRef.currentTime = 0
      }
    })
  }, [bgmPlaying])
  useEffect(() => {
    if (voiceList) {
      setVoicePlaying(new Array(voiceList?.length).fill(false))
    }
    if (bgmList) {
      setBgmPlaying(new Array(bgmList?.length).fill(false))
    }
    if (toneList) {
      setToneTooltip(new Array(toneList.length).fill(false))
    }
    if (tempoList) {
      setTempoPlaying(new Array(tempoList.length).fill(false))
    }
    setVideoData()
    if (amount && pType && bonus && pType === 'success') {
      setAmountValue(Number(amount) * (10 / 11))
      if (bonus) {
        setBonusValue(Number(bonus))
      }
      setModal(true)
    }
    if (mType === 'true') {
      if (btnRef.current) {
        btnRef.current.click()
        navigate(pathname)
      }
    }
  }, [
    location.search,
    userInfo,
    toneList,
    bgmList,
    voiceList,
    bgList,
    animationList,
    aiPromptDurationList,
    subTitleList,
  ])
  useEffect(() => {
    getMyPointRefetch()
  }, [])

  useEffect(() => {
    if (isImageTypeModal || isMixTypeModal || isVideoTypeModal) {
      document.body.classList.add('overflow-hidden') // Tailwind 유틸리티 클래스 사용
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [isImageTypeModal, isMixTypeModal, isVideoTypeModal])

  const handleCheckboxChange = (index: number) => {
    setSelectedImages((prev) => {
      const currentSelectionCount = prev.filter((selected) => selected).length
      const newSelection = !prev[index]
      prev.map((selected, i) => (i === index ? !selected : selected))
      // 이미 3개가 선택된 상태에서 추가 선택을 막습니다.
      if (currentSelectionCount >= 20 && newSelection) {
        return prev
      }
      return prev.map((selected, i) => (i === index ? newSelection : selected))
    })
  }
  const handleUploadCheckboxChange = (index: number) => {
    setSelectedUploadImages((prev) => {
      const currentSelectionCount = prev.filter((selected) => selected).length
      const newSelection = !prev[index]
      prev.map((selected, i) => (i === index ? !selected : selected))
      // 이미지 5개가 선택된 상태에서 추가 선택을 막습니다.
      if (currentSelectionCount >= 5 && newSelection) {
        return prev
      }
      return prev.map((selected, i) => (i === index ? newSelection : selected))
    })
  }
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files)
      setUploadImages((prevImages) => {
        const newImages = [...prevImages, ...selectedFiles]
        setSelectedUploadImages((prevSelected) => {
          const additionalSelectionCount = selectedFiles.length
          const currentSelectionCount = prevSelected.filter(
            (selected) => selected
          ).length

          // 추가된 파일이 최대 선택 개수를 초과하지 않도록 합니다.
          if (currentSelectionCount + additionalSelectionCount > 5) {
            const allowedSelectionCount = 5 - currentSelectionCount
            return [
              ...prevSelected,
              ...new Array(allowedSelectionCount).fill(true),
              ...new Array(selectedFiles.length - allowedSelectionCount).fill(
                false
              ),
            ]
          } else {
            return [
              ...prevSelected,
              ...new Array(selectedFiles.length).fill(true),
            ]
          }
        })
        return newImages
      })
    }
  }

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    document.getElementById('file-input')!.click()
  }

  const handleBlur = async (url: string) => {
    if (url) {
      try {
        const result = await getShortImageList(url)

        const { title, images } = result
        setValue('mainTitle', title)
        // setTitle(title);
        setImages(images)
        // setSelectedImages(new Array(images.length).fill(true))

        setSelectedImages((prevSelected) => {
          const additionalSelectionCount = images.length
          const currentSelectionCount = prevSelected.filter(
            (selected) => selected
          ).length

          // 추가된 파일이 최대 선택 개수를 초과하지 않도록 합니다.
          if (currentSelectionCount + additionalSelectionCount > 20) {
            const allowedSelectionCount = 20 - currentSelectionCount

            return [
              ...prevSelected,
              ...new Array(allowedSelectionCount).fill(true),
              ...new Array(images.length - allowedSelectionCount).fill(false),
            ]
          } else {
            return [...prevSelected, ...new Array(images.length).fill(true)]
          }
        })
        setSuccess(true)
      } catch (e: any) {
        // @ts-ignore
        const serverErrorMsg = e.response?.data?.message || ''
        if (serverErrorMsg === '410') {
          setErrorCode(Number(serverErrorMsg))
        }
        setErrorText(serverErrorMsg)
        setEssentialCheck(true)
        setHasLinkError(true)
        setSuccess(false)
      }
      setCrawlingLoading(false)
    }
  }

  const handleCrwaling = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setCrawlingLoading(true)
    await handleBlur(linkValue)
  }
  const handleImageType = () => {
    const templateId = watch('templateId')
    const image = templateList?.image?.list.find((el) => el.id === templateId)
    const etc = templateList?.etc?.list.find((el) => el.id === templateId)
    if (etc) {
      return (
        <>
          <div className="flex ">
            <span>{etc.name}</span>
            {new Array(etc.cashRate).fill(0).map((el, idx) => (
              <img
                src="assets/img/cash.png"
                alt="cash"
                key={idx}
                className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
              />
            ))}
          </div>
        </>
      )
    } else if (image) {
      return (
        <>
          <div className="flex ">
            <span>{image.name}</span>
            {new Array(image.cashRate).fill(0).map((el, idx) => (
              <img
                src="assets/img/cash.png"
                alt="cash"
                key={idx}
                className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
              />
            ))}
          </div>
        </>
      )
    } else {
      return <></>
    }
  }

  const handleRemoveLink = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    setValue('link', '')
    setValue('mainTitle', '')
    setSuccess(false)
    setHasLinkError(false)
    setCrawlingLoading(false)
  }

  const isDisabled =
    selectedImages.every((value) => !value) &&
    selectedUploadImages.every((value) => !value)

  const scrollToRef = useRef<HTMLDivElement>(null)
  const handleScroll = () => {
    if (scrollToRef.current) {
      // scrollIntoView를 사용해 해당 위치로 스크롤
      scrollToRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  return (
    <>
      {' '}
      <div className="flex flex-col relative mt-5 ">
        <div className="w-full absolute h-[102px] z-40 flex ">
          <button
            className={classNames(
              'w-[50%] h-full md:rounded-tl-[40px] flex items-start justify-center ',
              {
                ' bg-[#2E343D]  text-white': tab === 'link',
                ' bg-[#EEF1F7]   text-[#8F929B]': tab !== 'link',
              }
            )}
            onClick={() => {
              changeTab('link')
            }}
          >
            <span className="  text-[18px] font-bold pt-5">링크로 만들기</span>
          </button>
          <button
            className={classNames(
              'w-[50%] h-full md:rounded-tr-[40px] flex items-start justify-center ',
              {
                ' bg-[#2E343D]  text-white': tab === 'text',
                ' bg-[#EEF1F7]   text-[#8F929B]': tab !== 'text',
              }
            )}
            onClick={() => {
              changeTab('text')
            }}
          >
            <span className=" text-[18px] font-bold pt-5">텍스트로 만들기</span>
          </button>
        </div>

        <form
          onSubmit={handleSubmit(onSubmit, (err) => {
            userCheck()
            const { mainTitle, link } = err
            console.log(err)

            const selectErrorText = (mainTitle: any, link: any) => {
              let mainTitleErrorText =
                '컨텐츠 영상 제목(50자 제한) 입력을 하셔야 영상 생성 요청이 가능합니다.'
              let linkErrorText = '뉴스 링크'
              let errorStr = ''
              if (mainTitle && link) {
                errorStr = `${linkErrorText}, ${mainTitleErrorText}`

                if (mainTitleInputRef.current) {
                  mainTitleInputRef.current.focus()
                  mainTitleInputRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
              } else if (link) {
                errorStr = `${linkErrorText} 입력을 하셔야 영상 생성 요청이 가능합니다.`

                if (mainTitleInputRef.current) {
                  mainTitleInputRef.current.focus()
                  mainTitleInputRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
              }

              setErrorText(errorStr)
            }
            selectErrorText(mainTitle, link)
            setEssentialCheck(true)
          })}
          className={`flex flex-col gap-[20px] mt-[60px] z-50`}
        >
          <ShadowBox className="py-[40px]  md:w-[816px] bg-[#FFFFFF]">
            <div
              className="flex flex-col gap-[16px]  w-full "
              ref={scrollToRef}
            >
              <div className="flex flex-col gap-[16px]">
                <div className="flex flex-col w-full md:w-full ml-4 md:ml-0">
                  <div className="relative w-[210px]">
                    <h3 className="font-bold text-[#2E343D] text-[20px] md:text-[24px] ">
                      컨텐츠 링크 입력
                    </h3>
                    <ContentTooltip
                      setContentTooltipStatus={setContentTooltipStatus}
                      contentTooltipStatus={contentTooltipStatus}
                    />
                  </div>
                  <p className="text-main text-[14px]">
                    <span className="font-bold">
                      네이버 블로그, 티스토리, 워드프레스, 네이버 뉴스/연예/스포
                      {isMobile && <br />}츠
                    </span>
                    만 동작하며, 저작권에 유의해주세요!
                  </p>
                </div>
                <div className="flex flex-col items-center w-full md:w-[736px]  gap-[8px]">
                  <div className="relative">
                    <Controller
                      render={({ field: { onBlur, value, ...field } }) => (
                        <>
                          {isMobile ? (
                            <TextArea
                              userCheck={userCheck}
                              inputRef={mainTitleInputRef}
                              value={value}
                              error={value.length > 0 && hasLinkError}
                              {...field}
                              placeholder="컨텐츠 링크 붙여넣기"
                            />
                          ) : (
                            <Input
                              userCheck={userCheck}
                              inputRef={mainTitleInputRef}
                              value={value}
                              error={value.length > 0 && hasLinkError}
                              {...field}
                              placeholder="컨텐츠 링크 붙여넣기"
                              disabled={isSuccess}
                            />
                          )}
                        </>
                      )}
                      name={'link'}
                      rules={{
                        required: {
                          value: true,
                          message: 'URL 입력은 필수입니다!',
                        },
                      }}
                      control={control}
                    />
                    <div className="flex gap-[16px] absolute right-4 bottom-5  md:right-4 md:bottom-0 md:top-0 items-center ">
                      <button
                        onClick={handleRemoveLink}
                        disabled={watch(`link`) === '' || isCrawlingLoading}
                      >
                        <img
                          className="w-[24px] h-[24px]  md:w-[32px] md:h-[32px]"
                          src="assets/img/icon_circle_x.png"
                          alt="x"
                        />
                      </button>
                      <button
                        className={classNames(
                          'bg-[#6B8ACB] hover:bg-[#8FABE5] text-white px-[20px] py-[10px]  md:px-[24px] md:py-[16px] md:w-[76px] md:h-[51px]   flex items-center justify-center rounded-[10px] font-bold disabled:opacity-[0.3]',
                          {
                            ' active:scale-95': !isSuccess,
                          }
                        )}
                        onClick={handleCrwaling}
                        disabled={
                          watch(`link`) === '' || isCrawlingLoading || isSuccess
                        }
                      >
                        {isCrawlingLoading ? (
                          <span className="loader"></span> // 로더 컴포넌트
                        ) : (
                          '적용' // 적용 버튼 텍스트
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-[16px]">
                <div className=" flex flex-col ml-4 md:ml-0">
                  <div className="relative flex items-center gap-2">
                    {' '}
                    <h3 className="font-bold text-[#2E343D] md:text-[24px] text-[20px] ">
                      영상 제목 입력
                    </h3>
                    <ShortVideoTitleTooltip
                      setTitleTooltip={setTitleTooltip}
                      titleTooltip={titleTooltip}
                    />
                  </div>

                  <p className="text-main text-[14px]">
                    매력적인 제목을 정해주세요!
                  </p>
                </div>
                <div className="flex flex-col items-center w-full md:w-[736px] gap-[8px]">
                  <div className="relative">
                    <Controller
                      render={({ field }) => (
                        <>
                          {isMobile ? (
                            <TextArea
                              userCheck={userCheck}
                              inputRef={mainTitleInputRef}
                              error={
                                field.value.length > 50 ||
                                !PRODUCT_NAME_PATTERN.test(field.value)
                                  ? true
                                  : false
                              }
                              {...field}
                              placeholder="컨텐츠 영상 제목을 입력해주세요"
                              maxLength={50}
                            />
                          ) : (
                            <Input
                              userCheck={userCheck}
                              inputRef={mainTitleInputRef}
                              error={
                                field.value.length > 50 ||
                                !PRODUCT_NAME_PATTERN.test(field.value)
                                  ? true
                                  : false
                              }
                              {...field}
                              placeholder="컨텐츠 영상 제목을 입력해주세요"
                              maxLength={50}
                            />
                          )}
                          <div
                            className={`flex gap-[4px] mt-[8px] ${
                              (field.value.length > 50 ||
                                !PRODUCT_NAME_PATTERN.test(field.value)) &&
                              'text-error'
                            }`}
                          >
                            <span className="md:text-base text-[14px]">
                              {field.value.length}/50
                            </span>
                            <span className="md:text-base text-[14px]">{`이모지, 한자(漢字), 꺽쇠괄호(< >) 는 허용되지 않습니다.`}</span>
                          </div>
                        </>
                      )}
                      name={'mainTitle'}
                      rules={{
                        required: {
                          value: true,
                          message: '컨텐츠 영상 제목은 필수입니다!',
                        },
                        maxLength: {
                          value: 90,
                          message: '유튜브 영상 제목의 길이가 50자를 넘습니다.',
                        },
                        validate:
                          watch(`mainTitle`) !== ''
                            ? (value) => {
                                return (
                                  PRODUCT_NAME_PATTERN.test(value) ||
                                  '이모지와 꺽쇠괄호(< >)는 허용되지 않습니다.'
                                )
                              }
                            : undefined,
                      }}
                      control={control}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-[16px]">
                <div className="flex flex-col w-[343px] md:w-full ml-4 md:ml-0">
                  <div className="mb-4">
                    <label className="md:ml-0 font-bold text-[#2E343D] text-[18px] ">
                      컨텐츠 이미지
                    </label>
                    <p className="text-error text-[14px]">
                      사용할 수 있는 이미지가 없는 경우, 컨텐츠 영상 만들기가
                      진행되지 않습니다 🙏
                    </p>
                  </div>
                  {isSuccess && (
                    <div
                      style={{
                        background: '#F6F8FB',
                        borderRadius: '10px',
                        padding: '20px',
                        maxHeight: 'calc(2 * 230px)', // 2줄까지만 보이도록 높이 제한 (2줄 높이 + 갭)
                        overflowY: 'auto', // 스크롤 가능하게 설정
                        scrollbarWidth: 'thin', // Firefox용 기본 스크롤바 폭 설정
                        scrollbarColor: '#888 #e0e0e0', // Firefox용 스크롤바 색상 설정
                      }}
                    >
                      <div>
                        {images.length === 0 ? (
                          <p className="text-error text-[14px] mb-5">
                            입력하신 컨텐츠 링크에 사용할 수 있는 이미지가
                            없습니다. (이미지가 없는 경우, 영상만 있는 경우 등)
                            <br />
                            이미지를 직접 추가해서 컨텐츠 영상을 만들어보세요!
                          </p>
                        ) : (
                          <div>
                            <div className="text-[14px]  mb-3">
                              <span className="font-bold">
                                불러온 이미지{' '}
                                {selectedImages.filter((image) => image).length}
                                /{images.length}
                              </span>{' '}
                              (최대 20장 선택 가능)
                            </div>
                            <div
                              style={{
                                ...styles.gridContainer,
                                ...(window.innerWidth <= 768
                                  ? styles.gridContainerMobile
                                  : {}),
                              }}
                            >
                              {images.map((src, index) => (
                                <div
                                  style={{ marginBottom: '20px' }}
                                  key={index}
                                >
                                  <div
                                    style={{
                                      ...getGridItemStyle(
                                        selectedImages[index]
                                      ),
                                      ...(window.innerWidth <= 768
                                        ? styles.gridItemMobile
                                        : {}),
                                    }}
                                  >
                                    <img
                                      src={
                                        typeof src === 'string'
                                          ? src.includes('pstatic')
                                            ? `${process.env.REACT_APP_API_URL}/crawling/proxy-image?url=${src}`
                                            : src
                                          : URL.createObjectURL(src)
                                      }
                                      alt={`img_${index + 1}`}
                                      style={styles.image}
                                    />
                                  </div>
                                  <div style={styles.checkboxContainer}>
                                    <input
                                      type="checkbox"
                                      checked={selectedImages[index]}
                                      onChange={() =>
                                        handleCheckboxChange(index)
                                      }
                                      style={styles.checkbox}
                                    />
                                    <span style={styles.label}>
                                      {String(index + 1).padStart(2, '0')}
                                    </span>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                      <hr />
                      <div className="text-[14px]  mt-5 mb-1">
                        <span className="font-bold">
                          업로드한 이미지{' '}
                          {selectedUploadImages.filter((image) => image).length}
                          /5
                        </span>{' '}
                        (최대 5장 선택 가능)
                      </div>
                      <div className="flex items-center space-x-2 mb-3">
                        <span className="text-[#8F929B] text-[16px] text-sm">
                          총 20MB | jpg, jpeg, png
                        </span>
                        <button
                          onClick={handleButtonClick}
                          className="flex items-center space-x-1 px-3 py-2 text-white rounded-full bg-[#6B8ACB] text-[14px] font-bold"
                        >
                          <img
                            src="assets/img/icon_image.png"
                            width="17px"
                            alt=""
                          />
                          <span>파일 업로드</span>
                        </button>
                        <input
                          id="file-input"
                          type="file"
                          accept=".jpg,.jpeg,.png"
                          multiple
                          className="hidden"
                          onChange={handleFileChange}
                        />
                      </div>
                      <div
                        style={{
                          ...styles.gridContainer,
                          ...(window.innerWidth <= 768
                            ? styles.gridContainerMobile
                            : {}),
                        }}
                      >
                        {uploadImages.map((src, index) => (
                          <div style={{ marginBottom: '20px' }} key={index}>
                            <div
                              style={{
                                ...getGridItemStyle(
                                  selectedUploadImages[index]
                                ),
                                ...(window.innerWidth <= 768
                                  ? styles.gridItemMobile
                                  : {}),
                              }}
                            >
                              <img
                                src={
                                  typeof src === 'string'
                                    ? src
                                    : URL.createObjectURL(src)
                                }
                                alt={`img_${index + 1}`}
                                style={styles.image}
                              />
                            </div>
                            <div style={styles.checkboxContainer}>
                              <input
                                type="checkbox"
                                checked={selectedUploadImages[index]}
                                onChange={() =>
                                  handleUploadCheckboxChange(index)
                                }
                                style={styles.checkbox}
                              />
                              <span style={styles.label}>
                                {String(index + 1).padStart(2, '0')}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-[16px]">
                <div className="flex flex-col w-[343px] md:w-full ml-4 md:ml-0">
                  <div className="mb-4">
                    <label className="md:ml-0 font-bold text-[#2E343D] text-[18px] ">
                      영상 타입 선택
                    </label>
                  </div>
                  <div className="grid grid-cols-2">
                    {[
                      { name: '숏폼', value: true },
                      { name: '롱폼(준비중)', value: false },
                    ]?.map((el, index) => {
                      return (
                        <>
                          <div className="flex " key={index}>
                            <Controller
                              render={({ field }) => (
                                <CircularCheckbox
                                  {...field}
                                  id={index}
                                  disabled={!el.value}
                                  label={el.name}
                                  checked={el.value}
                                />
                              )}
                              name="bgmId"
                              control={control}
                            />
                          </div>
                        </>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </ShadowBox>

          <ShadowBox
            className={classNames('py-[40px] px-[16px] md:w-[816px]', {
              'opacity-30 pointer-events-none': !isSuccess,
            })}
          >
            <div className="flex flex-col gap-[40px] md:gap-0 w-[343px] md:w-[736px]">
              <div className="flex flex-col items-center  mt-[16px] md:mb-[40px] relative ">
                <p className="font-bold text-[#2E343D] text-[24px] ">
                  효과 선택
                </p>
                <p className="text-main text-[14px]">
                  원하는 분위기의 영상 생성을 위해 각 효과를 선택해주세요!
                </p>
                <div className="md:absolute  md:right-0 md:mt-0 mt-4">
                  <p className=" bg-clip-text text-transparent bg-gradient-to-r from-[rgba(96,149,255,1)] to-[rgba(213,18,231,1)]">
                    영상 효과 할인 이벤트!
                  </p>
                  <img
                    src="assets/img/short_video_editor/sale.png"
                    alt="sale"
                    width={141}
                    height={32}
                  />
                </div>
              </div>
              <CSSTransition
                in={isOpen}
                timeout={200}
                classNames="content"
                unmountOnExit
              >
                <div className="">
                  <div className="bgm  mb-[40px] ">
                    <ShortBgmDisplay
                      bgmList={bgmList ?? []}
                      setBgmPlaying={setBgmPlaying}
                      bgmPlaying={bgmPlaying}
                      watch={watch}
                      setValue={setValue}
                      bgmRefs={bgmRefs}
                      handleBgmPlayButtonClick={handleBgmPlayButtonClick}
                      userInfo={userInfo}
                      control={control}
                      bgmTooltip={bgmTooltip}
                      setBgmTooltip={setBgmTooltip}
                    />

                    <div className="bg-[#F6F8FB] p-4 rounded-[10px] flex flex-col gap-2 mt-4">
                      <p className="font-bold">
                        💰🎵 음원으로 지금 당장 수익 창출하기!
                      </p>
                      <p>
                        유튜브 음원 서비스를 활용해 배경음악을 사용하면,
                        구독자가 0명이어도 쇼츠 조회만으로 수익 창출이 가능해요!
                      </p>
                      <p>
                        아래 음원 서비스들을 사용하시려면, 패스트컷 컨텐츠 영상
                        생성시 배경음악 ‘없음'을 선택해주세요.
                      </p>
                      <p className="flex gap-2">
                        <a
                          href="https://zzal.studio/"
                          target="_blank"
                          rel="noreferrer"
                          onClick={() => {
                            clickLogo('ZZAL', 'ZZAL')
                          }}
                        >
                          <img
                            src="assets/img/affiliate/zzal/zzal_logo.png"
                            alt="zzal"
                            width={60}
                          />
                        </a>
                        <a
                          href="https://consalad.net/main/"
                          target="_blank"
                          rel="noreferrer"
                          onClick={() => {
                            clickLogo('CON_SALAD', 'CON_SALAD')
                          }}
                        >
                          <img
                            src="assets/img/affiliate/con_salad_logo.png"
                            alt="con_salad"
                            width={60}
                          />
                        </a>
                        <a
                          href="https://shortsmusic.kr/"
                          target="_blank"
                          rel="noreferrer"
                          onClick={() => {
                            clickLogo('SHORTS_MUSIC', 'SHORTS_MUSIC')
                          }}
                        >
                          <img
                            src="assets/img/affiliate/shorts_music_logo.png"
                            alt="shorts_music"
                            width={60}
                          />
                        </a>
                      </p>
                    </div>
                  </div>

                  <div className="ai-prompt mb-[40px]">
                    <p className=" text-[18px] mb-[16px] flex items-center gap-1 relative">
                      <span className="font-bold">이미지 애니메이션 효과 </span>
                      <AnimationTitleTooltip
                        setAnimationTooltip={setAnimationTooltip}
                        animationTooltip={animationTooltip}
                      />
                    </p>

                    <div className="flex gap-[80px] ">
                      {animationList?.map((animation, index) => {
                        return (
                          <>
                            <div className="flex " key={index}>
                              <div className="flex h-full items-center">
                                <Controller
                                  render={({ field }) => (
                                    <CircularCheckbox
                                      {...field}
                                      id={index}
                                      checked={
                                        watch('animationId') === animation.id
                                      }
                                      disabled={!userInfo}
                                      onChange={() =>
                                        setValue('animationId', animation.id)
                                      }
                                      label={animation.name}
                                    />
                                  )}
                                  name="animationId"
                                  control={control}
                                />
                              </div>
                            </div>
                          </>
                        )
                      })}
                    </div>
                  </div>

                  <div className="background mb-[40px]">
                    <p className=" mb-[16px] flex items-center gap-1 relative">
                      <span className=" text-[18px] font-bold">
                        화면 구성 + 배경 효과
                      </span>
                      <span
                        className="w-[36px] h-[17px]  flex justify-center items-center rounded-[4px] font-bold text-[11px] text-white"
                        style={{
                          background: `linear-gradient(to right, rgba(96, 149, 255, 1),rgba(213, 18, 231, 1))`,
                        }}
                      >
                        SALE
                      </span>
                    </p>

                    <div className="grid grid-cols-3 gap-x-[8px] md:gap-x-[10px] gap-y-[16px] ">
                      {['image', 'mix', 'clip'].map((el, index) => {
                        let btnTitle = ''
                        let subTitle = ''
                        let type: 'clip' | 'image' | 'mix' = watch(
                          'templateType'
                        ) as 'clip' | 'image' | 'mix'

                        const handleTypeTitle = () => {
                          if (el === 'image') {
                            if (isMobile) {
                              btnTitle = '이미지 타입'
                              subTitle =
                                '이미지를 화면 전체에 적용하고, 제목의 스타일을 고르는 타입'
                            } else {
                              btnTitle = '이미지 타입에서 고르기'
                              subTitle =
                                '이미지를 화면 전체에 적용하고, 제목의 스타일을 고르는 타입'
                            }
                          } else if (el === 'mix') {
                            if (isMobile) {
                              btnTitle = '믹스 타입'
                              subTitle =
                                '이미지&제목의 화면 구성과 배경 영상을 믹스해서 고르는 타입'
                            } else {
                              btnTitle = '믹스 타입에서 고르기'
                              subTitle =
                                '이미지&제목의 화면 구성과 배경 영상을 믹스해서 고르는 타입'
                            }
                          } else if (el === 'clip') {
                            if (isMobile) {
                              btnTitle = '영상 타입'
                              subTitle =
                                '영상으로 만들어진 템플릿에 이미지와 제목을 적용하는 타입 '
                            } else {
                              btnTitle = '영상 타입에서 고르기'
                              subTitle =
                                '영상으로 만들어진 템플릿에 이미지와 제목을 적용하는 타입 '
                            }
                          }
                        }
                        const handleTypeClick = () => {
                          if (el === 'image') {
                            setImageTypeModal((prev) => !prev)
                          } else if (el === 'mix') {
                            setMixTypeModal((prev) => !prev)
                          } else if (el === 'clip') {
                            setVideoTypeModal((prev) => !prev)
                          }
                        }

                        let selectedTemplate = { path: '' }
                        if (templateList && templateList[type]) {
                          const selectedTemplateId = watch('templateId')

                          const [template] = templateList[type]?.list.filter(
                            (el) => el.id === selectedTemplateId
                          )
                          if (template) {
                            selectedTemplate = template
                          } else if (templateList['etc']) {
                            const [template] = templateList['etc']?.list.filter(
                              (el) => el.id === selectedTemplateId
                            )
                            selectedTemplate = template
                          }
                        }

                        handleTypeTitle()
                        return (
                          <div
                            className={classNames(
                              'bg-[#F6F8FB] h-auto flex flex-col items-center md:px-5 md:py-5 py-[10px] px-[6px] rounded-[10px]',
                              {
                                ' outline outline-4':
                                  watch('templateType') === el,
                              }
                            )}
                            style={{ outlineColor: '#00B7B9' }}
                            onClick={handleTypeClick}
                          >
                            <img
                              src={
                                watch('templateType') === el
                                  ? selectedTemplate?.path
                                  : index !== 2
                                  ? `assets/img/short_video_editor/${index}.png`
                                  : 'https://pub.angelleague.io/fast_cut/template/clip3.gif'
                              }
                              alt="0"
                              height={240}
                              className="w-[101px] md:w-[136px]"
                            />
                            <button
                              className="py-2 font-bold text-[14px] text-white bg-[#6B8ACB] rounded-[40px] mt-2 w-[101px] md:w-[160px]"
                              type="button"
                            >
                              {btnTitle}
                            </button>

                            {watch('templateType') === el ? (
                              <p className="w-full text-center mt-2 bg-white border-[1px] border-[#D9DEE8] rounded-[4px] py-1 flex md:flex-row flex-col md:justify-center items-center gap-2 ">
                                {templateList && type !== 'image' ? (
                                  <>
                                    {templateList[type]?.list
                                      .filter(
                                        (el) => el.id === watch('templateId')
                                      )
                                      .map((el) => {
                                        return (
                                          <>
                                            <div className="flex ">
                                              <span>{el.name}</span>
                                              {new Array(el.cashRate)
                                                .fill(0)
                                                .map((el, idx) => (
                                                  <img
                                                    src="assets/img/cash.png"
                                                    alt="cash"
                                                    key={idx}
                                                    className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
                                                  />
                                                ))}
                                            </div>
                                          </>
                                        )
                                      })}
                                    {type === 'mix' &&
                                      bgList
                                        ?.filter(
                                          (el) =>
                                            el.id === watch('backgroundId')
                                        )
                                        .map((el) => {
                                          return (
                                            <>
                                              <div className="flex ">
                                                <span>{el.name}</span>
                                                {new Array(el.cashRate)
                                                  .fill(0)
                                                  .map((el, idx) => (
                                                    <img
                                                      src="assets/img/cash.png"
                                                      alt="cash"
                                                      key={idx}
                                                      className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
                                                    />
                                                  ))}
                                              </div>
                                            </>
                                          )
                                        })}
                                  </>
                                ) : (
                                  templateList &&
                                  type === 'image' &&
                                  handleImageType()
                                )}
                              </p>
                            ) : (
                              <p className="text-[14px] text-center mt-2">
                                {subTitle}
                              </p>
                            )}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <SubtitleDisplay
                    subTitleList={subTitleList ?? []}
                    watch={watch}
                    control={control}
                    setValue={setValue}
                    userInfo={userInfo}
                    setSubtitleTooltip={setSubtitleTooltip}
                    subtitleTooltip={subtitleTooltip}
                    subtitleColorList={subtitleColorList ?? []}
                  />

                  <AiScriptDisplay
                    watch={watch}
                    setValue={setValue}
                    control={control}
                    userInfo={userInfo}
                    toneList={toneList}
                    tempoList={tempoList}
                    setPromptStatus={setPromptStatus}
                    promptStatus={promptStatus}
                    voiceList={voiceList}
                    setVoicePlaying={setVoicePlaying}
                    setTempoPlaying={setTempoPlaying}
                    tempoPlaying={tempoPlaying}
                    voicePlaying={voicePlaying}
                    voiceTags={voiceTags}
                    selectedVoiceId={watch('voiceId')}
                    setToneTooltip={setToneTooltip}
                    toneTooltip={toneTooltip}
                  />
                </div>
              </CSSTransition>
              <div className="flex items-center justify-center">
                <button
                  className="flex items-center justify-center gap-[8px] w-full py-[12.5px] bg-[#EEF1F7] rounded-[40px]"
                  type="button"
                  onClick={toggleContent}
                >
                  <span>{isOpen ? '접기' : '펼치기'}</span>
                  <img
                    src="assets/img/icon_up_arrow.png"
                    alt="arrow"
                    className={`w-[12.8px] h-[8.25px]  transition-transform duration-500 ${
                      isOpen ? '' : 'rotate-180'
                    }`}
                  />
                </button>
              </div>
            </div>
          </ShadowBox>
          <div
            className="text-[16px] text-center"
            style={{
              opacity: isDisabled ? 0.3 : 1,
              pointerEvents: isDisabled ? 'none' : 'auto',
            }}
          >
            패스트컷은 사용자가 제공하는 URL에 있는 텍스트/이미지/영상을 토대로
            영상을 만들고 있으며,
            <br /> 링크 내의 텍스트나 이미지, 영상 등이 영상에 사용될 수
            있습니다.
            <br /> 이에 대한 지적재산권 침해 여부는 사용자가 확인하여야 하며,
            이에 관하여 패스트컷은 책임이 없습니다.
          </div>
          <div>
            <div
              className="flex flex-col justify-center items-center gap-[20px]"
              style={{
                opacity: !isSuccess ? 0.3 : 1,
                pointerEvents: isDisabled ? 'none' : 'auto',
              }}
            >
              <button
                type="submit"
                className={`h-[60px] flex items-center justify-center bg-main gap-[4px] w-[343px] md:w-full border-none rounded-[30px] hover:bg-hover text-[#FFFFFF] font-bold`}
                ref={btnRef}
                disabled={!isSuccess}
              >
                <span>컨텐츠 영상 생성 요청</span>
              </button>
            </div>
          </div>
        </form>
      </div>
      {essentialCheck && (
        <Modal isModal={essentialCheck}>
          <div className="bg-white w-[335px] h-[184px] p-[20px] border-t rounded-t-[20px]">
            <div className="flex flex-col items-center justify-center h-full gap-[24px]">
              <p className="font-bold text-[18px]">
                🙌 필수 정보를 확인해주세요!
              </p>
              {errorCode === 410 ? (
                <>
                  {' '}
                  <p className="text-[14px]">
                    쿠팡/알리 링크를 사용할 수 없습니다 <br />
                    상품 컨텐츠 영상은 상품 영상 만들기 화면에서 상품 영상과
                    함께 생성해주세요!
                  </p>{' '}
                </>
              ) : (
                <p className="text-[14px]">{errorText}</p>
              )}

              {/* <p className="text-red-500">{errorMessage}</p> */}
            </div>
          </div>
          <div className="w-[335px]  h-[60px] rounded-b-[20px] flex justify-center items-center font-bold">
            {errorCode === 410 ? (
              <>
                <button
                  className="text-[#5E6068] w-[50%] h-full bg-[#EEF1F7] border-b  border-[#EEF1F7] rounded-bl-[20px]"
                  onClick={() => {
                    setEssentialCheck(false)
                  }}
                >
                  닫기
                </button>{' '}
                <button
                  className="text-white w-[50%]  h-full bg-main  border-b  border-main rounded-br-[20px]"
                  onClick={() => {
                    setEssentialCheck(false)
                    navigate('/video-editor')
                  }}
                >
                  상품영상 만들러 가기
                </button>
              </>
            ) : (
              <>
                <button
                  className="text-white w-[335px] rounded-b-[20px] h-full bg-main  border-b  border-main"
                  onClick={() => {
                    setEssentialCheck(false)
                  }}
                >
                  확인
                </button>
              </>
            )}
          </div>
        </Modal>
      )}
      {saveSuccessModal && (
        <Modal isModal={saveSuccessModal}>
          <div className="bg-white w-[335px] h-[184px] p-[20px] border-t rounded-t-[20px]">
            <div className="flex flex-col items-center justify-center h-full gap-[24px]">
              <p className="font-bold text-[18px]">요청 완료</p>
              <div>
                <p className="text-[14px] font-bold">
                  컨텐츠 영상 생성이 요청 되었습니다!
                </p>
                <p className="text-[14px] font-bold">
                  생성 현황은 <span className="text-main">‘사용 현황‘</span>{' '}
                  페이지에서 확인하세요.
                </p>
              </div>
            </div>
          </div>
          <div className="flex  w-[335px]">
            <div className="w-[50%] bg-[#EEF1F7] h-[60px] border-b border-[#EEF1F7] rounded-bl-[20px] flex justify-center items-center">
              <button
                className="text-[#5E6068] w-full font-bold"
                onClick={() => {
                  navigate('/my-videos?type=lcv&page=1&pageSize=80')
                }}
              >
                사용현황 페이지 가기
              </button>
            </div>
            <div className="w-[50%] bg-main h-[60px] border-b border-main rounded-br-[20px] flex justify-center items-center">
              <button
                className="text-white w-full font-bold"
                onClick={(event) => {
                  setSaveSuccessModal(false)
                  handleRemoveLink(event)
                  handleScroll()
                }}
              >
                확인
              </button>
            </div>
          </div>
        </Modal>
      )}
      {isModal && (
        <ConfirmPaymentCashModal
          isModal={isModal}
          setModal={setModal}
          amount={amountValue}
          bonusValue={bonusValue}
          valid={valid ? valid : ''}
          promotionCode={promotionCode ? Number(promotionCode) : 0}
        />
      )}
      {isImageTypeModal && (
        <ImageTypeModal
          isImageTypeModal={isImageTypeModal}
          setImageTypeModal={setImageTypeModal}
          userInfo={userInfo}
          imageList={templateList?.image}
          etcList={templateList?.etc}
          watch={watch}
          setValue={setValue}
          control={control}
        />
      )}
      {isMixTypeModal && (
        <MixTypeModal
          isMixTypeModal={isMixTypeModal}
          setMixTypeModal={setMixTypeModal}
          userInfo={userInfo}
          mixList={templateList?.mix}
          bgList={bgList}
          watch={watch}
          setValue={setValue}
          control={control}
        />
      )}
      {isVideoTypeModal && (
        <VideoTypeModal
          isVideoTypeModal={isVideoTypeModal}
          setVideoTypeModal={setVideoTypeModal}
          userInfo={userInfo}
          videoList={templateList?.clip}
          watch={watch}
          setValue={setValue}
          control={control}
        />
      )}
      {/* 캐시 사용 모달*/}
      {isUseModal && (
        <CashUseModal
          isUseModal={isUseModal}
          setUseModal={setUseModal}
          currentPoint={myPointData.totalPoint}
          shortVideoInfo={shortVideoInfo}
          shortVideoPriceInfo={shortVideoPriceInfo}
          setSaveSuccessModal={setSaveSuccessModal}
          setValue={setValue}
          getMyPointRefetch={getMyPointRefetch}
          setRequest={setRequest}
        />
      )}
      {isPending && (
        <>
          {' '}
          <Spinner />
        </>
      )}
    </>
  )
}
