import React, {
  CSSProperties,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState,
} from 'react'
import { isMobile } from 'react-device-detect'
import { UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { CSSTransition } from 'react-transition-group'
import { FormValues } from 'utils/type'

interface FileUploaderProps {
  watch: UseFormWatch<FormValues>
  setValue: UseFormSetValue<FormValues>
  i: number
}
export const FileUploader = ({
  watch,
  i,
  setValue,
}: PropsWithChildren<FileUploaderProps>) => {
  const [isImageDivOpen, setImageDivOpen] = useState<boolean>(false)

  const handleImageDivOpen = () => {
    setImageDivOpen((prev) => !prev)
  }

  const handleUploadCheckboxChange = (
    productIndex: number,
    fileIndex: number
  ) => {
    const currentFiles = watch(`products.${productIndex}.files`, [])

    const currentSelectedFiles = watch(
      `products.${productIndex}.selectedFiles`,
      []
    )

    const fileToToggle = currentFiles[fileIndex]
    const isSelected = currentSelectedFiles.includes(fileToToggle)

    if (isSelected) {
      // 이미 선택된 파일을 선택 해제
      setValue(
        `products.${productIndex}.selectedFiles`,
        currentSelectedFiles.filter((file) => file !== fileToToggle)
      )
    } else {
      // 선택된 파일이 3개 이상이면 추가 선택을 막습니다.
      if (currentSelectedFiles.length >= 3) return

      // 선택되지 않은 파일을 선택
      setValue(`products.${productIndex}.selectedFiles`, [
        ...currentSelectedFiles,
        fileToToggle,
      ])
    }
  }

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: any
  ) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files)
      // 업로드한 파일을 products[index].files에 추가
      // @ts-ignore
      setValue(`products.${index}.files`, [
        ...(watch(`products.${index}.files`) ?? []),
        ...selectedFiles,
      ])

      // 현재 선택된 파일 배열 가져오기
      const prevSelected = watch(`products.${index}.selectedFiles`, [])
      const additionalSelectionCount = selectedFiles.length
      const currentSelectionCount = prevSelected ? prevSelected.length : 0

      // 선택된 파일 상태 업데이트 (최대 3개만 선택 가능)
      if (currentSelectionCount + additionalSelectionCount > 3) {
        const allowedSelectionCount = 3 - currentSelectionCount
        // @ts-ignore
        setValue(`products.${index}.selectedFiles`, [
          ...prevSelected,
          ...selectedFiles.slice(0, allowedSelectionCount),
        ])
      } else {
        // @ts-ignore
        setValue(`products.${index}.selectedFiles`, [
          ...prevSelected,
          ...selectedFiles,
        ])
      }
    }
  }
  const getGridItemStyle = (selected: boolean): CSSProperties => ({
    width: '130px',
    height: '130px',
    position: 'relative',
    borderRadius: '4px',
    border: '1px solid #D9DEE8',
    overflow: 'hidden', // 이미지가 부모의 경계를 넘어가지 않도록 설정
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FFFFFF',
    opacity: selected ? 1 : 0.3, // 선택되지 않은 경우 투명도 조절
  })

  // @ts-ignore
  const styles: { [key: string]: CSSProperties } = {
    input: {
      width: '100%',
      padding: '10px',
      marginBottom: '10px',
      borderRadius: '4px',
      border: '1px solid #ccc',
    },
    gridContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)',
    },
    gridContainerMobile: {
      gridTemplateColumns: 'repeat(3, 1fr)', // 각 아이템의 너비를 유지하면서 자동으로 간격 조정
      justifyItems: 'center',
    },
    gridItemMobile: {
      width: '93px',
      height: '93px',
    },
    image: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      objectFit: 'contain', // 비율을 유지하며 부모를 꽉 채우도록 설정
    },
    checkboxContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    checkbox: {
      marginRight: '8px',
      width: '16px',
      height: '16px',
    },
    label: {
      fontSize: '16px',
      color: '#333',
    },
  }

  return (
    <>
      <div className="flex justify-between md:w-full w-[343px]  bg-[#EEF1F7] items-center px-5 py-[13px] rounded-[10px] mt-6">
        <p className="font-bold text-[14px]">
          [선택] 이미지 업로드{' '}
          {
            watch(`products.${i}.selectedFiles`, []).filter((image) => image)
              .length
          }
          /3
          <span className="font-[400]">
            {isMobile ? <> (최대 3장)</> : <> (최대 3장 선택 가능)</>}
          </span>
        </p>
        <button
          className="flex items-center justify-center gap-[8px] w-[65px] h-[44px] md:text-base text-[14px] "
          type="button"
          onClick={() => {
            handleImageDivOpen()
          }}
        >
          <span>{isImageDivOpen ? '접기' : '펼치기'}</span>
          <img
            src="assets/img/icon_up_arrow.png"
            alt="arrow"
            className={`w-[12.8px] h-[8.25px]  transition-transform duration-500 ${
              isImageDivOpen ? '' : 'rotate-180'
            }`}
          />
        </button>
      </div>
      <CSSTransition
        in={isImageDivOpen}
        timeout={200}
        classNames="content"
        unmountOnExit
      >
        <div
          style={{
            width: '100%',
            background: '#F6F8FB',
            borderRadius: '10px',
            padding: '20px',
            maxHeight: 'calc(2 * 230px)', // 2줄까지만 보이도록 높이 제한 (2줄 높이 + 갭)
            overflowY: 'auto', // 스크롤 가능하게 설정
            scrollbarWidth: 'thin', // Firefox용 기본 스크롤바 폭 설정
            scrollbarColor: '#888 #e0e0e0', // Firefox용 스크롤바 색상 설정
          }}
        >
          {i === 0 && (
            <>
              <p className="text-[14px] text-error mb-1">
                상품 페이지 대표 이미지 외에 따로 이미지 추가를 원하시는
                경우에만 업로드 해주세요.
              </p>
              <p className="text-[14px] text-error mb-1">
                상품 페이지 영상이 없는 경우에만 적용되며, 상품 영상 생성시
                업로드 이미지를 앞쪽에 배치합니다.
              </p>
            </>
          )}
          <div className="flex items-center space-x-2 mb-3">
            <span className="text-[#8F929B] text-[16px] text-sm">
              총 20MB | jpg, jpeg, png
            </span>
            <button
              onClick={(event) => {
                event.preventDefault() // 기본 form 제출 동작을 방지
                document.getElementById(`file-input-${i}`)?.click() // 파일 선택 창 열기
              }}
              className="flex items-center space-x-1 px-3 py-2 text-white rounded-full bg-[#6B8ACB] text-[14px] font-bold"
            >
              <img src="assets/img/icon_image.png" alt="" width={17} />
              <span>파일 업로드</span>
            </button>
            <input
              id={`file-input-${i}`}
              type="file"
              accept=".jpg,.jpeg,.png"
              multiple
              className="hidden"
              onChange={(e) => handleFileChange(e, i)}
            />
          </div>

          <div
            style={{
              ...styles.gridContainer,
              ...(window.innerWidth <= 768 ? styles.gridContainerMobile : {}),
            }}
          >
            {watch(`products.${i}.files`, []).map((file, fileIndex) => {
              const selectedFiles = watch(`products.${i}.selectedFiles`, [])
              console.log('selectedFiles:::::', selectedFiles)
              return (
                <div style={{ marginBottom: '20px' }} key={fileIndex}>
                  <div
                    style={{
                      ...getGridItemStyle(
                        watch(`products.${i}.selectedFiles`, []).includes(file)
                      ),
                      ...(window.innerWidth <= 768
                        ? styles.gridItemMobile
                        : {}),
                    }}
                  >
                    <img
                      src={URL.createObjectURL(file)}
                      key={`Image ${fileIndex + 1}`}
                      alt=""
                      style={styles.image}
                    />
                  </div>
                  <div style={styles.checkboxContainer}>
                    <input
                      type="checkbox"
                      checked={watch(
                        `products.${i}.selectedFiles`,
                        []
                      ).includes(file)}
                      onChange={() => handleUploadCheckboxChange(i, fileIndex)}
                      style={styles.checkbox}
                    />
                    <span style={styles.label}>
                      {String(fileIndex + 1).padStart(2, '0')}
                    </span>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </CSSTransition>
    </>
  )
}
