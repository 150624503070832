import classNames from 'classnames'
import { Input } from 'components/atom/Input'
import { TextArea } from 'components/atom/TextArea'
import { LinkType } from 'components/shortvideo/display/LinkType'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Controller } from 'react-hook-form'
import { LinkDetailType } from 'service/list'
import { ReactComponent as EditIcon } from 'svg/fi_edit.svg'
import { ReactComponent as StarIcon } from 'svg/star_white.svg'
import { UserType } from 'utils/common'

const ProductNameInput = ({
  index,
  errors,
  control,
  watch,
  setValue,
  error,
  usage,
  userInfo,
  setError,
  selectedLink,
  isAiName,
}: any) => {
  const PRODUCT_NAME_PATTERN = /^[^<>\uD800-\uDFFF]*$/

  const [useAiName, setUseAiName] = useState(false)

  const handleAiName = (name: string) => {
    setUseAiName(true)
    setValue(`products.${index}.productName`, name)
  }

  const handleBasicName = (name: string) => {
    setUseAiName(false)
    setValue(`products.${index}.productName`, name)
  }

  useEffect(() => {
    if (selectedLink) {
      setUseAiName(isAiName)
      setValue(`products.${index}`, {
        link: selectedLink.url,
        productName: isAiName
          ? selectedLink.aiRecommendTitle
          : selectedLink.title,
      })
    }
  }, [selectedLink, isAiName])

  return (
    <>
      <div className="flex flex-col w-full mt-6 gap-2">
        <p className="w-full font-bold flex gap-2 items-center">
          <EditIcon />
          상품명 편집
        </p>
        <div
          className="product_name cursor-pointer"
          onClick={() => {
            handleAiName(selectedLink?.aiRecommendTitle)
          }}
        >
          <p
            className={classNames(
              'px-2 py-1 rounded-[4px] inline-flex gap-2 items-center',
              {
                'bg-[#BBEBEC]': useAiName,
                'bg-[#EEF1F7] ': !useAiName,
              }
            )}
          >
            {' '}
            <span className="font-bold text-white bg-[#2E343D] flex  items-center w-[65px] h-[22px] rounded-[4px] text-[12px] justify-center ">
              <StarIcon className="text-white" /> AI 추천
            </span>
            {selectedLink?.aiRecommendTitle ?? '--'}
          </p>
        </div>
        <div
          className=" cursor-pointer"
          onClick={() => {
            handleBasicName(selectedLink?.title)
          }}
        >
          <p
            className={classNames(
              'px-2 py-1 rounded-[4px] inline-flex gap-2 items-center',
              {
                'bg-[#BBEBEC]': !useAiName,
                'bg-[#EEF1F7] ': useAiName,
              }
            )}
          >
            <span className="text-[12px] bg-[#8F929B] px-1 py-[2px] text-white shrink-0 rounded-[4px] font-bold">
              원본
            </span>
            {selectedLink?.title ?? '--'}
          </p>
        </div>
        <div className="product_title">
          <Controller
            key={`productName_${index}`}
            render={({ field }) => (
              <>
                {isMobile ? (
                  <>
                    {' '}
                    <TextArea
                      error={error.products[index].product}
                      disabled={
                        usage &&
                        !usage?.available &&
                        userInfo?.userType !== UserType.체험
                      }
                      {...field}
                      placeholder="영상에 표기될 상품명을 깔끔하게 정리해서 적어주세요! (50자 이하)"
                      maxLength={100}
                    />
                  </>
                ) : (
                  <Input
                    error={error.products[index].product}
                    disabled={
                      usage &&
                      !usage?.available &&
                      userInfo?.userType !== UserType.체험
                    }
                    {...field}
                    placeholder="영상에 표기될 상품명을 깔끔하게 정리해서 적어주세요! (50자 이하)"
                    maxLength={100}
                    bg={true}
                    height="h-[50px]"
                  />
                )}

                <div
                  className={`flex gap-[4px] mt-1 md:flex-row flex-col text-[14px]   ${
                    field.value.length > 50 ||
                    !PRODUCT_NAME_PATTERN.test(field.value)
                      ? 'text-error'
                      : 'text-[#8F929B]'
                  }`}
                >
                  <span>{field.value.length}/50</span>
                  <span>{`이모지와 꺽쇠괄호(< >)는 허용되지 않습니다.`}</span>
                  {errors.products && errors.products[index]?.productName && (
                    <span className="text-error ml-[10px]">
                      {errors.products[index]?.productName?.type ===
                        'required' && '상품명을 입력해주세요.'}
                    </span>
                  )}
                </div>
              </>
            )}
            name={`products.${index}.productName`}
            control={control}
            rules={{
              required:
                (index === 0 || watch(`products.${index}.productName`)) !== ''
                  ? 'required'
                  : false,
              validate:
                index === 0 || watch(`products.${index}.productName`) !== ''
                  ? (value) => {
                      const updateError = { ...error }
                      updateError.products[index].product = false
                      setError(updateError)
                      return (
                        PRODUCT_NAME_PATTERN.test(value) ||
                        '이모지와 꺽쇠괄호(< >)는 허용되지 않습니다.'
                      )
                    }
                  : undefined,
              maxLength: 50,
            }}
          />
        </div>
      </div>{' '}
    </>
  )
}

export default ProductNameInput
