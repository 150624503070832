import { Dispatch, PropsWithChildren, useEffect } from 'react'
import ProductItem from './ProductItem'
import {
  Control,
  Field,
  FieldArrayWithId,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { FormValues } from 'utils/type'
import { Me, Usage } from 'service/user'
import { LinkDetailType } from 'service/list'

interface ProductListProps {
  watch: UseFormWatch<FormValues>
  setValue: UseFormSetValue<FormValues>
  control: Control<FormValues, any>
  fields: FieldArrayWithId<FormValues, 'products', 'id'>[]
  errors: FieldErrors<FormValues>
  setErrorText: Dispatch<React.SetStateAction<string>>
  userInfo: Me
  usage: Usage | undefined
  error: {
    products: {
      link: boolean
      product: boolean
    }[]
    mainTitle: boolean
    thumbnail: boolean
  }
  selectedLinks: LinkDetailType[]
  setError: any
  isAiName: boolean
}
const ProductList = ({
  watch,
  setValue,
  control,
  fields,
  errors,
  error,
  setErrorText,
  userInfo,
  usage,
  selectedLinks,
  setError,
  isAiName,
}: PropsWithChildren<ProductListProps>) => {
  useEffect(() => {
    // new Array(3).fill(1).forEach((el, index) => {
    //   setValue(`products.${index}`, {
    //     link: '',
    //     productName: '',
    //     files: [],
    //     selectedFiles: [],
    //     selectedDetails: [],
    //     selectedThumbnails: [],
    //     selectedVideos: [],
    //   })
    // })
  }, [selectedLinks])
  return (
    <div className="flex flex-col items-center w-[375px] md:w-[736px] gap-6">
      {selectedLinks.map((item: any, index: number) => (
        <ProductItem
          key={index}
          index={index}
          control={control}
          errors={errors}
          error={error}
          userInfo={userInfo}
          watch={watch}
          usage={usage}
          setValue={setValue}
          setErrorText={setErrorText}
          selectedLink={selectedLinks[index]}
          setError={setError}
          isAiName={isAiName}
        />
      ))}
    </div>
  )
}
export default ProductList
