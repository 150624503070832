import React, { PropsWithChildren } from 'react'
import { useLocation } from 'react-router-dom'
interface ModalProps {
  isModal: boolean
}

export const Modal = ({ children, isModal }: PropsWithChildren<ModalProps>) => {
  const { pathname } = useLocation()
  if (!isModal || pathname === '/shortform-price-guide') return null
  return (
    <div className="md:h-screen h-full w-full fixed left-0 top-0 flex flex-col justify-center items-center bg-black bg-opacity-50 text-center  z-50">
      {children}
    </div>
  )
}
