import { useMutation } from '@tanstack/react-query'
import { deleteLink, getAiRecommendClipTitle } from 'service/list'

function useGetAiRecommendClipTitle() {
  return useMutation({
    mutationKey: ['get-ai-recommend-clip-title'],
    mutationFn: getAiRecommendClipTitle,
  })
}

export default useGetAiRecommendClipTitle
