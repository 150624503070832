import React from 'react'

export const Sale = () => {
  return (
    <span
      className="w-[36px] h-[17px]  flex justify-center items-center rounded-[4px] font-bold text-[11px] text-white"
      style={{
        background: `linear-gradient(to right, rgba(96, 149, 255, 1),rgba(213, 18, 231, 1))`,
      }}
    >
      SALE
    </span>
  )
}
