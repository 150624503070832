import classNames from 'classnames'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { LinkDetailType } from 'service/list'
import { ReactComponent as ImageIcon } from 'svg/fi_image.svg'
import { ReactComponent as CheckIcon } from 'svg/fi_check.svg'
import { FormValues } from 'utils/type'
import { UseFormSetValue, UseFormWatch } from 'react-hook-form'

interface ProductImageSelectorProps {
  selectedLink: LinkDetailType
  index: number
  setValue: UseFormSetValue<FormValues>
  watch: UseFormWatch<FormValues>
}
export const ProductImageSelector = ({
  selectedLink,
  index,
  setValue,
  watch,
}: PropsWithChildren<ProductImageSelectorProps>) => {
  const MAX_DURATION = 60
  const videoDuration = watch(`products.${index}.selectedVideoTotalDuration`)
  const [imageArr, setImageArr] = useState<boolean[]>([])
  const [selectedImgs, setSelectedImgs] = useState<string[]>([])

  const updateImageArr = (i: number, imgUrl: string) => {
    if (videoDuration > MAX_DURATION) return

    const currentArr = imageArr
    const updateArr = currentArr.map((el, index) => (index === i ? !el : el))
    setImageArr(updateArr)

    const imgIndex = selectedImgs.indexOf(imgUrl)
    const updateSelectedImgs = [...selectedImgs]
    if (imgIndex !== -1) {
      // 중복을 허용하므로 한 번만 삭제 (가장 먼저 발견된 항목)
      updateSelectedImgs.splice(imgIndex, 1)
    } else {
      // 새 이미지 추가
      updateSelectedImgs.push(imgUrl)
    }

    setSelectedImgs(updateSelectedImgs)
    setValue(`products.${index}.selectedThumbnails`, updateSelectedImgs)
  }
  useEffect(() => {
    const imgs = selectedLink?.video?.thumbnail_images
    if (imgs) {
      setImageArr(new Array(imgs.length).fill(true))

      setSelectedImgs(imgs)
      setValue(`products.${index}.selectedThumbnails`, imgs)
    }
  }, [selectedLink])

  useEffect(() => {
    if (videoDuration >= MAX_DURATION) {
      //이미지 선택되어있던거 전부 삭제 및 dim
      setSelectedImgs([])
      setValue(`products.${index}.selectedThumbnails`, [])
      setImageArr(new Array(imageArr?.length).fill(false))
    }
  }, [videoDuration])

  return (
    <div
      className={classNames('flex flex-col w-full mt-6', {
        ' opacity-30': videoDuration > MAX_DURATION,
      })}
    >
      <p className="w-full font-bold flex gap-2 items-center">
        <ImageIcon />
        상품 대표 이미지 선택 {selectedImgs.length}/{imageArr.length}
      </p>
      <p className="w-full text-[14px] text-[#8F929B]">
        사용하고 싶은 이미지를 선택해주세요. (영상 길이에 따라 선택한 이미지가
        모두 사용되지 않을 수 있습니다)
      </p>
      <div className="bg-[#F6F8FB] w-full rounded-[10px] grid  md:grid-cols-10 grid-cols-5 p-4 gap-x-[7px]">
        {selectedLink?.video?.thumbnail_images.map((el, el_index) => (
          <>
            <div className="w-[64px] h-[64px] relative ">
              {' '}
              <img
                src={el}
                alt=""
                width={64}
                height={64}
                className="border-[1px] border-[#D9DEE8] rounded-[4px]"
              />
              <div
                className={classNames(
                  'w-[28px] h-[28px] rounded-full border-[1px] flex items-center justify-center absolute  right-1 bottom-1 ',
                  {
                    'bg-main': imageArr[el_index],
                    'bg-black opacity-70': !imageArr[el_index],
                  }
                )}
                onClick={() => {
                  updateImageArr(el_index, el)
                }}
              >
                {imageArr[el_index] && <CheckIcon />}
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  )
}
