import { useQuery } from '@tanstack/react-query'
import { getSubTitleLong, getSubTitleShort } from '../../service/list'

export function useGetSubtitleLong() {
  return useQuery({
    queryKey: ['get-subtitle-long'],
    queryFn: () => getSubTitleLong(),
    staleTime: 6 * 1000,
  })
}

export function useGetSubtitleShort() {
  return useQuery({
    queryKey: ['get-subtitle-short`'],
    queryFn: () => getSubTitleShort(),
    staleTime: 6 * 1000,
  })
}
