import { UseQueryResult, useMutation, useQuery } from '@tanstack/react-query'
import { createShortVideo, createTextVideo, postList } from '../../service/list'

function useCreateTextVideo() {
  return useMutation({
    mutationKey: ['create-text-video'],
    mutationFn: createTextVideo,
  })
}

export default useCreateTextVideo
