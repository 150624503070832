import React, {
  CSSProperties,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { Button } from '../atom/Button'
import { thumbnailOptions, ThumbnailOptionType } from '../../dummy/data'
import {
  Control,
  Controller,
  useForm,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { Input, InputThumbnail } from '../atom/Input'
import { Modal } from '../layout/Modal'
import {
  getLocalStorage,
  LOCAL_THUMBNAIL_INFO,
  removeLocalStorage,
  saveLocalStorage,
  THUMBNAILINFO_LIST,
} from '../../utils/common'
import classNames from 'classnames'
import { FormValues } from '../../utils/type'

interface ModalProps {
  userInfo: any
  thumbnailType: ThumbnailOptionType
  editThumbnail: boolean
  thumbnailId: number
  watch: UseFormWatch<FormValues>
  setValue: UseFormSetValue<FormValues>
  control: Control<FormValues>
  error: boolean
  handleThumbnailError: () => void
}

export interface ThumbnailInfo {
  channelName?: string
  title1?: string
  title2?: string
  title3?: string
  thumbnailFile?: any
}
export const ThumbnailDiv = ({
  children,
  userInfo,
  thumbnailType,
  editThumbnail,
  thumbnailId,
  setValue,
  watch,
  control,
  error,
  handleThumbnailError,
}: PropsWithChildren<ModalProps>) => {
  //채널이름 체크박스 state
  const [isCheck, setCheck] = useState<boolean>(false)
  const [isTitleCheck, setTitleCheck] = useState<boolean>(false)
  const [thumbnailTypeInfo, setThumbnailTypeInfo] = useState(thumbnailType)

  const [imgSrc, setImgSrc] = useState<any>(
    'https://pub.angelleague.io/fast_cut/thumbnail/thumbnail00.png'
  )
  const [essentialCheck, setEssentialCheck] = useState<boolean>(false)
  const [emptyChannelName, setEmptyChannelName] =
    useState<string>('내 채널 이름이 들어가요')

  const THUMBNAIL_PATTERN = /^[^\uD800-\uDFFF]*$/
  const CHANNEL_NAME_PATTERN = /^[^\uD800-\uDFFF&]*$/

  useEffect(() => {
    setThumbnailTypeInfo(thumbnailType)
    if (userInfo) {
      if (userInfo?.userInfo?.youtubeAccount) {
        setValue(
          'thumbnailInfo.channelName',
          userInfo?.userInfo?.youtubeAccount.youtubeChannelName
        )
      }
    }
  }, [thumbnailId])

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFile = Array.from(event.target.files)

      setValue(`thumbnailInfo.thumbnailFile`, selectedFile)
      setImgSrc(selectedFile[0])
    }
  }
  const handleTitleCheckBox = (flag: boolean) => {
    if (flag) {
      setValue('thumbnailInfo.title1', ' ')
      setValue('thumbnailInfo.title2', ' ')
      setValue('thumbnailInfo.title3', ' ')
    } else {
      setValue('thumbnailInfo.title1', '')
      setValue('thumbnailInfo.title2', '')
      setValue('thumbnailInfo.title3', '')
    }
  }
  const handleRemoveImage = () => {
    // 이미지 제거 처리
    setValue('thumbnailInfo.thumbnailFile', []) // thumbnailFile을 빈 배열로 설정
    setImgSrc('https://pub.angelleague.io/fast_cut/thumbnail/thumbnail00.png') // 기본 이미지로 재설정
  }

  // @ts-ignore
  return (
    <div
      className={classNames(
        'flex flex-col justify-center items-center   bg-[#F6F8FB] rounded-[10px]  mb-[40px]',
        { 'border-[1px] border-error': error }
      )}
    >
      <div className="p-5 flex flex-col gap-4">
        <div>
          <p className="font-bold text-[20px] text-left">
            썸네일 내용 입력(필수)
          </p>
          <p className="text-[14px] text-main text-left">
            조회수가 높아질 수 있게 매력적인 내용을 정해주세요!
          </p>
          <p className="text-[14px] text-[#5E6068]">
            적용된 배경 이미지는 예시입니다. 실제 썸네일에는 선택하신
            상품페이지의 첫 번째 상품 이미지가 적용됩니다.
          </p>
          <div className="flex items-center gap-[6px]  text-[#8F929B]">
            <input
              type="checkbox"
              className="w-[20px] h-[20px] border border-[#8F929B] "
              checked={isTitleCheck}
              onClick={() => {
                setTitleCheck(!isTitleCheck)
                handleTitleCheckBox(!isTitleCheck)
                handleThumbnailError()
              }}
            />
            <p style={{ fontWeight: 500 }} className="text-error text-[14px]">
              썸네일에 텍스트 넣지 않기
            </p>
          </div>
        </div>
        {thumbnailType && (
          <div className="flex items-end justify-between  h-[190px]">
            <div className="flex mt-[16px] relative w-[338px] h-[190px]">
              <div className="flex relative w-[100%]">
                {thumbnailTypeInfo?.left_box && (
                  <div
                    style={{
                      width: '193px',
                      height: '100%',
                      overflow: 'hidden',
                      position: 'absolute',
                      right: '0px',
                    }}
                  >
                    <img
                      style={{
                        width: '193px',
                        height: '100%',
                        position: 'absolute',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        overflow: 'hidden',
                      }}
                      src={
                        typeof imgSrc === 'string'
                          ? imgSrc
                          : URL.createObjectURL(imgSrc)
                      }
                      alt="thumbnail"
                    />
                  </div>
                )}
                {!thumbnailTypeInfo?.left_box && (
                  <div
                    style={{
                      width: '338px',
                      height: '100%',
                    }}
                  >
                    <img
                      style={{
                        width: '338px',
                        height: '100%',
                        position: 'absolute',
                        objectFit: 'cover',
                        overflow: 'hidden',
                      }}
                      src={
                        typeof imgSrc === 'string'
                          ? imgSrc
                          : URL.createObjectURL(imgSrc)
                      }
                      alt="thumbnail"
                    />
                  </div>
                )}

                {/* opacity 배경 */}
                {thumbnailTypeInfo?.opacity && (
                  <div
                    className={` op absolute w-[338px] h-[190px] bg-black opacity-[${thumbnailTypeInfo?.opacity}]`}
                  ></div>
                )}

                {/* 그라데이션 배경 */}
                {thumbnailTypeInfo?.bg_gradient && (
                  <div
                    className={` bg_gra absolute w-[338px] h-[190px] bg-black`}
                    style={{
                      background: thumbnailTypeInfo?.bg_gradient,
                    }}
                  ></div>
                )}

                {/* inner border */}
                {thumbnailTypeInfo?.border && (
                  <div className="absolute w-[338px] h-[190px] flex items-center justify-center z-40">
                    <div
                      className={`absolute w-[328px] h-[180px] border`}
                      style={{
                        borderWidth: `${thumbnailTypeInfo?.border.weight}`,
                        borderColor: `${thumbnailTypeInfo?.border.color}`,
                      }}
                    ></div>
                  </div>
                )}

                {/* out border */}
                {thumbnailTypeInfo?.outline_border && (
                  <div
                    className="absolute w-[338px] h-[190px] flex items-center justify-center z-50"
                    style={{
                      borderWidth: `${thumbnailTypeInfo?.outline_border.weight}`,
                      borderColor: `${thumbnailTypeInfo?.outline_border.color}`,
                    }}
                  ></div>
                )}

                {/* 좌상단 채널 이름 */}
                <div
                  className="channel_name absolute top-0  w-[185px] h-[18px] flex items-center pl-[12.08px] z-40"
                  style={{
                    background:
                      'linear-gradient(to right, rgba(35, 35, 35, 1),rgba(167, 167, 167, 0))',
                  }}
                >
                  <p className="font-bold text-[10px] text-[#FFFFFF] ">
                    {watch('thumbnailInfo.channelName')
                      ? watch('thumbnailInfo.channelName')
                      : emptyChannelName}
                  </p>
                </div>

                {thumbnailTypeInfo?.titleList.map((el, index) => {
                  return thumbnailTypeInfo.left_box ? (
                    <>
                      {index === 0 && (
                        <>
                          <div
                            className={`absolute w-[148px] top-0 h-full   bg-black z-10 flex  `}
                            style={{
                              // top: `${el.position}`,
                              // textAlign: `${el.title.align ? 'left' : 'center'}`,
                              // whiteSpace: 'nowrap',
                              whiteSpace: el.title.wrap,
                              overflow: 'hidden',
                            }}
                          >
                            <p
                              className=" absolute  text-[transparent] z-0  flex"
                              style={{
                                top: `${el.title.top}`,
                                left: `${el.title.left}`,
                                width: el.title?.width ?? '',
                                fontSize: `${el.title.size}`,
                                fontWeight: `${el.title.weight}`,
                                fontFamily: `${el.title.font}`,
                                background: `${el.title.gradient?.background}`,
                                WebkitBackgroundClip: `${el.title.gradient?.text}`,
                                letterSpacing: `${el.title.letter}`,
                                color: `${el.title.color}`,
                                height: el.title?.height ?? '',
                                lineHeight: `${el.title.lineHeight}`,
                                overflow: 'hidden',
                                wordBreak: 'break-all',
                                paddingTop: el.title?.py ?? '',
                                alignItems: el.title.itemsEnd ? 'end' : '',
                              }}
                            >
                              {watch(`thumbnailInfo.${el.title.titleName}`)
                                ? watch(`thumbnailInfo.${el.title.titleName}`)
                                : el.title.titleContent}
                            </p>
                          </div>
                        </>
                      )}
                      {index >= 1 && (
                        <>
                          <div
                            className={`absolute w-[148px] top-0  z-10`}
                            style={{
                              top: `${el.position}`,
                              textAlign: `${
                                el.title.align ? 'left' : 'center'
                              }`,
                              whiteSpace: el.title.wrap,
                            }}
                          >
                            {/* {thumbnailTypeInfo?.blank_box && (
                            <p
                              className=" absolute h-[20px] bg-[#414141] text-[transparent] z-0"
                              style={{
                                top: `40%`,
                                left: '1%',
                                fontSize: `${el.title.size}`,
                                fontWeight: `${el.title.weight}`,
                                fontFamily: `${el.title.font}`,
                                letterSpacing: `-1px`,
                                color: el.title.bg ?/ ""
                              }}
                            >
                              {watch(`thumbnailInfo.${el.title.titleName}`)
                                ? watch(`thumbnailInfo.${el.title.titleName}`)
                                : el.title.titleContent}
                            </p>
                          )} */}
                            <p
                              className={` ${el.title.border ?? ''}  ${
                                el.title.bg ? ` bg-[${el.title?.bg}]` : ''
                              }   z-10 flex absolute align-middle `}
                              style={{
                                color: `${el.title.color}`,
                                fontSize: `${el.title.size}`,
                                fontWeight: `${el.title.weight}`,
                                fontFamily: `${el.title.font}`,
                                textShadow: `${el.title.shadow}`,
                                letterSpacing: `${el.title.letter}`,
                                width: el.title?.width ?? '',
                                padding: `${el.title.padding}`,
                                background: el.title.gradient?.background ?? '',
                                WebkitBackgroundClip:
                                  el.title.gradient?.text ?? '',
                                WebkitTextStroke: `${el.title.stroke}`,
                                height: `${el.title.height}`,
                                whiteSpace: '',
                                top: `${el.title.top}`,
                                left: `${el.title.left}`,
                                bottom: `${el.title.bottom}`,
                                lineHeight: `${el.title.lineHeight}`,
                                justifyContent: el.title?.justifyCenter
                                  ? 'center'
                                  : '',
                                alignItems: el.title?.itemsCenter
                                  ? 'center'
                                  : '',
                                paddingLeft: el.title?.px ?? '',
                                paddingRight: el.title?.px ?? '',
                                paddingTop: el.title?.py ?? '',
                                overflow: 'hidden',
                                wordBreak: 'break-all',
                              }}
                            >
                              {watch(`thumbnailInfo.${el.title.titleName}`)
                                ? watch(`thumbnailInfo.${el.title.titleName}`)
                                : el.title.titleContent}
                            </p>{' '}
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div
                        className={`absolute w-full h-[37px] flex items-center px-[10px] align-middle `}
                        style={{
                          top: `${el.position}`,
                          textAlign: `${el.title.align ? 'left' : 'center'}`,
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {thumbnailTypeInfo?.blank_box && (
                          <p
                            className=" absolute h-[20px] bg-[#414141] text-[transparent] z-0"
                            style={{
                              top: `40%`,
                              left: '1%',
                              fontSize: `${el.title.size}`,
                              fontWeight: `${el.title.weight}`,
                              fontFamily: `${el.title.font}`,
                              letterSpacing: `-1px`,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              wordBreak: 'break-all',
                              width: el.title.width ?? '',
                              maxWidth: '338px',
                              height: el.title.height ?? '',
                            }}
                          >
                            {watch(`thumbnailInfo.${el.title.titleName}`)
                              ? watch(`thumbnailInfo.${el.title.titleName}`)
                              : el.title.titleContent}
                          </p>
                        )}
                        <p
                          className={`${el.title.border ?? ''} w-full z-10`}
                          style={{
                            color: `${el.title.color}`,
                            fontSize: `${el.title.size}`,
                            fontWeight: `${el.title.weight}`,
                            fontFamily: `${el.title.font}`,
                            textShadow: `${el.title.shadow}`,
                            letterSpacing: `${el.title.letter}`,
                            backgroundColor: `${el.title.bg}`,
                            padding: `${el.title.padding}`,
                            background: `${el.title.gradient?.background}`,
                            WebkitBackgroundClip: `${el.title.gradient?.text}`,
                            WebkitTextStroke: `${el.title.stroke}`,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            wordBreak: 'break-all',
                            width: el.title.width ?? '',
                            height: el.title.height ?? '',
                          }}
                        >
                          {watch(`thumbnailInfo.${el.title.titleName}`)
                            ? watch(`thumbnailInfo.${el.title.titleName}`)
                            : el.title.titleContent}
                        </p>{' '}
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
            <div className="flex flex-col gap-2 w-[342px] h-[190px] ">
              {thumbnailTypeInfo?.titleList.map((el, index) => {
                return (
                  <>
                    <div className="">
                      <Controller
                        key={el.title.titleName}
                        render={({ field }) => (
                          <>
                            {index === 0 && (
                              <div
                                className={`flex gap-[4px] mb-1 ${
                                  !THUMBNAIL_PATTERN.test(field.value) &&
                                  'text-error'
                                }`}
                              >
                                <span
                                  className={classNames('', {
                                    'text-[#D4D9DF]': isTitleCheck,
                                  })}
                                >{`이모지는 허용되지 않습니다.`}</span>
                              </div>
                            )}
                            <InputThumbnail
                              {...field}
                              // error={
                              //   field.value.length > el.title.maxLength ||
                              //   !THUMBNAIL_PATTERN.test(field.value)
                              //     ? true
                              //     : false
                              // }
                              disabled={isTitleCheck}
                              placeholder={el.title.placeholder}
                            />
                          </>
                        )}
                        name={`thumbnailInfo.${el.title.titleName}`}
                        control={control}
                        rules={{
                          required: {
                            value:
                              watch('thumbnailInfo.title1') ||
                              watch('thumbnailInfo.title2') ||
                              watch('thumbnailInfo.title3')
                                ? false
                                : true,
                            message: '썸네일 내용은 필수입니다!',
                          },

                          validate: (value) => {
                            handleThumbnailError()
                            return (
                              THUMBNAIL_PATTERN.test(value) ||
                              '이모지는 허용되지 않습니다.'
                            )
                          },
                        }}
                      />
                    </div>
                  </>
                )
              })}
            </div>
          </div>
        )}

        <div className="flex justify-between items-center  bg-[#EEF1F7] px-5 h-[98px] rounded-[10px]">
          <div className="flex flex-col w-[318px] h-[57px] justify-center">
            <p className="font-bold    text-left">
              썸네일 이미지 업로드(선택사항)
            </p>
            <p className="text-left text-[#8F929B] text-[14px]">
              이미지를 따로 업로드 하지 않으면, 해당 상품의 첫 <br />
              번째 이미지가 자동으로 사용됩니다.
            </p>
          </div>
          {/* 오른쪽 */}
          <div className="">
            <div className="flex items-center  gap-4">
              <div className="flex relative w-[139px] h-[78px] bg-[#D9DEE8]">
                {watch('thumbnailInfo.thumbnailFile', []).map(
                  (file, fileIndex) => (
                    <div
                      style={{
                        width: '139px',
                        height: '100%',
                        overflow: 'hidden',
                        position: 'relative',
                      }}
                    >
                      <img
                        style={{
                          width: '139px',
                          height: '100%',
                          position: 'absolute',
                          objectFit: 'cover',
                          overflow: 'hidden',
                        }}
                        src={URL.createObjectURL(file)}
                        alt="thumbnail"
                      />
                      <button
                        onClick={handleRemoveImage}
                        className="absolute top-2 right-2"
                        style={{ cursor: 'pointer' }}
                      >
                        <img
                          className="w-[24px] h-[24px]  md:w-[32px] md:h-[32px]"
                          src="assets/img/icon_circle_x_black.png"
                          alt="x"
                        />
                      </button>
                    </div>
                  )
                )}
              </div>
              <div className="flex flex-col">
                <button
                  onClick={(event) => {
                    event.preventDefault() // 기본 form 제출 동작을 방지
                    document.getElementById(`file-input`)?.click() // 파일 선택 창 열기
                  }}
                  type="button"
                  className="flex items-center justify-center w-[122px] h-[36px] text-white rounded-full bg-[#6B8ACB] text-[14px] font-bold mb-1"
                >
                  <img
                    src="assets/img/icon_image.png"
                    alt=""
                    width={17}
                    className="mr-[6px]"
                  />
                  <span>파일 업로드</span>
                </button>
                <input
                  id={`file-input`}
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  className="hidden"
                  onChange={(e) => handleFileChange(e)}
                />
                <p className="text-[#8F929B] text-[14px]  text-left ">
                  권장 사이즈 1280x720
                </p>
                <p className="text-[#8F929B] text-[14px]  text-left">
                  최대 2MB | jpg, jpeg, png
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center bg-[#EEF1F7] px-5 w-[696px] h-[160px] rounded-[10px] gap-1">
          <div className="flex w-full justify-start">
            {' '}
            <p className="font-bold text-left">
              채널 이름 입력(선택사항){' '}
              <span className="text-[#8F929B] text-[14px] text-left">
                썸네일 왼쪽 상단에 들어갈 유튜브 채널 이름을 입력 해주세요
              </span>
            </p>
          </div>
          <div className="">
            <Controller
              key={`thumbnailInfo.channelName`}
              render={({ field }) => (
                <>
                  <InputThumbnail
                    {...field}
                    error={
                      field.value.length > 20 ||
                      !CHANNEL_NAME_PATTERN.test(field.value)
                        ? true
                        : false
                    }
                    placeholder="운영하시는 유튜브 채널 이름을 입력해주세요 (20자 이내)"
                    maxLength={20}
                    width={'w-[656px]'}
                  />
                  <div
                    className={`flex gap-[4px] mt-[8px] text-[14px] ${
                      (field.value.length > 20 ||
                        !CHANNEL_NAME_PATTERN.test(field.value)) &&
                      'text-error'
                    }`}
                  >
                    <span>{field.value.length}/20</span>
                    <span>{`이모지와 &는 허용되지 않습니다.`}</span>
                  </div>
                  {/* 채널이름 미설정 체크박스  */}
                </>
              )}
              name={`thumbnailInfo.channelName`}
              control={control}
              rules={{
                maxLength: {
                  value: 20,
                  message: '채널이름 내용의 길이가 20자를 넘습니다.',
                },
                validate:
                  watch(`thumbnailInfo.channelName`) !== ''
                    ? (value) => {
                        return (
                          CHANNEL_NAME_PATTERN.test(value) ||
                          '이모지와 &는 허용되지 않습니다.'
                        )
                      }
                    : undefined,
              }}
            />
            {/* <div className="flex items-center gap-3 mt-[8px] text-error">
              <input
                type="checkbox"
                className="w-[20px] h-[20px] border border-[#8F929B]"
                checked={isCheck}
                onClick={() => {
                  setValue('thumbnailInfo.channelName', '')
                  setEmptyChannelName('')
                  setCheck((prev) => !prev)
                }}
              />
              <p style={{ fontWeight: 500 }} className="text-[14px]">
                채널 이름 넣지 않기
              </p>
            </div> */}
          </div>
        </div>
      </div>

      {essentialCheck && (
        <Modal isModal={essentialCheck}>
          <div className="bg-white w-[400px] h-[184px] p-[20px] border-t rounded-t-[20px]">
            <div className="flex flex-col items-center justify-center h-full gap-[24px]">
              <p className="font-bold text-[18px]">
                🙌 필수 정보를 입력해주세요!
              </p>
              <p className="text-[14px]">
                내용이 입력되지 않아 저장할 수 없어요 .
              </p>
            </div>
          </div>
          <div className="w-[400px] bg-main h-[60px] border-b border-main flex   rounded-b-[20px] justify-center items-center">
            <Button
              className="text-white w-[400px] h-full  rounded-b-[20px]"
              onClick={() => {
                setEssentialCheck(false)
              }}
            >
              확인
            </Button>
          </div>
        </Modal>
      )}
    </div>
  )
}
