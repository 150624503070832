import { Dispatch, PropsWithChildren, useEffect } from 'react'
import ProductNameInput from './ProductNameInput'
import {
  Control,
  FieldArrayWithId,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { FormValues } from 'utils/type'
import { Me, Usage } from 'service/user'
import { ProductVideoSelector } from './ProductVideoSelector'
import { ProductImageSelector } from './ProductImageSelector'
import { ProductDetailImageSelector } from './ProdcutDetailImageSelector'
import { FileUploader } from './FileUploader'
import { LinkDetailType } from 'service/list'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'

interface ProductItemProps {
  watch: UseFormWatch<FormValues>
  setValue: UseFormSetValue<FormValues>
  control: Control<FormValues, any>
  errors: FieldErrors<FormValues>
  setErrorText: Dispatch<React.SetStateAction<string>>
  userInfo: Me

  index: number
  usage: Usage | undefined
  error: {
    products: {
      link: boolean
      product: boolean
    }[]
    mainTitle: boolean
    thumbnail: boolean
  }
  selectedLink: LinkDetailType
  setError: any
  isAiName: boolean
}

const ProductItem = ({
  watch,
  index,
  control,
  errors,
  error,
  userInfo,
  setValue,
  usage,
  selectedLink,
  setError,
  isAiName,
}: PropsWithChildren<ProductItemProps>) => {
  useEffect(() => {
    if (selectedLink) {
      setValue(`products.${index}.extensionCollectId`, selectedLink.id)
    }
  }, [selectedLink])

  return (
    <div
      className={classNames(
        'flex flex-col items-center w-[375px] md:w-full gap-1',
        { 'px-4': isMobile }
      )}
    >
      <p className="text-[18px] font-bold bg-[#2E343D] text-white w-full rounded-[6px] px-2">
        상품 {index + 1}
      </p>
      {/* 상품명 입력 */}
      <ProductNameInput
        index={index}
        control={control}
        errors={errors}
        error={error}
        watch={watch}
        setValue={setValue}
        usage={usage}
        userInfo={userInfo}
        selectedLink={selectedLink}
        setError={setError}
        isAiName={isAiName}
      />

      {/* 상품 영상 선택 */}
      <ProductVideoSelector
        selectedLink={selectedLink}
        index={index}
        setValue={setValue}
      />

      {/* 상품 이미지 선택 */}
      <ProductImageSelector
        selectedLink={selectedLink}
        index={index}
        setValue={setValue}
        watch={watch}
      />

      {/* 상품 상세 이미지 */}
      <ProductDetailImageSelector
        selectedLink={selectedLink}
        index={index}
        setValue={setValue}
        watch={watch}
      />

      {/* 이미지 업로드 */}
      <FileUploader watch={watch} i={index} setValue={setValue} />
    </div>
  )
}

export default ProductItem
