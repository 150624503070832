import classNames from 'classnames'
import React, { InputHTMLAttributes } from 'react'
import { InputType } from 'zlib'

export const Input = (props: any) => {
  const { error, inputRef, isCheck, userCheck, recoverError, bg, height } =
    props
  const inputClass = classNames(
    'w-[343px] text-base md:w-[736px] border-[1px] rounded-[5px] p-[16px]',
    {
      'border-error focus:outline-none': error,
      'border-[#D9DEE8]': !error,
      'opacity-[0.5]': isCheck,
      'bg-[#F6F8FB]': bg,
      [height]: height,
      'h-[70px]': !height,
    }
  )

  return (
    <div>
      <input
        {...props}
        className={inputClass}
        ref={inputRef}
        onClick={userCheck}
      />
    </div>
  )
}

export const InputEmpty = (props: any) => {
  const { error, inputRef, isCheck, userCheck, recoverError, bg, height } =
    props
  const inputClass = classNames(
    'w-[343px] text-base md:w-[736px] border-[1px] rounded-[5px] p-[16px] text-error'
  )

  return (
    <div>
      <input
        {...props}
        className={inputClass}
        ref={inputRef}
        onClick={userCheck}
        value={
          '패스트컷 확장프로그램에서 수집한 쿠팡이나 알리익스프레스 상품을 먼저 선택해 주셔야 영상으로 만들 상품명을 불러올 \n수 있어요!'
        }
        disabled
      />
    </div>
  )
}

export const InputThumbnail = (props: any) => {
  const { error, inputRef, isCheck, userCheck, width, height } = props

  const inputClass = classNames(
    ` ${width ?? 'w-[342px]'}  ${
      height ? `w-[${height}px]` : ' h-[49px]'
    }  text-base   border-[1px] rounded-[10px] p-[16px]`,
    {
      'border-error focus:outline-none': error,
      'border-[#D9DEE8]': !error,
      'opacity-[0.5]': isCheck,
    }
  )

  return (
    <div>
      <input
        {...props}
        className={inputClass}
        ref={inputRef}
        onClick={userCheck}
      />
    </div>
  )
}
